import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { UserContext } from '../contexts/UserContext';
import * as api from '../apis';

const LoginGeneral = () => {
	const navigate = useNavigate();
	const { user, login } = useContext(UserContext);
	const [userLogin, setUserLogin] = useState('');
	const [password, setPassword] = useState('');

	useEffect(() => {
		if (user) {
			loginCompleted();
		}
	}, [user]);

	const onClickLogin = async () => {
		try {
			const res = await api.login(userLogin, password);
			if (res && res.data) {
				login(res.data);
				loginCompleted();
			}
		} catch (err) {
			console.error(err);
		}
	};

	const onKeyPressInput = (e) => {
		if (e.key === 'Enter') {
			onClickLogin();
		}
	};

	const loginCompleted = () => {
		navigate('/');
	};

	return (
		<Container>
			<Title>로그인</Title>
			<Wrapper>
				<TopLine />
				<LoginWrapper>
					<LoginTitle>외부사용자 로그인</LoginTitle>
					<Input
						type="text"
						value={userLogin}
						onChange={(e) => setUserLogin(e.currentTarget.value)}
						onKeyPress={onKeyPressInput}
						placeholder="아이디"
					/>
					<Input
						type="password"
						value={password}
						onChange={(e) => setPassword(e.currentTarget.value)}
						onKeyPress={onKeyPressInput}
						placeholder="패스워드"
					/>
					<Button onClick={onClickLogin}>로그인</Button>
					<LoginDescription>가입 및 이용 문의 : 055-772-4866</LoginDescription>
				</LoginWrapper>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 80px 0 0 0;

	@media only screen and (max-width: 767.98px) {
		padding: 80px 15px 20px 15px;
	}
`;

const Title = styled.span`
	color: #214e9e;
	font-size: 30px;
	font-weight: 700;
	line-height: 30px;
	letter-spacing: -0.9px;
`;

const Wrapper = styled.div`
	width: 380px;
	background-color: white;
	margin-top: 24px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
	}
`;

const TopLine = styled.div`
	width: 100%;
	height: 5px;
	background-color: #344e9b;
`;

const LoginTitle = styled.div`
	font-family: 'Noto Sans';
	font-size: 20px;
	font-weight: 700;
	margin-bottom: 24px;
`;

const LoginDescription = styled.div`
	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 400;
	margin-top: 24px;
`;

const LoginWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: stretch;
	padding: 24px 35px;

	@media only screen and (max-width: 767.98px) {
		padding: 20px;
		border-bottom: 1px solid #dddddd;
		& + & {
			border: none;
		}
	}
`;

const Input = styled.input`
	height: 40px;
	width: 100%;
	& + & {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	border-radius: 6px;
	border: 1px solid #dddddd;
	background-color: #ffffff;
	opacity: 0.97;

	padding-left: 20px;
`;

const Button = styled.button`
	width: 100%;
	height: 47px;
	margin-top: 12px;
	color: white;
	outline: none;
	border: none;
	background-color: #11a7b0;
	border-radius: 6px;
	${(props) =>
		props.type === 'sso' &&
		css`
			background-color: #265fa7;
		`}

	@media only screen and (max-width: 767.98px) {
		margin-top: 0;
	}
`;

export default LoginGeneral;
