import { useEffect, useState } from 'react';

import FilterType from '../constants/FilterType';
import ApprovalStatus from '../constants/ApprovalStatus';
import ApprovalMethod from '../constants/ApprovalMethod';

import * as api from '../apis';

const useFilterHandler = ({ onFetchCompleted }) => {
	const getPublishStatusFilter = () => {
		let statusFilter = [
			{ id: 1, name: '공개', selected: false, value: true },
			{ id: 2, name: '비공개', selected: false, value: false },
		];
		return statusFilter;
	};

	const getApprovalStatusFilter = () => {
		let result = [
			{
				id: 1,
				name: ApprovalStatus.toString(ApprovalStatus.APPROVED),
				value: ApprovalStatus.APPROVED,
				selected: false,
			},
			{
				id: 2,
				name: ApprovalStatus.toString(ApprovalStatus.PENDING),
				value: ApprovalStatus.PENDING,
				selected: false,
			},
			{
				id: 3,
				name: ApprovalStatus.toString(ApprovalStatus.REJECTED),
				value: ApprovalStatus.REJECTED,
				selected: false,
			},
			{
				id: 4,
				name: ApprovalStatus.toString(ApprovalStatus.CANCELED),
				value: ApprovalStatus.CANCELED,
				selected: false,
			},
		];

		return result;
	};

	const getApprovalMethodFilter = () => {
		let result = [
			{
				id: 1,
				name: ApprovalMethod.toString(ApprovalMethod.AUTO),
				value: ApprovalMethod.AUTO,
				selected: false,
			},
			{
				id: 2,
				name: ApprovalMethod.toString(ApprovalMethod.MANUAL),
				value: ApprovalMethod.MANUAL,
				selected: false,
			},
		];

		return result;
	};

	const [categoryFilter, setCategoryFilter] = useState([]);
	const [publishStatusFilter, setPublishStatusFilter] = useState(getPublishStatusFilter());
	const [approvalStatusFilter, setApprovalStatusFilter] = useState(getApprovalStatusFilter());
	const [approvalMethodFilter, setApprovalMethodFilter] = useState(getApprovalMethodFilter());

	useEffect(() => {
		refreshCategoryFilter();
	}, []);

	useEffect(() => {
		if (categoryFilter.length > 0 && onFetchCompleted) {
			onFetchCompleted();
		}
	}, [categoryFilter]);

	const refreshCategoryFilter = () => {
		api.getCategoryFilters()
			.then((res) => {
				if (res && res.data) {
					let filterList = res.data.map((category) => {
						return {
							...category,
							filters: category.filters.map((filter) => {
								return {
									...filter,
									category_items: filter.category_items.map((categoryItem) => {
										return {
											...categoryItem,
											selected: false,
										};
									}),
								};
							}),
						};
					});
					setCategoryFilter(filterList);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const changeCategoryFilterCheckbox = (targetId, checked) => {
		let changedFilter = categoryFilter.map((category) => {
			return {
				...category,
				filters: category.filters.map((filter) => {
					return {
						...filter,
						category_items: filter.category_items.map((categoryItem) => {
							if (categoryItem.id === targetId) {
								return {
									...categoryItem,
									selected: checked,
								};
							}
							return categoryItem;
						}),
					};
				}),
			};
		});
		setCategoryFilter(changedFilter);
	};

	const changeFilterCheckbox = (targetId, checked, targetFilter, setter) => {
		let changedFilter = targetFilter.map((filter) => {
			if (filter.id === targetId) {
				return {
					...filter,
					selected: checked,
				};
			}
			return filter;
		});
		setter(changedFilter);
	};

	const onChangeFilterCheckbox = (type, targetId, checked) => {
		switch (type) {
			case FilterType.CATEGORY:
				changeCategoryFilterCheckbox(targetId, checked);
				break;
			case FilterType.PUBLISH_STATUS:
				changeFilterCheckbox(targetId, checked, publishStatusFilter, setPublishStatusFilter);
				break;
			case FilterType.APPROVAL_STATUS:
				changeFilterCheckbox(targetId, checked, approvalStatusFilter, setApprovalStatusFilter);
				break;
			case FilterType.APPROVAL_METHOD:
				changeFilterCheckbox(targetId, checked, approvalMethodFilter, setApprovalMethodFilter);
				break;
		}
	};

	const getCategoryFilterParams = () => {
		let filterParams = [];
		let categoryFilterGroupIndex = 0;
		categoryFilter.forEach((category) => {
			category.filters.forEach((filter) => {
				filter.category_items.forEach((categoryItem) => {
					if (categoryItem.selected) {
						filterParams.push(`category_item_ids[${categoryFilterGroupIndex}][]=${categoryItem.id}`);
					}
				});
				categoryFilterGroupIndex++;
			});
		});

		return filterParams;
	};

	const getUnitFilterParams = () => {
		let filterParams = getCategoryFilterParams();
		publishStatusFilter.forEach((filter) => {
			if (filter.selected) {
				filterParams.push(`published[]=${filter.value}`);
			}
		});

		return filterParams;
	};

	const getBookingFilterParams = () => {
		let filterParams = getCategoryFilterParams();
		approvalStatusFilter.forEach((filter) => {
			if (filter.selected) {
				filterParams.push(`approval_statuses[]=${filter.value}`);
			}
		});
		approvalMethodFilter.forEach((filter) => {
			if (filter.selected) {
				filterParams.push(`approval_methods[]=${filter.value}`);
			}
		});

		return filterParams;
	};

	return {
		categoryFilter,
		publishStatusFilter,
		approvalStatusFilter,
		setApprovalStatusFilter,
		approvalMethodFilter,
		onChangeFilterCheckbox,
		getUnitFilterParams,
		getBookingFilterParams,
	};
};

export default useFilterHandler;
