import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import AdminSectionTitle from '../../../components/admin/AdminSectionTitle';
import ApprovalStatus from '../../../constants/ApprovalStatus';

import * as api from '../../../apis';

const SmsMessageSetting = () => {
	const [smsMessageList, setSmsMessageList] = useState([]);

	useEffect(() => {
		getSmsMessagesFromApi();
	}, []);

	const getSmsMessagesFromApi = () => {
		api.getSmsMessages()
			.then((res) => {
				if (res && res.data) {
					setSmsMessageList(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const onUpdateButtonClick = () => {
		api.updateSmsMessages({ messages: smsMessageList })
			.then((res) => {
				if (res.status === 204) {
					alert('수정되었습니다.');
					return;
				}
				alert('수정에 실패하였습니다.');
				return;
			})
			.catch((err) => {
				alert('수정에 실패하였습니다.');
				console.error(err);
			});
	};

	return (
		<Content>
			<TopContainer>
				<AdminSectionTitle title="SMS 관리" />
				<UpdateButton onClick={onUpdateButtonClick}>수정</UpdateButton>
			</TopContainer>
			<section>
				<SmsInformation>※ SMS 문자 발송은 학사 정보가 있는 ‘통합 회원’에게만 발송됩니다.</SmsInformation>
			</section>
			<section>
				<SmsMessageListTable>
					<thead>
						<tr>
							<th style={{ minWidth: '150px', maxWidth: '150px' }}>상태</th>
							<th style={{ width: '100%' }}>SMS 문구</th>
							<th style={{ minWidth: '100px', maxWidth: '100px' }}>자동 발송 여부</th>
						</tr>
					</thead>
					<tbody>
						{smsMessageList?.map((smsMessage, idx) => {
							return (
								<tr key={idx}>
									<td>{ApprovalStatus.toString(smsMessage.approval_status)}</td>
									<MessageInputContainer>
										<MessageInput
											value={smsMessage.message}
											placeholder="SMS 내용을 작성해주세요"
											onChange={(e) =>
												setSmsMessageList(
													smsMessageList.map((data, dataIdx) => {
														if (idx === dataIdx) {
															return {
																...data,
																message: e.currentTarget.value,
															};
														}
														return data;
													})
												)
											}
										/>
									</MessageInputContainer>
									<td>
										<input
											type="checkbox"
											checked={smsMessage.use_message}
											onChange={(e) =>
												setSmsMessageList(
													smsMessageList.map((data, dataIdx) => {
														if (idx === dataIdx) {
															return {
																...data,
																use_message: e.currentTarget.checked,
															};
														}
														return data;
													})
												)
											}
										/>
									</td>
								</tr>
							);
						})}
					</tbody>
				</SmsMessageListTable>
			</section>
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	padding: 40px 50px;
	background-color: white;
`;

const TopContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const UpdateButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 70px;
	height: 30px;
	border-radius: 4px;
	border: none;
	background-color: #11a9b1;

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.39px;
	line-height: 100%;

	&:disabled {
		background-color: #c0c0c0;
		color: white;
	}
`;

const SmsInformation = styled.div`
	margin-top: 8px;
	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.39px;
	line-height: 100%;
`;

const SmsMessageListTable = styled.table`
	width: 100%;

	border-collapse: collapse;
	border-top: 2px solid #333333;

	text-align: center;
	margin-top: 15px;
	thead th {
		height: 45px;
		color: #333333;
		font-family: 'Noto Sans';
		font-size: 15px;
		font-weight: 500;
		font-style: normal;
		letter-spacing: -0.45px;
		line-height: 100%;
		:first-child {
			border-right: 1px solid #dcdcdc;
		}
	}
	thead tr {
		border-bottom: 1px solid #dcdcdc;
	}

	tbody td {
		height: 45px;

		color: #666666;
		font-family: 'Noto Sans';
		font-size: 15px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: -0.45px;
		line-height: 100%;
		:first-child {
			border-right: 1px solid #dddddd;
		}
	}
	tbody tr {
		border-bottom: 1px solid #dddddd;
	}

	tfoot {
		height: 45px;
		background: #f7f7f7;
		border-bottom: 1px solid #dddddd;
	}
`;

const MessageInputContainer = styled.td`
	padding: 8px;
`;

const MessageInput = styled.textarea`
	width: 100%;
	height: 100px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

export default SmsMessageSetting;
