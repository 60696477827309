import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import * as api from '../../apis';

import LogoImg from '../../assets/images/common/logo.png';

const AdminHeader = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { logout } = useContext(UserContext);
	const menus = [
		{
			path: '/admin/dashboard',
			link: '/admin/dashboard',
			title: '대시보드',
			default: true,
		},
		{
			path: '/admin/unit/rooms',
			link: '/admin/unit/rooms',
			title: '예약항목 관리',
		},
		{
			path: '/admin/booking/rooms',
			link: '/admin/booking/rooms',
			title: '예약현황 관리',
		},
		{
			path: '/admin/users',
			link: '/admin/users',
			title: '사용자 관리',
		},
		{
			path: '/admin/settings',
			link: '/admin/settings',
			title: '시스템 설정',
		},
	];

	const onClickLogo = () => {
		navigate('/');
	};

	const onClickLogout = async () => {
		try {
			const res = await api.logout();
			if (res && res.status === 204) {
				logout();
				navigate('/');
			}
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Container>
			<TopLine />
			<Content>
				<LogoContainer>
					<LogoImage onClick={onClickLogo} src={LogoImg} />
					<LogoText onClick={onClickLogo}>스튜디오 예약시스템</LogoText>
				</LogoContainer>
				<MenuContainer>
					{menus.map((menu, idx) => {
						return (
							<MenuItem
								key={idx}
								selected={
									(location.pathname === '/admin' && menu.default) ||
									location.pathname.includes(menu.path)
								}
								onClick={() => navigate(menu.link)}
							>
								{menu.title}
							</MenuItem>
						);
					})}
					<LogoutButton onClick={onClickLogout}>로그아웃</LogoutButton>
				</MenuContainer>
			</Content>
		</Container>
	);
};

const Container = styled.div`
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	height: 95px;
	z-index: 100;
	background-color: white;
`;

const TopLine = styled.div`
	width: 100%;
	height: 5px;
	background-color: #22499d;
`;

const Content = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 1240px;
`;

const LogoContainer = styled.div`
	display: flex;
	align-items: center;
	height: 90px;
`;

const LogoImage = styled.img`
	width: 117px;
	height: auto;
	cursor: pointer;
`;

const LogoText = styled.span`
	cursor: pointer;
	margin-left: 17px;
	color: #000000;
	font-family: 'Noto Sans';
	font-size: 27px;
	font-weight: 700;
`;

const MenuContainer = styled.ul`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 90px;
	margin-bottom: 0;
	padding-left: 0;
`;

const MenuItem = styled.li`
	display: flex;
	align-items: center;
	height: 100%;
	box-sizing: border-box;
	cursor: pointer;
	list-style: none;
	font-family: 'Noto Sans';
	font-size: 20px;
	font-weight: 500;
	color: #111111;
	letter-spacing: -0.6px;
	border-bottom: 3px solid white;

	& + & {
		margin-left: 48px;
	}

	${(props) =>
		props.selected &&
		css`
			color: #0e498f;
			border-bottom: 3px solid #275ba6;
		`}
`;

const LogoutButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 88px;
	height: 35px;
	margin-left: 48px;

	cursor: pointer;
	border-radius: 17px;
	border: 1px solid #275da6;
	background-color: #ffffff;

	color: #333333;
	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 500;
`;

export default AdminHeader;
