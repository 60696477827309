import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useLocation } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';

const FrontLayout = ({ children, background }) => {
	const location = useLocation();

	useEffect(() => {
		const scrollToTop = () => window.scrollTo(0, 0);
		return () => {
			scrollToTop();
		};
	}, [location]);

	return (
		<Container>
			<Header />
			<Content background={background}>{children}</Content>
			<Footer />
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	height: 100%;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 100%;
	min-height: calc(100vh - 180px);
	padding: 160px 0 114px 0;

	background-color: #f5f5f5;

	${(props) =>
		props.background &&
		css`
			background-color: ${props.background};
		`}

	@media only screen and (max-width: 767.98px) {
		padding: 60px 0 38px 0;
		min-height: calc(100vh - 140px);
	}
`;

export default FrontLayout;
