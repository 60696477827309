import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { UserContext } from '../contexts/UserContext';
import useSizeDetector from '../hooks/useSizeDetector';

import ApprovalStatus from '../constants/ApprovalStatus';
import CategorySlug from '../constants/CategorySlug';

import AdminSectionTitle from '../components/admin/AdminSectionTitle';
import TablePagination from '../components/TablePagination';
import BookingItemModal from '../components/modal/BookingItemModal';
import OperatorRequestModal from '../components/modal/OperatorRequestModal';

import RequestIconImage from '../assets/images/screens/MyPage/request_icon.png';

import * as api from '../apis';
import * as utils from '../utils';

const MyPage = () => {
	const navigate = useNavigate();
	const { user } = useContext(UserContext);
	const { Desktop, Mobile } = useSizeDetector();

	const [pageIndex, setPageIndex] = useState(1);
	const [filterStatus, setFilterStatus] = useState(null);
	const [bookingList, setBookingList] = useState(null);
	const [bookingItemModalVisiblity, setBookingItemModalVisiblity] = useState(false);
	const [bookingItemModalData, setBookingItemModalData] = useState(null);
	const [operatorRequestModalVisibility, setOperatorRequestModalVisibility] = useState(false);
	const [operatorRequestModalData, setOperatorRequestModalData] = useState(null);

	useEffect(() => {
		if (!user) {
			navigate('/login');
			return;
		}
	}, []);

	useEffect(() => {
		if (!user) {
			navigate('/login');
			return;
		}
		getMyBookingFromApi();
	}, [pageIndex, filterStatus]);

	const getMyBookingFromApi = () => {
		let filterParams = [`page=${pageIndex}`, `page_size=10`];
		if (filterStatus) filterParams.push(`approval_statuses[]=${filterStatus}`);
		api.getMyBooking(filterParams).then((res) => {
			if (res && res.data) {
				setBookingList(res.data);
			}
		});
	};

	const onClickAdminRequest = (bookingItem) => {
		setOperatorRequestModalData(bookingItem);
		setOperatorRequestModalVisibility(true);
	};

	const onClickShowRequest = (bookingItem) => {
		setBookingItemModalVisiblity(true);
		setBookingItemModalData(bookingItem);
	};

	const onClickCancelBooking = (bookingItem) => {
		if (!window.confirm('선택된 예약을 취소하시겠습니까?')) return;
		const data = {
			booking_ids: [bookingItem.id],
			approval_status: ApprovalStatus.CANCELED,
		};
		api.updateApprovalStatus(data).then((res) => {
			if (res && res.data) {
				alert('취소되었습니다.');
				getMyBookingFromApi();
			}
		});
	};

	const onPageChange = (page) => {
		setPageIndex(page);
	};

	const onStatusChange = (status) => {
		setFilterStatus(status);
	};

	return (
		<Content>
			<Row>
				<Desktop>
					<MypageColumn>
						<Title>마이페이지</Title>
						<MenuContainer>
							<Menu>
								<MainMenu>나의예약</MainMenu>
								<SubMenu>시설</SubMenu>
							</Menu>
						</MenuContainer>
					</MypageColumn>
				</Desktop>
				<BookingListColumn>
					<Mobile>
						<TopContainer>
							<TopTitle>마이페이지</TopTitle>
							<OverflowInfo>표는 가로로 슬라이드 됩니다.</OverflowInfo>
						</TopContainer>
					</Mobile>
					<TableTopContainer>
						<AdminSectionTitle title="나의 예약" />
						<StatusButtonContainer>
							<StatusButton onClick={() => onStatusChange(null)} selected={filterStatus === null}>
								전체
							</StatusButton>
							<StatusButton
								onClick={() => onStatusChange(ApprovalStatus.APPROVED)}
								selected={filterStatus === ApprovalStatus.APPROVED}
							>
								{ApprovalStatus.toString(ApprovalStatus.APPROVED)}
							</StatusButton>
							<StatusButton
								onClick={() => onStatusChange(ApprovalStatus.PENDING)}
								selected={filterStatus === ApprovalStatus.PENDING}
							>
								{ApprovalStatus.toString(ApprovalStatus.PENDING)}
							</StatusButton>
							<StatusButton
								onClick={() => onStatusChange(ApprovalStatus.REJECTED)}
								selected={filterStatus === ApprovalStatus.REJECTED}
							>
								{ApprovalStatus.toString(ApprovalStatus.REJECTED)}
							</StatusButton>
							<StatusButton
								onClick={() => onStatusChange(ApprovalStatus.CANCELED)}
								selected={filterStatus === ApprovalStatus.CANCELED}
							>
								{ApprovalStatus.toString(ApprovalStatus.CANCELED)}
							</StatusButton>
						</StatusButtonContainer>
					</TableTopContainer>
					<TableContainer>
						<BookingListTable>
							<thead>
								<tr>
									<th style={{ minWidth: '70px' }}>예약번호</th>
									<th style={{ minWidth: '120px' }}>위치</th>
									<th style={{ minWidth: '120px' }}>시설명 / 호실</th>
									<th style={{ minWidth: '100px' }}>이용기간</th>
									<th style={{ minWidth: '100px' }}>신청일</th>
									<th style={{ minWidth: '80px' }}>예약상태</th>
									<th style={{ minWidth: '70px' }}>운영자요청</th>
									<th style={{ minWidth: '70px' }}>신청내역</th>
									<th style={{ minWidth: '70px' }}>예약취소</th>
								</tr>
							</thead>
							<tbody>
								{bookingList?.items.map((item, idx) => {
									return (
										<tr key={idx}>
											<td>{`#${item.id}`}</td>
											<td>
												{`${utils.getUnitCategoryName(item.unit, CategorySlug.PLACE, 1)} 
											${utils.getUnitCategoryName(item.unit, CategorySlug.PLACE, 2)}`}
												<br />
												{utils.getUnitCategoryName(item.unit, CategorySlug.PLACE, 3)}
											</td>
											<td className="strong">{`${item.unit?.name ?? '-'} / ${
												item.room_item?.name ?? '-'
											}`}</td>
											<td className="strong">
												{item.booking_date
													? item.booking_date
															.split(' ')
															.map((line, _) => <div key={_}>{line}</div>)
													: '-'}
											</td>
											<td>
												{item.created_at ? new Date(item.created_at).toLocaleDateString() : '-'}
											</td>
											<td>
												<TableDataContainer>
													<ApprovalStatusText status={item.approval_status}>
														{ApprovalStatus.toString(item.approval_status) ?? '-'}
													</ApprovalStatusText>
												</TableDataContainer>
											</td>
											<td>
												{item.operator_request && (
													<OperatorRequestIcon
														src={RequestIconImage}
														onClick={() => onClickAdminRequest(item)}
													/>
												)}
											</td>
											<td>
												<TableDataContainer>
													<ShowBookingButton onClick={() => onClickShowRequest(item)}>
														보기
													</ShowBookingButton>
												</TableDataContainer>
											</td>
											<td>
												{item.approval_status !== ApprovalStatus.CANCELED && (
													<TableDataContainer>
														<CancelBookingButton onClick={() => onClickCancelBooking(item)}>
															취소
														</CancelBookingButton>
													</TableDataContainer>
												)}
											</td>
										</tr>
									);
								})}
							</tbody>
						</BookingListTable>
					</TableContainer>
					<div className="mt-24px">
						<TablePagination
							currentPage={bookingList?.pagination.current_page}
							lastPage={bookingList?.pagination.last_page}
							onPageChange={onPageChange}
						/>
					</div>
				</BookingListColumn>
			</Row>
			{bookingItemModalVisiblity && (
				<BookingItemModal booking={bookingItemModalData} onClose={() => setBookingItemModalVisiblity(false)} />
			)}
			{operatorRequestModalVisibility && (
				<OperatorRequestModal
					data={operatorRequestModalData}
					onClose={(refresh) => {
						setOperatorRequestModalVisibility(false);
						if (refresh) {
							getMyBookingFromApi();
						}
					}}
				/>
			)}
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	margin-top: 50px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 20px;
		padding: 0 15px;
	}
`;

const Row = styled.div`
	display: flex;
`;

const MypageColumn = styled.div`
	width: 190px;
	background-color: #ffffff;
	border-radius: 4px;
	padding: 40px 30px;
`;

const Title = styled.div`
	padding-bottom: 20px;
	border-bottom: 1px solid #dddddd;

	color: #333333;
	font-family: 'Noto Sans';
	font-size: 20px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: -0.6px;
	line-height: 100%;
`;

const MenuContainer = styled.div`
	margin-top: 24px;
`;

const Menu = styled.ul`
	margin-block-start: 0;
	margin-block-end: 0;
	padding-inline-start: 0;
	& + & {
		margin-top: 23px;
	}
`;

const MainMenu = styled.li`
	list-style: none;
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const SubMenu = styled.li`
	margin-top: 13px;
	margin-left: 20px;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const BookingListColumn = styled.div`
	width: 1030px;
	margin-left: 20px;
	background-color: #ffffff;
	border-radius: 4px;
	padding: 50px 40px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		margin-left: 0;
		padding: 40px 15px;
	}
`;

const TopContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const TopTitle = styled.div`
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 20px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: -0.6px;
	line-height: 100%;
`;

const OverflowInfo = styled.div`
	align-self: flex-end;
	margin-top: 29px;
	color: #666666;
	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.42px;
	line-height: 100%;
`;

const TableTopContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const StatusButtonContainer = styled.div`
	display: flex;

	@media only screen and (max-width: 767.98px) {
		margin-top: 12px;
	}
`;

const StatusButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 65px;
	height: 26px;
	border-radius: 13px;
	border: 1px solid #0e498f;
	cursor: pointer;

	color: #0e498f;
	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.42px;
	line-height: 100%;

	& + & {
		margin-left: 5px;
	}

	${(props) =>
		props.selected &&
		css`
			color: #ffffff;
			background-color: #6d9ed8;
			border: 1px solid #6d9ed8;
		`}
`;

const TableContainer = styled.div`
	width: 100%;

	@media only screen and (max-width: 767.98px) {
		overflow: scroll;
	}
`;
const BookingListTable = styled.table`
	width: 100%;

	border-collapse: collapse;
	border-top: 2px solid #0e498f;

	text-align: center;
	margin-top: 20px;
	thead th {
		height: 45px;
		color: #0e498f;
		font-family: 'Noto Sans';
		font-size: 15px;
		font-weight: 500;
		font-style: normal;
		letter-spacing: -0.45px;
		line-height: 100%;
	}
	thead tr {
		border-bottom: 1px solid #dcdcdc;
	}

	tbody td {
		height: 54px;
		color: #666666;
		font-family: 'Noto Sans';
		font-size: 15px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: -0.45px;
		line-height: normal;
		word-break: break-all;
	}
	tbody td.strong {
		color: #333333;
		font-weight: 500;
	}
	tbody tr {
		border-bottom: 1px solid #dddddd;
	}

	tfoot {
		height: 45px;
		background: #f7f7f7;
		border-bottom: 1px solid #dddddd;
	}

	@media only screen and (max-width: 767.98px) {
		margin-top: 10px;
		thead th {
			font-size: 14px;
		}

		tbody td {
			font-size: 14px;
			word-break: break-all;
		}
	}
`;

const TableDataContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ApprovalStatusText = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 65px;
	height: 26px;
	border-radius: 13px;
	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.42px;
	line-height: 100%;

	${(props) =>
		props.status === ApprovalStatus.APPROVED &&
		css`
			color: #ffffff;
			background-color: #6d9ed8;
		`}

	${(props) =>
		(props.status === ApprovalStatus.CANCELED || props.status === ApprovalStatus.REJECTED) &&
		css`
			color: #6d9ed8;
			background-color: #e9f1fa;
		`}
	
		${(props) =>
		props.status === ApprovalStatus.PENDING &&
		css`
			color: #ffffff;
			background-color: #49bbc6;
		`}
`;

const OperatorRequestIcon = styled.img`
	cursor: pointer;
	width: 19px;
	height: 19px;
`;

const ShowBookingButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 55px;
	height: 26px;
	border-radius: 13px;
	border: 1px solid #0e498f;
	cursor: pointer;

	color: #0e498f;
	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.42px;
	line-height: 100%;
`;

const CancelBookingButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 55px;
	height: 26px;
	border-radius: 13px;
	border: 1px solid #bebfc0;
	cursor: pointer;

	color: #a0a0a0;
	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.42px;
	line-height: 100%;
`;

export default MyPage;
