import React from 'react';
import styled, { css } from 'styled-components';

const Tab = ({ tabs, tabIndex, onChange }) => {
	return (
		<Container>
			{tabs?.map((tab, idx) => {
				return (
					<TabItem key={idx} selected={tabIndex === idx} onClick={() => onChange(idx)}>
						{tab}
					</TabItem>
				);
			})}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
`;

const TabItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 50%;
	height: 50px;
	cursor: pointer;
	background-color: #c8c8c8;

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 16px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: -0.48px;
	line-height: 100%;

	border-radius: 4px 0 0 4px;

	& + & {
		border-radius: 0 4px 4px 0;
	}

	${(props) =>
		props.selected &&
		css`
			background-image: linear-gradient(to right, #10abb1 0%, #275ba6 100%);
		`}

	@media only screen and (max-width: 767.98px) {
		height: 40px;
		font-size: 15px;
	}
`;

export default Tab;
