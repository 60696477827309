import React from 'react';
import { Route } from 'react-router-dom';

import AdminLayout from '../layouts/AdminLayout';
import Dashboard from '../screens/admin/Dashboard';
import Calendar from '../screens/admin/Calendar';
import UnitList from '../screens/admin/UnitList';
import UnitRoom from '../screens/admin/UnitRoom';
import BookingList from '../screens/admin/BookingList';
import UserList from '../screens/admin/UserList';
import UserInfo from '../screens/admin/UserInfo';
import Setting from '../screens/admin/Setting';
import RoomCategorySetting from '../screens/admin/Setting/RoomCategorySetting';
import BoardCategorySetting from '../screens/admin/Setting/BoardCategorySetting';
import ScheduleSetting from '../screens/admin/Setting/ScheduleSetting';
import SmsMessageSetting from '../screens/admin/Setting/SmsMessageSetting';

import AdminSettingTab from '../constants/AdminSettingTab';
import AdminUnitListTab from '../constants/AdminUnitListTab';
import AdminBookingListTab from '../constants/AdminBookingListTab';

export default [
	<Route
		exact
		path="/admin"
		key="admin.dashboard"
		element={
			<AdminLayout>
				<Dashboard />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/dashboard"
		key="admin.dashboard"
		element={
			<AdminLayout>
				<Dashboard />
			</AdminLayout>
		}
	/>,
	<Route exact path="/admin/calendar" key="admin.calendar" element={<Calendar />} />,
	<Route
		exact
		path="/admin/unit/rooms"
		key="admin.unit.rooms"
		element={
			<AdminLayout tabs={AdminUnitListTab} tabIndex={0}>
				<UnitList />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/unit/rooms/:room_id/edit"
		key="admin.unit.rooms.edit"
		element={
			<AdminLayout>
				<UnitRoom />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/unit/rooms/create"
		key="admin.unit.rooms.create"
		element={
			<AdminLayout>
				<UnitRoom />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/booking/rooms"
		key="admin.booking.rooms"
		element={
			<AdminLayout tabs={AdminBookingListTab} tabIndex={0}>
				<BookingList />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/users"
		key="admin.users"
		element={
			<AdminLayout>
				<UserList />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/users/:user_id/edit"
		key="admin.users.edit"
		element={
			<AdminLayout>
				<UserInfo />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/users/create"
		key="admin.users.create"
		element={
			<AdminLayout>
				<UserInfo />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/settings"
		key="admin.setting"
		element={
			<AdminLayout>
				<Setting />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/settings/unit/room/categories"
		key="admin.setting.unit.room.categories"
		element={
			<AdminLayout tabs={AdminSettingTab} tabIndex={0}>
				<RoomCategorySetting />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/settings/board/categories"
		key="admin.setting.board.categories"
		element={
			<AdminLayout tabs={AdminSettingTab} tabIndex={1}>
				<BoardCategorySetting />
			</AdminLayout>
		}
	/>,
	<Route
		exact
		path="/admin/settings/schedules"
		key="admin.settings.schedules"
		element={
			<AdminLayout tabs={AdminSettingTab} tabIndex={2}>
				<ScheduleSetting />
			</AdminLayout>
		}
	/>,
	// <Route
	// 	exact
	// 	path="/admin/settings/sms_messages"
	// 	key="admin.settings.sms_messages"
	// 	element={
	// 		<AdminLayout tabs={AdminSettingTab} tabIndex={3}>
	// 			<SmsMessageSetting />
	// 		</AdminLayout>
	// 	}
	// />,
];
