import LoginType from '../constants/LoginType';

export const getUnitCategoryName = (unit, slug, depth) => {
	if (!unit) return '-';

	let categoryBySlug = unit.categories?.find((category) => category.slug === slug);
	let categoryItemByDepth = categoryBySlug?.category_items.find((categoryItem) => categoryItem.depth === depth);
	return categoryItemByDepth?.name ?? '-';
};

export const getCategoryFilterTitle = (categoryFilter, slug, depth) => {
	if (categoryFilter.length === 0) return '';
	let filter = categoryFilter
		.find((category) => category.slug === slug)
		.filters.find((categoryFilter) => categoryFilter.depth === depth);
	return filter.title;
};

export const convertDateToStr = (date) => {
	if (date === null) return null;

	return (
		`${date ? date.getFullYear() : '-'}`.padStart(2, '0') +
		'-' +
		`${date ? date.getMonth() + 1 : '-'}`.padStart(2, '0') +
		'-' +
		`${date ? date.getDate() : '-'}`.padStart(2, '0')
	);
};

export const getUserLoginStr = (user) => {
	if (user.login_type === LoginType.COMMON) {
		return user.user_login;
	}

	if (user.user_login.indexOf('gn') === 0) {
		return user.user_login.substring(2);
	}

	return user.user_login;
};
