import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import AdminSectionTitle from '../../../components/admin/AdminSectionTitle';
import SystemScheduleModal from '../../../components/modal/SystemScheduleModal';

import * as api from '../../../apis';

const ScheduleSetting = () => {
	const [scheduleList, setScheduleList] = useState([]);

	const [scheduleModalData, setScheduleModalData] = useState({
		show: false,
		id: null,
		title: null,
		startDate: null,
		endDate: null,
		allowStatus: true,
	});

	const [allChecked, setAllChecked] = useState(false);

	useEffect(() => {
		getSchedulesFromApi();
	}, []);

	useEffect(() => {
		setScheduleList(
			scheduleList.map((data) => {
				return {
					...data,
					selected: allChecked,
				};
			})
		);
	}, [allChecked]);

	const getSchedulesFromApi = () => {
		api.getSystemSchedules()
			.then((res) => {
				if (res && res.data) {
					setScheduleList(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const onAddButtonClick = () => {
		setScheduleModalData({
			show: true,
			id: null,
			name: null,
			startDate: null,
			endDate: null,
			allowStatus: true,
		});
	};

	const onModifyButtonClick = (data) => {
		setScheduleModalData({
			show: true,
			id: data.id,
			name: data.name,
			startDate: data.start_date,
			endDate: data.end_date,
			allowStatus: data.allow_status,
		});
	};

	const onClickDeleteSelected = () => {
		const selectedSchedule = scheduleList.filter((val) => val.selected);
		if (selectedSchedule.length === 0) {
			alert('선택한 일정이 없습니다.');
			return;
		}

		if (!window.confirm(`선택한 ${selectedSchedule.length}개의 스케쥴을 정말 삭제하시겠습니까?`)) return;

		let promiseArr = [];
		selectedSchedule.map((schedule) => {
			promiseArr.push(api.deleteSystemSchedule(schedule.id));
		});
		Promise.all(promiseArr).then(() => {
			alert('선택한 스케쥴을 정상적으로 삭제하였습니다.');
			getSchedulesFromApi();
		});
	};

	const onAddSystemSchedule = (name, startDate, endDate, allowStatus) => {
		const params = {
			name: name,
			start_date: startDate,
			end_date: endDate,
			allow_status: allowStatus,
		};
		if (scheduleModalData.id) {
			api.updateSystemSchedule(scheduleModalData.id, params)
				.then((res) => {
					if (res && res.data) {
						getSchedulesFromApi();
					}
				})
				.catch((err) => {
					console.error(err);
				});
		} else {
			api.createSystemSchedule(params)
				.then((res) => {
					if (res && res.data) {
						getSchedulesFromApi();
					}
				})
				.catch((err) => {
					console.error(err);
				});
		}

		setScheduleModalData({ show: false });
	};

	return (
		<Content>
			<TopContainer>
				<AdminSectionTitle title="학교 일정 관리" subTitle={`전체 ${scheduleList.length}개`} />
				<AddButton onClick={onAddButtonClick}>일정 등록</AddButton>
			</TopContainer>
			<section>
				<ScheduleListTable>
					<thead>
						<tr>
							<th style={{ minWidth: '50px' }}>
								<input
									type="checkbox"
									checked={allChecked}
									onChange={(e) => setAllChecked(e.currentTarget.checked)}
								/>
							</th>
							<th style={{ minWidth: '50px' }}>번호</th>
							<th style={{ minWidth: '150px' }}>제목</th>
							<th style={{ minWidth: '150px' }}>기간</th>
							<th style={{ minWidth: '150px' }}>예약허용</th>
							<th style={{ minWidth: '150px' }}>등록일</th>
							<th style={{ minWidth: '100px' }}>수정</th>
						</tr>
					</thead>
					<tbody>
						{scheduleList?.map((data, idx) => {
							return (
								<tr key={idx}>
									<td>
										<input
											type="checkbox"
											checked={data.selected}
											onChange={(e) =>
												setScheduleList(
													scheduleList.map((data, dataIdx) => {
														if (idx === dataIdx) {
															return {
																...data,
																selected: e.currentTarget.checked,
															};
														}
														return data;
													})
												)
											}
										/>
									</td>
									<td>{idx + 1}</td>
									<td>{data.name ?? '-'}</td>
									<td>{`${data.start_date ?? '-'} ~ ${data.end_date ?? '-'}`}</td>
									<td>{data.allow_status ? '허용' : '비허용'}</td>
									<td>{data.created_at ? new Date(data.created_at).toLocaleString() : '-'}</td>
									<td>
										<ModifyButton onClick={() => onModifyButtonClick(data)}>수정</ModifyButton>
									</td>
								</tr>
							);
						})}
					</tbody>
					<tfoot>
						<TableFoot>
							<td colSpan="100%">
								<DeleteButton onClick={onClickDeleteSelected}>선택삭제</DeleteButton>
							</td>
						</TableFoot>
					</tfoot>
				</ScheduleListTable>
			</section>

			{scheduleModalData.show && (
				<SystemScheduleModal
					defaultData={scheduleModalData}
					onConfirm={(name, startDate, endDate, allowStatus) =>
						onAddSystemSchedule(name, startDate, endDate, allowStatus)
					}
					onClose={() => setScheduleModalData({ show: false })}
				/>
			)}
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	padding: 40px 50px;
	background-color: white;
`;

const TopContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const AddButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 70px;
	height: 30px;
	border-radius: 4px;
	border: none;
	background-color: #11a9b1;

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.39px;
	line-height: 100%;

	&:disabled {
		background-color: #c0c0c0;
		color: white;
	}
`;

const ScheduleListTable = styled.table`
	width: 100%;

	border-collapse: collapse;
	border-top: 2px solid #333333;

	text-align: center;
	margin-top: 15px;
	thead th {
		height: 45px;
		color: #333333;
		font-family: 'Noto Sans';
		font-size: 15px;
		font-weight: 500;
		font-style: normal;
		letter-spacing: -0.45px;
		line-height: 100%;
		:first-child {
			border-right: 1px solid #dcdcdc;
		}
	}
	thead tr {
		border-bottom: 1px solid #dcdcdc;
	}

	tbody td {
		height: 45px;

		color: #666666;
		font-family: 'Noto Sans';
		font-size: 15px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: -0.45px;
		line-height: 100%;
		:first-child {
			border-right: 1px solid #dddddd;
		}
	}
	tbody tr {
		border-bottom: 1px solid #dddddd;
	}

	tfoot {
		height: 45px;
		background: #f7f7f7;
		border-bottom: 1px solid #dddddd;
	}
`;

const TableFoot = styled.tr`
	text-align: left;
`;

const DeleteButton = styled.button`
	width: 60px;
	height: 28px;

	margin-left: 11px;
	border: none;
	border-radius: 4px;
	background-color: #275ba6;
	color: white;

	font-family: 'Noto Sans';
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.39px;
	line-height: 100%;
`;

const ModifyButton = styled.button`
	width: 55px;
	height: 26px;

	border: none;
	border-radius: 13px;
	background-color: #275ba6;
	color: white;

	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.42px;
	line-height: 100%;
`;

export default ScheduleSetting;
