import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import ApprovalStatus from '../../constants/ApprovalStatus';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import koLocale from '@fullcalendar/core/locales/ko';

import BookingItemModal from '../../components/modal/BookingItemModal';
import LoadingIndicator from '../../components/LoadingIndicator';

import * as api from '../../apis';

const DashboardCalendar = ({ standalone }) => {
	const [unitList, setUnitList] = useState(null);
	const [calendarUnitId, setCalendarUnitId] = useState(null);
	const [bookingList, setBookingList] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const [bookingItemModalData, setBookingItemModalData] = useState(null);
	const [bookingItemModalVisibility, setBookingItemModalVisiblity] = useState(false);

	useEffect(() => {
		let filterParams = [`page_size=${Number.MAX_SAFE_INTEGER}`];
		api.getUnits(filterParams)
			.then((res) => {
				if (res && res.data) {
					setUnitList(res.data);
					setCalendarUnitId(res.data.items[0].id);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	useEffect(() => {
		if (!calendarUnitId) return;
		getBookingsFromApi();
	}, [calendarUnitId]);

	const getBookingsFromApi = () => {
		let filterParams = [
			`unit_id=${calendarUnitId}`,
			`approval_statuses[]=${ApprovalStatus.APPROVED}`,
			`page_size=${Number.MAX_SAFE_INTEGER}`,
		];

		setIsLoading(true);
		api.getBookings(filterParams)
			.then((res) => {
				if (res && res.data) {
					setBookingList(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const getBookingEvents = () => {
		return (
			bookingList.items?.map((booking) => {
				return {
					url: booking.id,
					title: `#${booking.id} ${booking.user.name}`,
					allDay: false,
					start: booking.booking_start_date,
					end: booking.booking_end_date,
				};
			}) ?? []
		);
	};

	const onOpenStandalone = () => {
		window.open('/admin/calendar');
	};

	const onClickEvent = (eventInfo) => {
		eventInfo.jsEvent.preventDefault();

		let booking = bookingList.items.find((booking) => booking.id == eventInfo.event.url);
		setBookingItemModalData(booking);
		setBookingItemModalVisiblity(true);
	};

	return (
		<>
			<LoadingIndicator size={50} loading={isLoading} />
			<UnitSelectContainer>
				<UnitSelectTitle>시설 선택</UnitSelectTitle>
				<UnitSelect value={calendarUnitId ?? ''} onChange={(e) => setCalendarUnitId(e.currentTarget.value)}>
					{unitList?.items.map((unit, idx) => {
						return (
							<option key={idx} value={unit.id}>
								{unit.name ?? '-'}
							</option>
						);
					})}
				</UnitSelect>
				{!standalone && <OpenStandaloneButton onClick={onOpenStandalone}>새 창으로 보기</OpenStandaloneButton>}
			</UnitSelectContainer>
			<CalendarContainer>
				<FullCalendar
					plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
					initialView="dayGridMonth"
					locale={koLocale}
					headerToolbar={{
						start: 'today prev,next',
						center: 'title',
						end: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
					}}
					buttonText={{
						today: '오늘',
						month: '월',
						week: '주',
						day: '일',
						list: '목록',
					}}
					fixedWeekCount={false}
					dayHeaderClassNames="day-header"
					events={getBookingEvents()}
					eventClick={(e) => onClickEvent(e)}
					height={standalone ? 'calc(100vh - 180px)' : '700px'}
				/>
			</CalendarContainer>
			{bookingItemModalVisibility && (
				<BookingItemModal
					booking={bookingItemModalData}
					onClose={() => {
						setBookingItemModalVisiblity(false);
					}}
				/>
			)}
		</>
	);
};

const UnitSelectContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 12px;
`;

const UnitSelectTitle = styled.div`
	color: #0e498f;
	font-family: 'Noto Sans';
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
	text-align: center;
`;

const UnitSelect = styled.select`
	width: 200px;
	height: 35px;
	padding: 8px;
	margin-left: 8px;

	border: 1px solid #dddddd;

	appearance: none;
	background-image: linear-gradient(45deg, transparent 50%, gray 50%),
		linear-gradient(135deg, gray 50%, transparent 50%);
	background-position: calc(100% - 20px) calc(1em + 0px), calc(100% - 15px) calc(1em + 0px), calc(100% - 2.5em) 0.5em;
	background-size: 5px 5px, 5px 5px, 1px 1.5em;
	background-repeat: no-repeat;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const OpenStandaloneButton = styled.button`
	width: 120px;
	height: 35px;

	margin-left: 11px;
	border: none;
	border-radius: 4px;
	background-color: #275ba6;
	color: white;

	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.39px;
	line-height: 100%;
`;

const CalendarContainer = styled.div`
	margin-top: 20px;
	width: 100%;
`;

export default DashboardCalendar;
