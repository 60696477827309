import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import useSizeDetector from '../hooks/useSizeDetector';

import * as api from '../apis';
import ApprovalStatus from '../constants/ApprovalStatus';

import BookingCompleteIconImage from '../assets/images/screens/Booking/booking_complete_icon.png';
import ContactContainerImage from '../assets/images/screens/Booking/contact_container.png';
import MobileContactContainerImage from '../assets/images/screens/Booking/mobile_contact_container.png';

const BookingComplete = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { isDesktop } = useSizeDetector();

	const [bookingData, setBookingData] = useState(null);
	const [isWait, setIsWait] = useState(false);

	useEffect(() => {
		if (!location.state?.booking_id) {
			alert('예약 정보가 없습니다.');
			navigate('/booking');
			return;
		}

		api.getBooking(location.state?.booking_id)
			.then((res) => {
				if (res && res.data) {
					setBookingData(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	useEffect(() => {
		if (bookingData) {
			setIsWait(bookingData?.approval_status !== ApprovalStatus.APPROVED);
		}
	}, [bookingData]);

	const onClickConfirm = () => {
		navigate('/mypage');
	};

	return (
		<Content>
			<BookingCompleteIcon src={BookingCompleteIconImage} />
			<Title>예약 신청 완료</Title>
			<BookingId>{`예약 번호 #${bookingData?.id ?? '0'}`}</BookingId>
			<ProgressContainer>
				<ProgressBarContainer>
					<ProgressBarCircle />
					<ProgressBarLine />
					<ProgressBarCircle />
					<ProgressBarLine deActivate={isWait} />
					<ProgressBarCircle deActivate={isWait} />
				</ProgressBarContainer>
				<ProgressTextContainer>
					<ProgressText>예약 신청</ProgressText>
					<ProgressText>신청 내역 확인</ProgressText>
					<ProgressText deActivate={isWait}>예약 승인</ProgressText>
				</ProgressTextContainer>
			</ProgressContainer>
			<ContactContainer isDesktop={isDesktop}>
				<ContactTitle>예약 관련 문의</ContactTitle>
				<ContactNumber>{location.state?.contact_number ?? '-'}</ContactNumber>
			</ContactContainer>
			<ConfirmButton onClick={onClickConfirm}>완료</ConfirmButton>
		</Content>
	);
};

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 100%;
	max-width: 1240px;
	margin-top: 50px;
	padding: 50px;
	border-radius: 8px;
	background-color: white;

	@media only screen and (max-width: 767.98px) {
		width: calc(100% - 30px);
		padding: 30px 15px 36px 15px;
		margin: 20px 15px;
	}
`;

const BookingCompleteIcon = styled.img`
	width: 90px;
	height: 90px;
	margin-top: 5px;

	@media only screen and (max-width: 767.98px) {
		width: 70px;
		height: 70px;
		margin-top: 0;
	}
`;

const Title = styled.span`
	margin-top: 29px;
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 30px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: -0.9px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		font-size: 20px;
		margin-top: 25px;
	}
`;

const BookingId = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 160px;
	height: 45px;
	margin-top: 20px;
	border: 1px solid #0e498f;
	border-radius: 4px;

	color: #0e498f;
	font-family: 'Noto Sans';
	font-size: 16px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: -0.48px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		width: 140px;
		height: 40px;
		font-size: 14px;
	}
`;

const ProgressContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	width: 490px;
	height: 55px;
	margin-top: 30px;

	@media only screen and (max-width: 767.98px) {
		width: 265px;
		height: 40px;
		margin-top: 25px;
	}
`;

const ProgressBarContainer = styled.div`
	display: flex;
	align-items: center;
	position: relative;
`;

const ProgressBarCircle = styled.div`
	width: 10px;
	height: 10px;

	background: #0e498f;
	box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.3);
	border: 6px solid #e7edf4;
	box-sizing: content-box;
	border-radius: 10px;

	& ~ & {
		margin-left: 185px;
	}
	${(props) =>
		props.deActivate &&
		css`
			background-color: #e7edf4;
			border: 6px solid #e7edf4;
		`}

	@media only screen and (max-width: 767.98px) {
		width: 7px;
		height: 7px;
		border: 3px solid #e7edf4;
		border-radius: 7px;

		& ~ & {
			margin-left: 90px;
		}
	}
`;

const ProgressBarLine = styled.div`
	position: absolute;
	left: 11px;
	width: 207px;
	height: 5px;
	background-color: #0e498f;

	& ~ & {
		left: 223px;
	}
	${(props) =>
		props.deActivate &&
		css`
			background-color: #e7edf4;
		`}

	@media only screen and (max-width: 767.98px) {
		width: 100px;
		& ~ & {
			left: 112px;
		}
	}
`;

const ProgressTextContainer = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	width: 100%;
	margin-top: 15px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 9px;
	}
`;

const ProgressText = styled.span`
	color: #0e498f;
	font-family: 'Noto Sans';
	font-size: 18px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.54px;
	line-height: 100%;

	${(props) =>
		props.deActivate &&
		css`
			color: #dddddd;
		`}
	& + & {
		margin-left: 125px;
	}

	@media only screen and (max-width: 767.98px) {
		font-size: 14px;
		& + & {
			margin-left: 40px;
		}
	}
`;

const ContactContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 986px;
	height: 126px;
	margin-top: 40px;
	background-image: url(${(props) => (props.isDesktop ? ContactContainerImage : MobileContactContainerImage)});

	@media only screen and (max-width: 767.98px) {
		width: 316px;
		height: 116px;
		background-size: 100% 100%;
		flex-direction: column;
	}
`;

const ContactTitle = styled.div`
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 18px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.54px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		font-size: 14px;
	}
`;

const ContactNumber = styled.div`
	margin-left: 15px;
	color: #0e498f;
	font-family: 'Noto Sans';
	font-size: 26px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: -0.78px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		margin-top: 14px;
		margin-left: 0;
		font-size: 22px;
	}
`;

const ConfirmButton = styled.button`
	width: 130px;
	height: 45px;
	margin-top: 30px;
	background-image: linear-gradient(to right, #10abb1 0%, #275ba6 100%);
	border: none;
	border-radius: 22px;

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.48px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		width: 110px;
		height: 40px;
		margin-top: 20px;
	}
`;

export default BookingComplete;
