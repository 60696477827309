export default {
	COMMON: 0, // 일반 사용자
	ADMINISTRATOR: 1, // 부분 시스템 운영자
	SYSTEM_ADMINISTRATOR: 9, // 시스템 운영자

	toString: (val) => {
		switch (val) {
			case 0:
				return '일반 사용자';
			case 1:
				return '부분 시스템 운영자';
			case 9:
				return '시스템 운영자';
			default:
				return '-';
		}
	},
};
