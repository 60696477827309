import React from 'react';
import styled, { css } from 'styled-components';

const DashboardSummaryCard = ({ title, background, color, count, large, onClick }) => {
	return (
		<CardContainer background={background} large={large} onClick={onClick}>
			<Title color={color}>{title}</Title>
			<Count color={color} large={large}>
				{count}
			</Count>
		</CardContainer>
	);
};

const CardContainer = styled.div`
	width: 160px;
	height: 160px;
	border-radius: 8px;
	cursor: pointer;

	& + & {
		margin-left: 10px;
	}

	background: ${(props) => props.background};
	${(props) =>
		props.large &&
		css`
			width: 300px;
		`}
`;

const Title = styled.div`
	margin-top: 30px;
	margin-left: 28px;

	color: ${(props) => props.color};
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: 0px;
	line-height: 21px;
`;

const Count = styled.div`
	width: 160px;
	margin-top: 41px;
	padding-right: 26px;

	text-align: right;
	color: ${(props) => props.color};
	font-family: 'Noto Sans';
	font-size: 37px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: 0px;
	line-height: 54px;

	${(props) =>
		props.large &&
		css`
			width: 274px;
		`}
`;

export default DashboardSummaryCard;
