import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

import AdminSectionTitle from '../components/admin/AdminSectionTitle';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import LockIcon from '@mui/icons-material/Lock';
import UserRole from '../constants/UserRole';

import * as api from '../apis';

const Post = () => {
	const { board_id, post_id } = useParams();
	const navigate = useNavigate();
	const { user } = useContext(UserContext);

	const [post, setPost] = useState(null);
	const [comment, setComment] = useState('');

	useEffect(() => {
		getPostWithApi();
	}, []);

	const getPostWithApi = () => {
		api.getPost(board_id, post_id)
			.then((res) => {
				if (res && res.data) {
					if (!hasReadPermission(res.data)) {
						alert('게시글에 대한 접근 권한이 없습니다.');
						navigate(-1);
						return;
					}
					setPost(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const hasReadPermission = (post) => {
		if (!post.secret) {
			return true;
		}

		if (!user) {
			return false;
		}

		if (user && user.user_role === UserRole.SYSTEM_ADMINISTRATOR) {
			return true;
		}

		if (user && user.id === post.user.id) {
			return true;
		}

		return false;
	};

	const onClickEditPost = () => {
		navigate(`/boards/${board_id}/posts/${post_id}/edit`);
	};

	const onClickDeletePost = () => {
		if (!window.confirm('정말 삭제하시겠습니까?')) {
			return;
		}

		api.deletePost(board_id, post_id)
			.then((res) => {
				alert('게시글을 삭제하였습니다.');
				navigate(-1);
			})
			.catch((err) => {
				console.error(err);
				alert('게시글 삭제에 실패하였습니다.');
			});
	};

	const onClickDeleteComment = (commentId) => {
		if (!window.confirm('정말 삭제하시겠습니까?')) {
			return;
		}

		api.deleteComment(board_id, post_id, commentId)
			.then((res) => {
				alert('댓글을 삭제하였습니다.');
				getPostWithApi();
			})
			.catch((err) => {
				console.error(err);
				alert('댓글 삭제에 실패하였습니다.');
			});
	};

	const onClickWriteComment = () => {
		api.createComment(board_id, post_id, { comment })
			.then((res) => {
				if (res && res.data) {
					alert('등록되었습니다.');
					setComment('');
					getPostWithApi();
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const onClickConfirm = () => {
		navigate(`/boards/${board_id}`);
	};

	const hasEditPermission = () => {
		if (!user || !post) {
			return false;
		}

		if (user && user.user_role === UserRole.SYSTEM_ADMINISTRATOR) {
			return true;
		}

		if (post.user.id === user.id) {
			return true;
		}

		return false;
	};

	const hasCommentDeletePermission = (comment) => {
		if (!user || !post) {
			return false;
		}

		if (user && user.user_role === UserRole.SYSTEM_ADMINISTRATOR) {
			return true;
		}

		if (comment.user.id === user.id) {
			return true;
		}

		return false;
	};

	const hasCommentPermission = () => {
		if (!user || !post) {
			return false;
		}

		return true;
	};

	if (!post) return null;

	return (
		<Content>
			<PostTitle>
				<PostTitleContainer>
					<AdminSectionTitle title={post?.title ?? '-'} />
					{post?.secret && <StyledLockIcon />}
				</PostTitleContainer>
				<ButtonContainer>
					{hasEditPermission() && <PostEditButton onClick={onClickEditPost}>수정</PostEditButton>}
					{hasEditPermission() && <PostDeleteButton onClick={onClickDeletePost}>삭제</PostDeleteButton>}
				</ButtonContainer>
			</PostTitle>
			<section>
				<PostInfoContainer>
					<PostCategory>{`[${post?.category?.name ?? '미분류'}]`}</PostCategory>
					<PostUserName>{post?.user.name}</PostUserName>
					<PostCreatedAt>
						{post?.created_at ? new Date(post.created_at).toLocaleDateString() : '-'}
					</PostCreatedAt>
				</PostInfoContainer>
			</section>
			<section className="mt-24px">
				<ViewerContainer>
					<PostViewer dangerouslySetInnerHTML={{ __html: post?.description ?? '-' }} />
				</ViewerContainer>
			</section>
			<SectionSeparator />
			<section className="mt-24px">
				<CommentTitle>댓글</CommentTitle>
				<CommentContainer>
					{post?.comments.map((comment, idx) => {
						return (
							<CommentItem key={idx}>
								<CommentRow>
									<CommentUser>{comment.user.name}</CommentUser>
									<CommentCreatedAt>
										{comment.created_at ? new Date(comment.created_at).toLocaleString() : '-'}
									</CommentCreatedAt>
									{hasCommentDeletePermission(comment) && (
										<CommentDeleteButton onClick={(e) => onClickDeleteComment(comment.id)} />
									)}
								</CommentRow>
								<CommentRow>
									<Comment>{comment.comment}</Comment>
								</CommentRow>
							</CommentItem>
						);
					})}
				</CommentContainer>
				{hasCommentPermission() && (
					<CommentInputContainer>
						<CommentInput
							value={comment}
							placeholder="댓글을 입력해주세요"
							onChange={(e) => setComment(e.currentTarget.value)}
						/>
						<CommentWriteButton onClick={onClickWriteComment}>등록</CommentWriteButton>
					</CommentInputContainer>
				)}
			</section>
			<section>
				<FooterContainer>
					<OkButton onClick={onClickConfirm}>확인</OkButton>
				</FooterContainer>
			</section>
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	margin-top: 50px;
	padding: 40px 50px;
	border-radius: 8px;
	background-color: white;

	@media only screen and (max-width: 767.98px) {
		width: calc(100% - 30px);
		margin: 20px 15px 0 15px;
		padding: 20px 15px 40px 15px;
	}
`;

const SectionSeparator = styled.div`
	width: 100%;
	margin: 28px 0 16px 0;
	border: 1px solid #dddddd;
`;

const PostTitle = styled.div`
	display: flex;
	justify-content: space-between;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column-reverse;
	}
`;

const PostTitleContainer = styled.div`
	display: flex;
	align-items: center;
`;

const StyledLockIcon = styled(LockIcon)`
	font-size: 18px !important;
	margin-left: 8px;
	margin-bottom: 1px;
`;

const ButtonContainer = styled.div`
	display: flex;

	@media only screen and (max-width: 767.98px) {
		align-self: flex-end;
	}
`;

const PostEditButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 60px;
	height: 30px;

	border-radius: 8px;
	border: none;
	background-color: #11a9b1;
	cursor: pointer;

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.39px;
	line-height: 100%;
`;

const PostDeleteButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 60px;
	height: 30px;
	margin-left: 8px;

	border-radius: 8px;
	border: none;
	background-color: #275ba6;
	cursor: pointer;

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.39px;
	line-height: 100%;
`;

const PostInfoContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 8px;
`;

const PostCategory = styled.div`
	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: normal;
`;

const PostUserName = styled.div`
	margin-left: 8px;
	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: normal;
`;

const PostCreatedAt = styled.div`
	margin-left: 8px;
	color: #666666;
	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: normal;
`;

const ViewerContainer = styled.div`
	width: 100%;
	margin-top: 8px;
`;

const PostViewer = styled.div`
	width: 100%;
	font-size: 16px;
	margin-top: 10px;
	overflow-x: scroll;
`;

const CommentTitle = styled.div`
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 18px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const CommentContainer = styled.div`
	margin-top: 8px;
`;

const CommentItem = styled.div`
	& + & {
		margin-top: 12px;
	}
`;

const CommentRow = styled.div`
	display: flex;
	align-items: center;
`;

const Comment = styled.div`
	margin-top: 4px;
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const CommentUser = styled.div`
	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const CommentCreatedAt = styled.div`
	margin-left: 8px;
	color: #666666;
	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: normal;
`;

const CommentDeleteButton = styled(DeleteForeverOutlinedIcon)`
	font-size: 22px !important;
	color: #666666;
	cursor: pointer;
`;

const CommentInputContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 16px;
`;

const CommentInput = styled.input`
	width: calc(100% - 80px);
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const CommentWriteButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 60px;
	height: 30px;
	margin-left: 10px;

	border-radius: 8px;
	border: none;
	background-color: #11a9b1;
	cursor: pointer;

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.39px;
	line-height: 100%;
`;

const FooterContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 30px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 20px;
	}
`;

const OkButton = styled.button`
	width: 100px;
	height: 40px;

	border: none;
	border-radius: 20px;
	margin-left: 10px;
	background-image: linear-gradient(to right, #10abb1 0%, #275ba6 100%);

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.48px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		width: 85px;
		height: 33px;
		font-size: 14px;
	}
`;

export default Post;
