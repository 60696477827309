import React, { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import AdminHeader from '../components/admin/AdminHeader';
import Footer from '../components/Footer';
import AdminTab from '../components/admin/AdminTab';

import UserRole from '../constants/UserRole';

import { UserContext } from '../contexts/UserContext';

const AdminLayout = ({ tabs, tabIndex, children }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const { user } = useContext(UserContext);

	useEffect(() => {
		if (user?.user_role !== UserRole.SYSTEM_ADMINISTRATOR) {
			navigate('/');
		}
	}, []);

	useEffect(() => {
		const scrollToTop = () => window.scrollTo(0, 0);
		return () => {
			scrollToTop();
		};
	}, [location]);

	return (
		<Container>
			<AdminHeader />
			<Content>
				{tabs ? (
					<AdminTab tabs={tabs} tabIndex={tabIndex}>
						{children}
					</AdminTab>
				) : (
					children
				)}
			</Content>
			<Footer />
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	height: 100%;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 100%;
	min-height: calc(100vh - 180px);
	padding: 145px 0 48px 0;

	background-color: #f5f5f5;
`;

export default AdminLayout;
