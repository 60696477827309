import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import AdminSectionTitle from '../components/admin/AdminSectionTitle';
import BoardSlug from '../constants/BoardSlug';
import { Editor } from '@tinymce/tinymce-react';

import * as api from '../apis';

const PostCreate = () => {
	const { board_id, post_id } = useParams();
	const isEdit = post_id ? true : false;
	const navigate = useNavigate();

	const [board, setBoard] = useState(null);
	const [postCategoryList, setPostCategoryList] = useState(null);
	const [categoryId, setCategoryId] = useState(null);
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [secret, setSecret] = useState(false);
	const descriptionEditorRef = useRef();
	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		api.getBoard(board_id)
			.then((res) => {
				if (res && res.data) {
					setBoard(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
		api.getPostCategories(board_id)
			.then((res) => {
				if (res && res.data) {
					setPostCategoryList(res.data);
					if (res.data.length !== 0) {
						setCategoryId(res.data[0].id);
					}
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	useEffect(() => {
		if (isEdit) {
			api.getPost(board_id, post_id)
				.then((res) => {
					if (res && res.data) {
						setCategoryId(res.data.category?.id ?? null);
						setTitle(res.data.title);
						setDescription(res.data?.description ?? '');
						setSecret(res.data?.secret ?? false);
						setInitialized(true);
					}
				})
				.catch((err) => {
					console.error(err);
				});
		} else {
			setInitialized(true);
		}
	}, [postCategoryList]);

	const onEditorImageUpload = (callback, value, meta) => {
		let input = document.createElement('input');
		input.setAttribute('type', 'file');
		input.setAttribute('accept', 'image/*');
		input.onchange = function () {
			let file = this.files[0];
			(async () => {
				let formData = new FormData();
				formData.append('file', file);
				api.createFile(formData)
					.then((res) => {
						let url = `${process.env.REACT_APP_API_URL}/storage/files/${res.data.filename}`;
						callback(url, { alt: file.name, title: file.name });
					})
					.catch((err) => {
						console.error(err);
					});
			})();
		};
		input.click();
	};

	const onClickSave = () => {
		const data = {
			category_id: categoryId,
			title,
			description,
			secret,
		};

		if (!validateData(data)) {
			return;
		}

		if (isEdit) {
			api.updatePost(board_id, post_id, data)
				.then((res) => {
					if (res && (res.data || res.status === 204)) {
						alert('수정되었습니다.');
						navigate(-1);
					}
				})
				.catch((err) => {
					console.error(err);
				});
		} else {
			api.createPost(board_id, data)
				.then((res) => {
					if (res && res.data) {
						alert('저장되었습니다.');
						navigate(-1);
					}
				})
				.catch((err) => {
					console.error(err);
				});
		}
	};

	const validateData = (data) => {
		if (!data.title) {
			alert('제목을 입력하여 주십시오.');
			return false;
		}
		if (!data.description) {
			alert('내용을 입력하여 주십시오.');
			return false;
		}

		return true;
	};

	return (
		<Content>
			<AdminSectionTitle title={isEdit ? '게시글 수정' : '게시글 작성'} />
			{postCategoryList && postCategoryList.length !== 0 && (
				<section className="mt-24px">
					<SubTitle>분류</SubTitle>
					<CategorySelect value={categoryId ?? ''} onChange={(e) => setCategoryId(e.currentTarget.value)}>
						{postCategoryList.map((postCategory, _) => {
							return (
								<option key={_} value={postCategory.id}>
									{postCategory.name}
								</option>
							);
						})}
					</CategorySelect>
				</section>
			)}
			<section className="mt-24px">
				<SubTitle>제목</SubTitle>
				<TitleInput
					disabled={!initialized}
					value={title}
					placeholder="제목을 입력해주세요"
					onChange={(e) => setTitle(e.currentTarget.value)}
				/>
			</section>
			<section className="mt-24px">
				<SubTitle>내용</SubTitle>
				<EditorContainer>
					<Editor
						apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
						init={{
							language: 'ko_KR',
							language_url: '/libs/tinymce/langs/ko_KR.js',
							height: '100%',
							menubar: false,
							plugins: [
								'advlist autolink lists link image charmap print preview anchor',
								'searchreplace visualblocks code fullscreen',
								'insertdatetime media table paste code help wordcount',
							],
							toolbar:
								'undo redo | formatselect | ' +
								'bold italic underline forecolor backcolor | alignleft aligncenter ' +
								'alignright alignjustify | bullist numlist outdent indent | ' +
								'image | help',
							content_style:
								'body { font-family:"Noto Sans"; Helvetica,Arial,sans-serif; font-size:16px }',
							image_title: false,
							automatic_uploads: true,
							convert_urls: false,
							file_picker_types: 'image',
							file_picker_callback: onEditorImageUpload,
						}}
						initialValue=""
						value={description}
						onInit={(evt, editor) => (descriptionEditorRef.current = editor)}
						onEditorChange={(newValue, editor) => setDescription(newValue)}
					/>
				</EditorContainer>
			</section>
			{board?.slug === BoardSlug.QNA && (
				<section>
					<SecretCheckboxContainer>
						<div>
							<SecretCheckbox
								type="checkBox"
								name="secret_checkbox"
								id="secret_checkbox"
								checked={secret}
								onChange={(e) => setSecret(e.currentTarget.checked)}
							/>
							<SecretCheckboxLabel htmlFor="secret_checkbox">비밀글</SecretCheckboxLabel>
						</div>
						<SecretCheckboxDescription>
							※ 비밀글로 설정하여 글을 작성할 경우, 게시글을 나와 운영자만 확인할 수 있습니다.
						</SecretCheckboxDescription>
					</SecretCheckboxContainer>
				</section>
			)}
			<section>
				<FooterContainer>
					<CancelButton onClick={() => navigate(-1)}>취소</CancelButton>
					<OkButton onClick={onClickSave}>저장</OkButton>
				</FooterContainer>
			</section>
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	margin-top: 50px;
	padding: 40px 50px;
	border-radius: 8px;
	background-color: white;

	@media only screen and (max-width: 767.98px) {
		width: calc(100% - 30px);
		margin: 20px 15px 0 15px;
		padding: 20px 15px 40px 15px;
	}
`;

const SubTitle = styled.div`
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 18px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		font-size: 15px;
	}
`;

const CategorySelect = styled.select`
	width: 200px;
	height: 35px;
	padding-left: 8px;
	margin-top: 8px;

	border-radius: 4px;
	border: 1px solid #dddddd;

	appearance: none;
	background-image: linear-gradient(45deg, transparent 50%, gray 50%),
		linear-gradient(135deg, gray 50%, transparent 50%);
	background-position: calc(100% - 15px) calc(1em - 2px), calc(100% - 10px) calc(1em - 2px), calc(100% - 2.5em) 0.5em;
	background-size: 5px 5px, 5px 5px, 1px 1.5em;
	background-repeat: no-repeat;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.39px;
	line-height: 100%;
`;

const TitleInput = styled.input`
	width: 100%;
	height: 35px;
	padding: 8px;
	margin-top: 8px;

	border: 1px solid #dddddd;
	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const EditorContainer = styled.div`
	width: 100%;
	height: 600px;
	margin-top: 8px;
`;

const SecretCheckboxContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 16px;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const SecretCheckbox = styled.input`
	width: 15px;
	height: 15px;
	border: 1px solid #c5c5c5;
	cursor: pointer;
`;

const SecretCheckboxLabel = styled.label`
	margin-left: 8px;
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 15px;
	cursor: pointer;

	@media only screen and (max-width: 767.98px) {
		font-size: 16px;
		line-height: 16px;
	}
`;

const SecretCheckboxDescription = styled.div`
	margin-left: 16px;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	line-height: 15px;
	color: #0e498f;

	@media only screen and (max-width: 767.98px) {
		margin-left: 0;
		margin-top: 4px;
		font-size: 14px;
		line-height: 14px;
	}
`;

const FooterContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 30px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 20px;
	}
`;

const CancelButton = styled.button`
	width: 100px;
	height: 40px;

	border: 1px solid #0e498f;
	border-radius: 20px;
	background-color: #ffffff;

	color: #0e498f;
	font-family: 'Noto Sans';
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.48px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		width: 85px;
		height: 33px;
		font-size: 14px;
	}
`;

const OkButton = styled.button`
	width: 100px;
	height: 40px;

	border: none;
	border-radius: 20px;
	margin-left: 10px;
	background-image: linear-gradient(to right, #10abb1 0%, #275ba6 100%);

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.48px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		width: 85px;
		height: 33px;
		font-size: 14px;
	}
`;

export default PostCreate;
