import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { UserContext } from '../contexts/UserContext';

import CategorySlug from '../constants/CategorySlug';

import Tab from '../components/Tab';
import TimeSelect from '../components/TimeSelect';

import FilterPlaceIconImage from '../assets/images/screens/Booking/filter_place_icon.png';
import FilterTypeIconImage from '../assets/images/screens/Booking/filter_type_icon.png';
import FilterDateIconImage from '../assets/images/screens/Booking/filter_date_icon.png';
import FilterTimeIconImage from '../assets/images/screens/Booking/filter_time_icon.png';
import CategoryItemSelectedImage from '../assets/images/screens/Booking/category_item_selected.png';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';

import * as api from '../apis';
import * as utils from '../utils';

const Booking = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { user } = useContext(UserContext);

	const [tabIdx, setTabIdx] = useState(0);
	const [categoryList, setCategoryList] = useState(null);
	const [typeCategoryItemId, setTypeCategoryItemId] = useState(null);
	const [typeCategoryItemList, setTypeCategoryItemList] = useState([]);
	const [placeCategoryItemList, setPlaceCategoryItemList] = useState(null);

	const [placeCategoryFilter, setPlaceCategoryFilter] = useState([]);
	const [placeCategoryDepth2Filter, setPlaceCategoryDepth2Filter] = useState([]);
	const [typeCategoryFilter, setTypeCategoryFilter] = useState([]);
	const [usageDateFilter, setUsageDateFilter] = useState(null);
	const [startTimeFilter, setStartTimeFilter] = useState(null);
	const [endTimeFilter, setEndTimeFilter] = useState(null);

	const [unitList, setUnitList] = useState(null);

	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		if (!user) {
			navigate('/login');
			return;
		}

		api.getCategories()
			.then((res) => {
				if (res && res.data) {
					let tabIdx = location.state?.tab_idx ?? 0;

					let typeCategory = res.data.find((category) => category.slug === CategorySlug.TYPE);
					setTypeCategoryItemList(typeCategory.items);
					let placeCategory = res.data.find((category) => category.slug === CategorySlug.PLACE);
					setPlaceCategoryItemList(placeCategory.items);

					setCategoryList(res.data);
					setTypeCategoryItemId(typeCategory.items[tabIdx].id);
					setTabIdx(tabIdx);
					setInitialized(true);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	useEffect(() => {
		if (
			placeCategoryFilter ||
			placeCategoryDepth2Filter ||
			typeCategoryFilter ||
			usageDateFilter ||
			startTimeFilter ||
			endTimeFilter ||
			typeCategoryItemId
		) {
			filterChange();
		}
	}, [
		placeCategoryFilter,
		placeCategoryDepth2Filter,
		typeCategoryFilter,
		usageDateFilter,
		startTimeFilter,
		endTimeFilter,
		tabIdx,
		typeCategoryItemId,
	]);

	const filterChange = () => {
		if (typeCategoryItemId === null) return;

		let filterParams = [`published[]=true`, `only_enabled=true`, `page_size=${Number.MAX_SAFE_INTEGER}`];

		filterParams.push(`category_item_ids[0][]=${typeCategoryItemId}`);
		if (placeCategoryFilter.length !== 0) {
			placeCategoryFilter.forEach((categoryItemId) =>
				filterParams.push(`category_item_ids[1][]=${categoryItemId}`)
			);
		}
		if (placeCategoryDepth2Filter.length !== 0) {
			placeCategoryDepth2Filter.forEach((categoryItemId) =>
				filterParams.push(`category_item_ids[2][]=${categoryItemId}`)
			);
		}
		if (typeCategoryFilter.length !== 0) {
			typeCategoryFilter.forEach((categoryItemId) =>
				filterParams.push(`category_item_ids[3][]=${categoryItemId}`)
			);
		}
		if (usageDateFilter) filterParams.push(`usage_start_date=${usageDateFilter}`);
		if (startTimeFilter) filterParams.push(`booking_start_time=${startTimeFilter}`);
		if (endTimeFilter) filterParams.push(`booking_end_time=${endTimeFilter}`);

		getUnitsFromApi(filterParams);
	};

	const getUnitsFromApi = (filterParams) => {
		api.getUnits(filterParams)
			.then((res) => {
				if (res && res.data) {
					setUnitList(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const changeTab = (tabIdx) => {
		let typeCategory = categoryList.find((category) => category.slug === CategorySlug.TYPE);

		setTypeCategoryItemList(
			typeCategoryItemList.map((typeCategoryItem) => {
				return {
					...typeCategoryItem,
					children: typeCategoryItem.children.map((typeCategoryItemChild) => {
						return {
							...typeCategoryItemChild,
							selected: false,
						};
					}),
				};
			})
		);

		setTypeCategoryItemId(typeCategory.items[tabIdx].id);
		setTabIdx(tabIdx);
		setTypeCategoryFilter([]);
	};

	const changeCategoryFilter = (categoryItem, target, setter) => {
		if (categoryItem.selected) {
			setter(target.filter((categoryId) => categoryId !== categoryItem.id));
		} else {
			let newPlaceCategoryFilter = target.map((categoryId) => categoryId);
			newPlaceCategoryFilter.push(categoryItem.id);
			setter(newPlaceCategoryFilter);
		}
	};

	const onClickPlaceCategoryItem = (categoryItem) => {
		setPlaceCategoryItemList(
			placeCategoryItemList.map((placeCategoryItem) => {
				if (placeCategoryItem.id === categoryItem.id) {
					changeCategoryFilter(placeCategoryItem, placeCategoryFilter, setPlaceCategoryFilter);
					return {
						...placeCategoryItem,
						selected: placeCategoryItem.selected ? false : true,
					};
				}
				return placeCategoryItem;
			})
		);
	};

	const onCheckPlaceCategoryItemDepth2 = (categoryItem) => {
		setPlaceCategoryItemList(
			placeCategoryItemList.map((placeCategoryItem) => {
				return {
					...placeCategoryItem,
					children: placeCategoryItem.children.map((childDepth2) => {
						if (childDepth2.id === categoryItem.id) {
							let childDepth2Selected = childDepth2.selected ? false : true;

							// depth3 자식의 선택 상태 변경
							let newPlaceCategoryDepth2Filter = placeCategoryDepth2Filter.map(
								(categoryId) => categoryId
							);
							childDepth2.children.forEach((childDepth3) => {
								childDepth3.selected = childDepth2Selected;

								if (childDepth2Selected) {
									newPlaceCategoryDepth2Filter.push(childDepth3.id);
								} else {
									newPlaceCategoryDepth2Filter = newPlaceCategoryDepth2Filter.filter(
										(categoryId) => categoryId !== childDepth3.id
									);
								}
							});
							setPlaceCategoryDepth2Filter(newPlaceCategoryDepth2Filter);

							return {
								...childDepth2,
								selected: childDepth2Selected,
								indeterminate: false,
							};
						}
						return childDepth2;
					}),
				};
			})
		);
	};

	const onCheckPlaceCategoryItemDepth3 = (categoryItem) => {
		setPlaceCategoryItemList(
			placeCategoryItemList.map((placeCategoryItem) => {
				return {
					...placeCategoryItem,
					children: placeCategoryItem.children.map((childDepth2) => {
						let depth3Children = childDepth2.children.map((childDepth3) => {
							if (childDepth3.id === categoryItem.id) {
								changeCategoryFilter(
									childDepth3,
									placeCategoryDepth2Filter,
									setPlaceCategoryDepth2Filter
								);
								return {
									...childDepth3,
									selected: childDepth3.selected ? false : true,
								};
							}
							return childDepth3;
						});

						// 모두 선택 되어있거나, 선택 해제 되어있다면 부모의 선택 상태를 바꿔준다.
						let childDepth2Selected = childDepth2.selected;
						let indeterminate = false;
						let depth3ChildrenSelectedCount = depth3Children.filter((child) => child.selected).length;
						if (depth3ChildrenSelectedCount === 0) {
							childDepth2Selected = false;
						} else if (depth3ChildrenSelectedCount === depth3Children.length) {
							childDepth2Selected = true;
						} else {
							childDepth2Selected = true;
							indeterminate = true;
						}

						return {
							...childDepth2,
							selected: childDepth2Selected,
							indeterminate,
							children: depth3Children,
						};
					}),
				};
			})
		);
	};

	const onClickTypeCategoryItem = (categoryItem) => {
		setTypeCategoryItemList(
			typeCategoryItemList.map((typeCategoryItem) => {
				if (typeCategoryItem.id === typeCategoryItemId) {
					return {
						...typeCategoryItem,
						children: typeCategoryItem.children.map((typeCategoryItemChild) => {
							if (typeCategoryItemChild.id === categoryItem.id) {
								changeCategoryFilter(typeCategoryItemChild, typeCategoryFilter, setTypeCategoryFilter);
								return {
									...typeCategoryItemChild,
									selected: typeCategoryItemChild.selected ? false : true,
								};
							}
							return typeCategoryItemChild;
						}),
					};
				}
				return typeCategoryItem;
			})
		);
	};

	const onClickResetTimeFilter = () => {
		setStartTimeFilter(null);
		setEndTimeFilter(null);
	};

	const onClickUnit = (id) => {
		navigate(`/booking/schedule/${id}`, {
			state: {
				unit_id: id,
				filter: {
					usage_date: usageDateFilter,
				},
			},
		});
	};

	const renderPlaceCategoryItem = (categoryItem, idx) => {
		return (
			<PlaceCategoryItemContainer key={idx}>
				<PlaceCategoryItem
					title={categoryItem.name}
					selected={categoryItem.selected}
					onClick={(e) => onClickPlaceCategoryItem(categoryItem)}
				>
					<CategoryItemName selected={categoryItem.selected}>{categoryItem.name}</CategoryItemName>
					{categoryItem.selected && <CategoryItemSelected src={CategoryItemSelectedImage} />}
				</PlaceCategoryItem>
				{categoryItem.selected && (
					<CategoryChildrenTree>
						{categoryItem.children.map((categoryChildDepth2, idx) => {
							return (
								<CategoryChild key={idx}>
									<CategoryChildDepth2
										selected={categoryChildDepth2.selected}
										title={categoryChildDepth2.name}
									>
										{categoryChildDepth2.indeterminate ? (
											<StyledIndeterminateCheckBoxOutlinedIcon
												onClick={(e) => {
													onCheckPlaceCategoryItemDepth2(categoryChildDepth2);
												}}
											/>
										) : (
											<FilterCheckbox
												type="checkBox"
												name={categoryChildDepth2.id}
												id={categoryChildDepth2.id}
												checked={categoryChildDepth2.selected}
												onChange={(e) => onCheckPlaceCategoryItemDepth2(categoryChildDepth2)}
											/>
										)}
										<CategoryChildDepth2Label
											htmlFor={categoryChildDepth2.id}
											selected={categoryChildDepth2.selected}
										>
											{categoryChildDepth2.name}
										</CategoryChildDepth2Label>
									</CategoryChildDepth2>
									{categoryChildDepth2.children.map((categoryChildDepth3, _) => {
										return (
											<CategoryChildDepth3 key={_} title={categoryChildDepth3.name}>
												<FilterCheckbox
													type="checkBox"
													name={categoryChildDepth3.id}
													id={categoryChildDepth3.id}
													checked={categoryChildDepth3.selected}
													onChange={(e) =>
														onCheckPlaceCategoryItemDepth3(categoryChildDepth3)
													}
												/>
												<CategoryChildDepth3Label
													htmlFor={categoryChildDepth3.id}
													selected={categoryChildDepth3.selected}
												>
													{categoryChildDepth3.name}
												</CategoryChildDepth3Label>
											</CategoryChildDepth3>
										);
									})}
								</CategoryChild>
							);
						})}
					</CategoryChildrenTree>
				)}
			</PlaceCategoryItemContainer>
		);
	};

	const renderTypeCategoryItem = (categoryItem, idx) => {
		return (
			<CategoryItem
				title={categoryItem.name}
				key={idx}
				selected={categoryItem.selected}
				onClick={(e) => onClickTypeCategoryItem(categoryItem)}
			>
				<CategoryItemName selected={categoryItem.selected}>{categoryItem.name}</CategoryItemName>
				{categoryItem.selected && <CategoryItemSelected src={CategoryItemSelectedImage} />}
			</CategoryItem>
		);
	};

	if (!initialized) return null;

	return (
		<Content>
			<section>
				<Tab
					tabs={typeCategoryItemList.map((val) => `${val.name} 예약`)}
					tabIndex={tabIdx}
					onChange={(idx) => changeTab(idx)}
				/>
			</section>
			<section>
				<Row>
					<FilterColumn>
						<section>
							<FilterTitleContainer>
								<FilterPlaceIcon src={FilterPlaceIconImage} />
								<FilterTitle>위치</FilterTitle>
							</FilterTitleContainer>
							{placeCategoryItemList?.map((placeCategoryItem, idx) =>
								renderPlaceCategoryItem(placeCategoryItem, idx)
							)}
						</section>
						<section className="mt-29px">
							<FilterTitleContainer>
								<FilterTypeIcon src={FilterTypeIconImage} />
								<FilterTitle>시설유형</FilterTitle>
							</FilterTitleContainer>
							{typeCategoryItemList
								?.find((typeCategory) => typeCategory.id === typeCategoryItemId)
								.children.map((typeCategoryItem, idx) => renderTypeCategoryItem(typeCategoryItem, idx))}
						</section>
						<section className="mt-29px">
							<FilterTitleContainer>
								<FilterDateIcon src={FilterDateIconImage} />
								<FilterTitle>이용일자</FilterTitle>
							</FilterTitleContainer>
							<SearchDateInput
								type="date"
								value={usageDateFilter ?? ''}
								min={utils.convertDateToStr(new Date())}
								onChange={(e) => setUsageDateFilter(e.currentTarget.value)}
							/>
						</section>
						<section className="mt-29px">
							<FilterTitleContainer>
								<FilterTimeIcon src={FilterTimeIconImage} />
								<FilterTitle>시간대 검색</FilterTitle>
								<TimeFilterResetButton onClick={onClickResetTimeFilter}>초기화</TimeFilterResetButton>
							</FilterTitleContainer>
							<FilterSubTitle>시작시간</FilterSubTitle>
							<TimeSelect
								time={startTimeFilter}
								onChange={(value) => {
									if (value > endTimeFilter) {
										alert('시작시간은 종료시간보다 이후일 수 없습니다.');
										return false;
									}
									setStartTimeFilter(value);
									return true;
								}}
							></TimeSelect>
							<FilterSubTitle className="mt-10px">종료시간</FilterSubTitle>
							<TimeSelect
								time={endTimeFilter}
								onChange={(value) => {
									if (value < startTimeFilter) {
										alert('종료시간은 시작시간보다 이전일 수 없습니다.');
										return false;
									}
									setEndTimeFilter(value);
									return true;
								}}
							></TimeSelect>
						</section>
					</FilterColumn>
					<UnitResultColumn>
						<ResultTitle>검색결과</ResultTitle>
						<ResultContainer>
							{unitList?.items.map((unit, _) => {
								return (
									<Unit key={_} onClick={(e) => onClickUnit(unit.id)}>
										{unit.thumbnail ? (
											<UnitThumbnail
												src={`${process.env.REACT_APP_API_URL}/storage/files/${unit.thumbnail?.filename}`}
											/>
										) : (
											<EmptyUnitThumbnail />
										)}
										<UnitInfoContainer>
											<UnitPlaceCategoryName>
												{utils.getUnitCategoryName(unit, CategorySlug.PLACE, 1)}
											</UnitPlaceCategoryName>
											<UnitPlaceCategoryName>
												{`${utils.getUnitCategoryName(
													unit,
													CategorySlug.PLACE,
													2
												)} ${utils.getUnitCategoryName(unit, CategorySlug.PLACE, 3)}`}
											</UnitPlaceCategoryName>
											<UnitName>{unit.name}</UnitName>
										</UnitInfoContainer>
									</Unit>
								);
							})}
						</ResultContainer>
					</UnitResultColumn>
				</Row>
			</section>
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	margin-top: 40px;

	@media only screen and (max-width: 767.98px) {
		padding: 20px 15px 30px 15px;
		margin-top: 0;
	}
`;

const Row = styled.div`
	display: flex;
	margin-top: 20px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 15px;
		flex-direction: column;
	}
`;

const FilterColumn = styled.div`
	width: 260px;
	background-color: #ffffff;
	border-radius: 4px;
	padding: 40px 30px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding: 25px 15px;
	}
`;

const FilterTitleContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 15px;
`;

const FilterTitle = styled.span`
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 16px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: -0.48px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		font-size: 18px;
	}
`;

const TimeFilterResetButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 50px;
	height: 20px;
	margin-left: 10px;

	border-radius: 8px;
	border: none;
	background-color: #11a9b1;
	cursor: pointer;

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 12px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.39px;
	line-height: 100%;
`;

const FilterSubTitle = styled.div`
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.48px;
	line-height: 100%;
`;

const FilterPlaceIcon = styled.img`
	width: 13px;
	height: 19px;
	margin-right: 10px;
`;

const FilterTypeIcon = styled.img`
	width: 16px;
	height: 19px;
	margin-right: 10px;
`;

const FilterDateIcon = styled.img`
	width: 15px;
	height: 18px;
	margin-right: 10px;
`;

const FilterTimeIcon = styled.img`
	width: 18px;
	height: 17px;
	margin-right: 10px;
`;

const CategoryItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 200px;
	height: 35px;
	padding: 0 17px;
	border: 1px solid #666666;
	border-radius: 4px;
	cursor: pointer;

	& + & {
		margin-top: 5px;
	}

	${(props) =>
		props.selected &&
		css`
			border: 1px solid #2956a6;
		`}

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: 40px;
	}
`;

const PlaceCategoryItemContainer = styled.div`
	& + & {
		margin-top: 5px;
	}
`;

const PlaceCategoryItem = styled(CategoryItem)`
	${(props) =>
		props.selected &&
		css`
			border: 1px solid #2956a6;
			border-radius: 4px 4px 0 0;
		`}
`;

const CategoryItemName = styled.span`
	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.75px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	break-word: break-all;

	${(props) =>
		props.selected &&
		css`
			color: #2956a6;
		`}
`;

const CategoryItemSelected = styled.img`
	width: 13px;
	height: 11px;
`;

const CategoryChildrenTree = styled.div`
	padding: 12px 10px;
	border: 1px solid #666666;
	border-top: none;
	border-radius: 0 0 4px 4px;
`;

const CategoryChild = styled.div`
	& + & {
		margin-top: 5px;
	}
`;

const FilterCheckbox = styled.input`
	width: 15px;
	height: 15px;
	border: 1px solid #c5c5c5;
	margin: 0 11px 0 17px;
	cursor: pointer;
`;

const StyledIndeterminateCheckBoxOutlinedIcon = styled(IndeterminateCheckBoxOutlinedIcon)`
	font-size: 20px !important;
	margin: 0 9px 0 14px;
	color: #ffffff;
	cursor: pointer;
`;

const CategoryChildDepth2 = styled.div`
	display: flex;
	align-items: center;
	width: 180px;
	height: 35px;
	border-radius: 4px;
	background-color: #f5f5f5;

	${(props) =>
		props.selected &&
		css`
			background-color: #acc5e3;
		`}

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: 40px;
	}
`;

const CategoryChildDepth2Label = styled.label`
	width: 150px;
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	cursor: pointer;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	break-word: break-all;

	${(props) =>
		props.selected &&
		css`
			color: #ffffff;
		`}

	@media only screen and (max-width: 767.98px) {
		font-size: 16px;
	}
`;

const CategoryChildDepth3 = styled.div`
	display: flex;
	align-items: center;
	width: 180px;
	height: 25px;
	padding-left: 20px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: 30px;
	}
`;

const CategoryChildDepth3Label = styled.label`
	width: 150px;
	color: #666666;
	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.42px;
	cursor: pointer;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	break-word: break-all;

	${(props) =>
		props.selected &&
		css`
			color: #88a9d0;
		`}

	@media only screen and (max-width: 767.98px) {
		font-size: 15px;
	}
`;

const SearchDateInput = styled.input`
	width: 200px;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;
	border-radius: 4px;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const UnitResultColumn = styled.div`
	width: 960px;
	margin-left: 20px;
	background-color: #ffffff;
	border-radius: 4px;
	padding: 39px 26px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		margin-top: 20px;
		margin-left: 0;
		padding: 25px 10px;
	}
`;

const ResultTitle = styled.div`
	margin-left: 13px;
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 22px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: -0.66px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		margin-left: 5px;
		font-size: 20px;
	}
`;

const ResultContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 23px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 15px;
	}
`;

const Unit = styled.div`
	width: 200px;
	height: 270px;
	border: 1px solid #0e498f;
	border-radius: 8px;
	cursor: pointer;
	margin: 13px;

	@media only screen and (max-width: 767.98px) {
		width: 145px;
		height: 200px;
		margin: 5px;
	}
`;

const UnitThumbnail = styled.img`
	width: 100%;
	height: 160px;
	object-fit: cover;
	border-radius: 8px 8px 0 0;

	@media only screen and (max-width: 767.98px) {
		height: 115px;
	}
`;

const EmptyUnitThumbnail = styled.div`
	width: 100%;
	height: 160px;
	background-color: #dddddd;
	border-radius: 8px 8px 0 0;

	@media only screen and (max-width: 767.98px) {
		height: 115px;
	}
`;

const UnitInfoContainer = styled.div`
	width: 100%;
	padding: 23px 25px;

	@media only screen and (max-width: 767.98px) {
		padding: 13px 21px;
	}
`;

const UnitPlaceCategoryName = styled.div`
	color: #666666;
	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.42px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	break-word: break-all;

	& + & {
		margin-top: 0px;
	}

	@media only screen and (max-width: 767.98px) {
		font-size: 13px;
	}
`;

const UnitName = styled.div`
	margin-top: 4px;
	color: #0e498f;
	font-family: 'Noto Sans';
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.48px;
	line-height: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	break-word: break-all;

	@media only screen and (max-width: 767.98px) {
		margin-top: 6px;
		font-size: 15px;
	}
`;

export default Booking;
