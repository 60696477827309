import React, { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import useSizeDetector from '../hooks/useSizeDetector';

import { UserContext } from '../contexts/UserContext';

import AdminSectionTitle from '../components/admin/AdminSectionTitle';
import TablePagination from '../components/TablePagination';
import UserRole from '../constants/UserRole';

import LockIcon from '@mui/icons-material/Lock';

import * as api from '../apis';

const Board = () => {
	const { slug } = useParams();
	const navigate = useNavigate();
	const { user } = useContext(UserContext);
	const { Desktop, Mobile, isDesktop } = useSizeDetector();

	const [pageIndex, setPageIndex] = useState(1);
	const [currentBoard, setCurrentBoard] = useState(null);
	const [boardList, setBoardList] = useState(null);
	const [postList, setPostList] = useState(null);

	useEffect(() => {
		api.getBoards()
			.then((res) => {
				if (res && res.data) {
					setBoardList(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	useEffect(() => {
		if (!boardList) return;
		setCurrentBoard(boardList.find((board) => board.slug === slug || board.id == slug));
	}, [boardList, slug]);

	useEffect(() => {
		if (!boardList) return;
		getPostsFromApi();
	}, [currentBoard, pageIndex]);

	const getPostsFromApi = () => {
		let filterParams = [`page=${pageIndex}`];
		api.getPosts(currentBoard.id, filterParams).then((res) => {
			if (res && res.data) {
				setPostList(res.data);
			}
		});
	};

	const hasWritePermission = () => {
		if (!user) {
			return false;
		}

		if (user && user.user_role === UserRole.SYSTEM_ADMINISTRATOR) {
			return true;
		}

		if (currentBoard && !currentBoard.readonly) {
			return true;
		}

		return false;
	};

	const hasReadPermission = (post) => {
		if (!post.secret) {
			return true;
		}

		if (!user) {
			return false;
		}

		if (user && user.user_role === UserRole.SYSTEM_ADMINISTRATOR) {
			return true;
		}

		if (user && user.id === post.user.id) {
			return true;
		}

		return false;
	};

	const onClickChangeBoard = (slug) => {
		navigate(`/boards/${slug}`);
	};

	const onClickNewPost = () => {
		navigate(`/boards/${currentBoard.id}/posts/create`);
	};

	const onClickPost = (post) => {
		if (!hasReadPermission(post)) {
			alert('게시글에 대한 접근 권한이 없습니다.');
			return;
		}
		navigate(`/boards/${currentBoard.id}/posts/${post.id}`);
	};

	const onPageChange = (page) => {
		setPageIndex(page);
	};

	return (
		<Content>
			<Row>
				<Desktop>
					<BoardListColumn>
						<Title>게시판</Title>
						<MenuContainer>
							<Menu>
								{boardList?.map((board, _) => {
									return (
										<MainMenu
											key={_}
											selected={board.slug === slug || board.id == slug}
											onClick={(e) => onClickChangeBoard(board.slug)}
										>
											{board.name}
										</MainMenu>
									);
								})}
							</Menu>
						</MenuContainer>
					</BoardListColumn>
				</Desktop>
				<PostListColumn>
					<Mobile>
						<TopContainer>
							<TopTitle>게시판</TopTitle>
							<TopTabContainer>
								{boardList?.map((board, _) => {
									return (
										<TopTab
											key={_}
											selected={board.slug === slug || board.id == slug}
											onClick={(e) => onClickChangeBoard(board.slug)}
										>
											{board.name}
										</TopTab>
									);
								})}
							</TopTabContainer>
						</TopContainer>
					</Mobile>
					<BoardInfo>
						<AdminSectionTitle title={currentBoard?.name ?? '-'} />
						{hasWritePermission() && <PostAddButton onClick={onClickNewPost}>게시글 작성</PostAddButton>}
					</BoardInfo>
					<PostListTable>
						<thead>
							<tr>
								<th style={{ minWidth: isDesktop ? '80px' : '35px' }}>번호</th>
								<th style={{ minWidth: isDesktop ? '120px' : '45px' }}>분류</th>
								<th style={{ minWidth: isDesktop ? '500px' : '110px' }}>제목</th>
								<th style={{ minWidth: isDesktop ? '120px' : '45px' }}>작성자</th>
								<th style={{ minWidth: isDesktop ? '100px' : '65px' }}>작성일</th>
							</tr>
						</thead>
						<tbody>
							{postList?.items.map((post, idx) => {
								return (
									<tr key={idx}>
										<td>
											{postList?.pagination.total -
												idx -
												(pageIndex - 1) * postList?.pagination.per_page}
										</td>
										<td>{post.category?.name ?? '미분류'}</td>
										<PostTitle className="text-left" onClick={(e) => onClickPost(post)}>
											{`${post.title} [${post.comments.length}]`}
											{post.secret && <StyledLockIcon />}
										</PostTitle>
										<td>{post.user.name}</td>
										<td>
											{post.created_at ? new Date(post.created_at).toLocaleDateString() : '-'}
										</td>
									</tr>
								);
							})}
						</tbody>
					</PostListTable>
					<div className="mt-24px">
						<TablePagination
							currentPage={postList?.pagination.current_page}
							lastPage={postList?.pagination.last_page}
							onPageChange={onPageChange}
						/>
					</div>
				</PostListColumn>
			</Row>
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	margin-top: 50px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 20px;
		padding: 0 15px;
	}
`;

const Row = styled.div`
	display: flex;
`;

const BoardListColumn = styled.div`
	width: 190px;
	background-color: #ffffff;
	border-radius: 4px;
	padding: 40px 30px;
`;

const Title = styled.div`
	padding-bottom: 20px;
	border-bottom: 1px solid #dddddd;

	color: #333333;
	font-family: 'Noto Sans';
	font-size: 20px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: -0.6px;
	line-height: 100%;
`;

const MenuContainer = styled.div`
	margin-top: 24px;
`;

const Menu = styled.ul`
	margin-block-start: 0;
	margin-block-end: 0;
	padding-inline-start: 0;
	& + & {
		margin-top: 23px;
	}
`;

const MainMenu = styled.li`
	list-style: none;
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
	cursor: pointer;

	${(props) =>
		props.selected &&
		css`
			color: #0e498f;
		`}

	& + & {
		margin-top: 16px;
	}
`;

const PostListColumn = styled.div`
	width: 1030px;
	margin-left: 20px;
	background-color: #ffffff;
	border-radius: 4px;
	padding: 50px 40px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		margin-left: 0;
		padding: 40px 15px;
	}
`;

const TopContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const TopTitle = styled.div`
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 20px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: -0.6px;
	line-height: 100%;
`;

const TopTabContainer = styled.div`
	display: flex;
	margin-top: 26px;
	margin-bottom: 29px;
`;

const TopTab = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 150px;
	height: 40px;
	background-color: #d5d5d5;

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;

	${(props) =>
		props.selected &&
		css`
			background-color: #0e498f;
		`}
`;

const BoardInfo = styled.div`
	display: flex;
	justify-content: space-between;
`;

const PostAddButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 90px;
	height: 30px;

	border-radius: 8px;
	border: none;
	background-color: #11a9b1;
	cursor: pointer;

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.39px;
	line-height: 100%;
`;

const PostListTable = styled.table`
	width: 100%;

	border-collapse: collapse;
	border-top: 2px solid #0e498f;

	text-align: center;
	margin-top: 20px;
	thead th {
		height: 45px;
		color: #0e498f;
		font-family: 'Noto Sans';
		font-size: 15px;
		font-weight: 500;
		font-style: normal;
		letter-spacing: -0.45px;
		line-height: 100%;
	}
	thead th.text-left {
		text-align: left;
	}
	thead tr {
		border-bottom: 1px solid #dcdcdc;
	}

	tbody td {
		height: 54px;
		color: #666666;
		font-family: 'Noto Sans';
		font-size: 15px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: -0.45px;
		line-height: normal;
	}
	tbody td.text-left {
		text-align: left;
	}
	tbody tr {
		border-bottom: 1px solid #dddddd;
	}

	tfoot {
		height: 45px;
		background: #f7f7f7;
		border-bottom: 1px solid #dddddd;
	}

	@media only screen and (max-width: 767.98px) {
		thead th {
			font-size: 14px;
		}

		tbody td {
			font-size: 14px;
			word-break: break-all;
		}
	}
`;

const PostTitle = styled.td`
	cursor: pointer;
`;

const StyledLockIcon = styled(LockIcon)`
	font-size: 18px !important;
	margin-left: 8px;
	margin-bottom: 1px;
`;

export default Board;
