import React, { useState } from 'react';
import styled from 'styled-components';
import useFilterHandler from '../../hooks/useFilterHandler';

import CategorySlug from '../../constants/CategorySlug';
import ApprovalStatus from '../../constants/ApprovalStatus';
import AdminSectionTitle from '../../components/admin/AdminSectionTitle';

import * as api from '../../apis';
import * as utils from '../../utils';

const BookingItemModal = ({ isAdmin, booking, onClose }) => {
	const [approvalStatus, setApprovalStatus] = useState(booking?.approval_status);
	const [requestTitle, setRequestTitle] = useState(booking?.operator_request?.title);
	const [requestDescription, setRequestDescription] = useState(booking?.operator_request?.description);
	const [answerDescription, setAnswerDescription] = useState(booking?.operator_request?.answer);
	const { categoryFilter } = useFilterHandler({
		onFetchCompleted: () => {
			setInitialized(true);
		},
	});
	const [initialized, setInitialized] = useState(false);

	const onClickConfirm = async () => {
		try {
			if (booking?.approval_status !== approvalStatus) {
				const params = {
					booking_ids: [booking?.id],
					approval_status: approvalStatus,
				};

				await api.updateApprovalStatus(params);
			}

			if (requestTitle && requestDescription) {
				const params = {
					title: requestTitle,
					description: requestDescription,
				};

				await api.createBookingOperatorRequest(booking.id, params);
			}

			onClose(true);
		} catch (err) {
			console.error(err);
		}
	};

	const renderAdminSection = () => {
		return (
			<section className="mt-40px">
				<AdminSectionTitle title="관리 내역" />
				<AdminSectionContainer className="mt-24px">
					<InfoRow>
						<InfoColumn className="w-100">
							<InfoTitle>예약 상태</InfoTitle>
							<InfoDescription>
								<StatusChangeSelect
									value={approvalStatus}
									onChange={(e) => setApprovalStatus(e.currentTarget.value)}
								>
									<option value={ApprovalStatus.APPROVED}>
										{ApprovalStatus.toString(ApprovalStatus.APPROVED)}
									</option>
									<option value={ApprovalStatus.REJECTED}>
										{ApprovalStatus.toString(ApprovalStatus.REJECTED)}
									</option>
									<option value={ApprovalStatus.CANCELED}>
										{ApprovalStatus.toString(ApprovalStatus.CANCELED)}
									</option>
									<option value={ApprovalStatus.PENDING}>
										{ApprovalStatus.toString(ApprovalStatus.PENDING)}
									</option>
								</StatusChangeSelect>
							</InfoDescription>
						</InfoColumn>
					</InfoRow>
					<InfoRow>
						<InfoColumn className="w-100">
							<InfoTitle>관리자 요청사항</InfoTitle>
							<RequestContainer>
								<RequestTitleInput
									value={requestTitle ?? ''}
									placeholder="요청사항 제목을 작성해주세요"
									onChange={(e) => setRequestTitle(e.currentTarget.value)}
								/>
								<RequestDescriptionInput
									value={requestDescription}
									placeholder="요청사항 내용을 작성해주세요"
									onChange={(e) => setRequestDescription(e.currentTarget.value)}
								/>
							</RequestContainer>
						</InfoColumn>
					</InfoRow>
					<InfoRow>
						<InfoColumn className="w-100">
							<InfoTitle>예약자 답변</InfoTitle>
							<AnswerContainer>
								<AnswerText>{answerDescription ?? '답변이 존재하지 않습니다.'}</AnswerText>
							</AnswerContainer>
						</InfoColumn>
					</InfoRow>
				</AdminSectionContainer>
			</section>
		);
	};

	if (!initialized) return null;

	return (
		<ModalContainer>
			<Content>
				<section>
					<ModalTopContainer>
						<AdminSectionTitle title="신청 내역 확인" />
						<CloseButton onClick={() => onClose(false)}>x</CloseButton>
					</ModalTopContainer>
				</section>
				<section>
					<InfoContainer>
						<InfoRow>
							<InfoColumn>
								<InfoTitle>예약번호</InfoTitle>
								<InfoDescription>{`#${booking.id ?? '0'}`}</InfoDescription>
							</InfoColumn>
							<InfoColumn>
								<InfoTitle>신청일</InfoTitle>
								<InfoDescription>
									{booking.created_at ? new Date(booking.created_at).toLocaleString() : '-'}
								</InfoDescription>
							</InfoColumn>
						</InfoRow>
						<InfoRow>
							<InfoColumn>
								<InfoTitle>예약자</InfoTitle>
								<InfoDescription>{booking?.user?.name ?? '-'}</InfoDescription>
							</InfoColumn>
							<InfoColumn>
								<InfoTitle>학번/교직원 번호</InfoTitle>
								<InfoDescription>{booking?.user?.user_login ?? '-'}</InfoDescription>
							</InfoColumn>
						</InfoRow>
						<InfoRow>
							<InfoColumn>
								<InfoTitle>이메일</InfoTitle>
								<InfoDescription>{booking?.user?.email ?? '-'}</InfoDescription>
							</InfoColumn>
							<InfoColumn>
								<InfoTitle>연락처</InfoTitle>
								<InfoDescription>{booking?.user?.phone ?? '-'}</InfoDescription>
							</InfoColumn>
						</InfoRow>
						<InfoRow>
							<InfoColumn>
								<InfoTitle>
									{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.PLACE, 1)}
								</InfoTitle>
								<InfoDescription>
									{utils.getUnitCategoryName(booking?.unit, CategorySlug.PLACE, 1)}
								</InfoDescription>
							</InfoColumn>
							<InfoColumn>
								<InfoTitle>
									{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.PLACE, 2)}
								</InfoTitle>
								<InfoDescription>
									{utils.getUnitCategoryName(booking?.unit, CategorySlug.PLACE, 2)}
								</InfoDescription>
							</InfoColumn>
						</InfoRow>
						<InfoRow>
							<InfoColumn>
								<InfoTitle>
									{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.PLACE, 3)}
								</InfoTitle>
								<InfoDescription>
									{utils.getUnitCategoryName(booking?.unit, CategorySlug.PLACE, 3)}
								</InfoDescription>
							</InfoColumn>
							<InfoColumn>
								<InfoTitle>
									{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.TYPE, 2)}
								</InfoTitle>
								<InfoDescription>
									{utils.getUnitCategoryName(booking?.unit, CategorySlug.TYPE, 2)}
								</InfoDescription>
							</InfoColumn>
						</InfoRow>
						<InfoRow>
							<InfoColumn>
								<InfoTitle>시설명</InfoTitle>
								<InfoDescription>{booking?.unit?.name ?? '-'}</InfoDescription>
							</InfoColumn>
							<InfoColumn>
								<InfoTitle>호실</InfoTitle>
								<InfoDescription>{booking?.room_item?.name ?? '-'}</InfoDescription>
							</InfoColumn>
						</InfoRow>
						<InfoRow>
							<InfoColumn className="w-100">
								<InfoTitle>이용기간</InfoTitle>
								<InfoDescription>{booking?.booking_date ?? '-'}</InfoDescription>
							</InfoColumn>
						</InfoRow>
						<InfoRow>
							<InfoColumn className="w-100">
								<InfoTitle>사용 인원</InfoTitle>
								<InfoDescription>
									<InfoDescriptionText>{`${booking?.usage_count ?? '-'}명`}</InfoDescriptionText>
								</InfoDescription>
							</InfoColumn>
						</InfoRow>
						<InfoRow>
							<InfoColumn className="w-100">
								<InfoTitle>촬영내용</InfoTitle>
								<InfoDescription className="w-100">
									<InfoDescriptionText>{booking?.purpose_description ?? '-'}</InfoDescriptionText>
								</InfoDescription>
							</InfoColumn>
						</InfoRow>
						<InfoRow>
							<InfoColumn className="w-100">
								<InfoTitle>특이사항</InfoTitle>
								<InfoDescription className="w-100">
									<InfoDescriptionText>{booking?.extra_description ?? '-'}</InfoDescriptionText>
								</InfoDescription>
							</InfoColumn>
						</InfoRow>
					</InfoContainer>
					<InfoRow>
						<InfoColumn className="w-100">
							<InfoTitle>교안 파일</InfoTitle>
							{booking?.attachments?.length === 0 && <InfoDescription>-</InfoDescription>}
							<AttachmentContainer>
								{booking?.attachments?.map((file, idx) => {
									return (
										<InfoDescription key={idx}>
											<InfoDescriptionText>
												<a
													href={`${process.env.REACT_APP_API_URL}/storage/files/${file.filename}`}
													download={file.org_filename ?? '-'}
												>
													{file.org_filename ?? '-'}
												</a>
											</InfoDescriptionText>
										</InfoDescription>
									);
								})}
							</AttachmentContainer>
						</InfoColumn>
					</InfoRow>
				</section>
				{isAdmin && renderAdminSection()}
				<Footer>
					<CancelButton onClick={() => onClose(false)}>취소</CancelButton>
					<OkButton onClick={onClickConfirm}>확인</OkButton>
				</Footer>
			</Content>
		</ModalContainer>
	);
};

const ModalContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 99999;
	display: flex;
	justify-content: center;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		padding: 15px;
	}
`;

const Content = styled.div`
	position: relative;
	width: 720px;
	max-height: 80%;
	overflow-y: scroll;

	background-color: white;
	padding: 40px;

	background: #ffffff;
	box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.25);
	border-radius: 10px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding: 20px 15px;
	}
`;

const ModalTopContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const CloseButton = styled.button`
	display: flex;
	align-items: flex-end;
	justify-content: center;

	width: 30px;
	height: 30px;

	border-radius: 8px;
	border: none;
	background-color: #333333;

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 24px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -1.2px;
	line-height: 30px;
	padding-top: 0px;
`;

const InfoContainer = styled.div`
	border-top: 2px solid #333333;
	margin-top: 20px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 14px;
	}
`;

const InfoRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;

	min-height: 45px;
	border-bottom: 1px solid #dddddd;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		border-bottom: none;
	}
`;

const InfoColumn = styled.div`
	display: flex;
	width: 50%;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		min-height: 45px;
		border-bottom: 1px solid #dddddd;
	}
`;

const InfoTitle = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 120px;
	min-width: 120px;

	background-color: #f7f7f7;

	color: #333333;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		width: 100px;
		min-width: 100px;
		font-size: 14px;
	}
`;

const InfoDescription = styled.span`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	padding: 5px 15px;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
	word-break: break-all;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		font-size: 14px;
		align-items: center;
	}
`;

const InfoDescriptionText = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	width: 100%;
	height: 100%;
	padding: 8px;
	border: none;
	border-radius: 4px;
	background-color: #f7f7f7;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const AttachmentContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const Footer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
`;

const CancelButton = styled.button`
	width: 100px;
	height: 40px;
	border: 1px solid #0e498f;
	border-radius: 20px;
	background-color: #ffffff;

	color: #0e498f;
	font-family: 'Noto Sans';
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.48px;
	line-height: 100%;
`;

const OkButton = styled.button`
	width: 100px;
	height: 40px;

	margin-left: 20px;
	border: none;
	border-radius: 20px;
	background-color: #0e498f;
	background-image: linear-gradient(to right, #10abb1 0%, #275ba6 100%);

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.48px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		margin-left: 10px;
	}
`;

const AdminSectionContainer = styled.div`
	border-top: 2px solid #333333;
`;

const StatusChangeSelect = styled.select`
	width: 80px;
	height: 28px;
	padding-left: 8px;

	border-radius: 4px;
	border: 1px solid #dddddd;

	appearance: none;
	background-image: linear-gradient(45deg, transparent 50%, gray 50%),
		linear-gradient(135deg, gray 50%, transparent 50%);
	background-position: calc(100% - 15px) calc(1em - 2px), calc(100% - 10px) calc(1em - 2px), calc(100% - 2.5em) 0.5em;
	background-size: 5px 5px, 5px 5px, 1px 1.5em;
	background-repeat: no-repeat;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.39px;
	line-height: 100%;
`;

const RequestContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 175px;
	margin: 5px 15px;
	padding: 5px 0;
`;

const RequestTitleInput = styled.input`
	width: 100%;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const RequestDescriptionInput = styled.textarea`
	width: 100%;
	height: 135px;

	margin-top: 8px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const AnswerContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100px;
	background: #f7f7f7;
	border-radius: 4px;
	margin: 5px 15px;
	padding: 10px;
`;

const AnswerText = styled.span`
	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

export default BookingItemModal;
