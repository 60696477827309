import React, { useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import useSizeDetector from '../hooks/useSizeDetector';

import LogoImg from '../assets/images/common/logo.png';
import LogoMobileImg from '../assets/images/common/logo_mobile.png';
import MobileMenuImg from '../assets/images/components/header/mobile_menu.png';
import MobileCalendarImg from '../assets/images/components/header/mobile_calendar.png';
import MobileLogoutImg from '../assets/images/components/header/mobile_logout.png';

import UserRole from '../constants/UserRole';

import * as api from '../apis';

const Header = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { Desktop, Mobile, isDesktop } = useSizeDetector();

	const [showMobileMenu, setShowMobileMenu] = useState(false);

	const { user, logout } = useContext(UserContext);
	const menus = [
		{
			path: '/unit/rooms/introduce',
			link: '/unit/rooms/introduce',
			title: '시설 소개',
		},
		{
			path: '/booking',
			link: '/booking',
			title: '예약 신청',
		},
		{
			path: '/boards',
			link: '/boards/notice',
			title: '게시판',
		},
		{
			path: '/mypage',
			link: '/mypage',
			title: '마이페이지',
		},
	];

	const onClickLogo = () => {
		navigate('/');
	};

	const onClickLogin = () => {
		navigate('/login');
	};

	const onClickLogout = async () => {
		try {
			const res = await api.logout();
			if (res && res.status === 204) {
				logout();
				navigate('/');
			}
		} catch (err) {
			console.error(err);
		}
	};

	const onClickAdminPage = () => {
		navigate('/admin');
	};

	const onClickMobileMenu = () => {
		setShowMobileMenu(!showMobileMenu);
	};

	const renderLoginButton = () => {
		if (!user) {
			return <LoginButton onClick={onClickLogin}>로그인</LoginButton>;
		}

		if (user.user_role === UserRole.SYSTEM_ADMINISTRATOR) {
			return (
				<>
					<AdminLogoutButton onClick={onClickLogout}>로그아웃</AdminLogoutButton>
					<AdminPageButton onClick={onClickAdminPage}>운영자 페이지</AdminPageButton>
				</>
			);
		}
		return <LogoutButton onClick={onClickLogout}>로그아웃</LogoutButton>;
	};

	const renderMobileLoginButton = () => {
		if (!user) {
			return (
				<MenuItemLogin onClick={onClickLogin}>
					<MobileLoginImage src={MobileLogoutImg} />
					로그인
				</MenuItemLogin>
			);
		}

		return (
			<MenuItemLogin onClick={onClickLogout}>
				<MobileLoginImage src={MobileLogoutImg} />
				로그아웃
			</MenuItemLogin>
		);
	};

	return (
		<Container>
			<Desktop>
				<TopLine />
			</Desktop>
			<TopContainer>
				<Desktop>
					<LogoImage onClick={onClickLogo} src={LogoImg} />
					<LogoText onClick={onClickLogo}>스튜디오 예약시스템</LogoText>
					{renderLoginButton()}
				</Desktop>
				<Mobile>
					<MobileMenuImage src={MobileMenuImg} onClick={onClickMobileMenu} />
					<LogoImage onClick={onClickLogo} src={LogoMobileImg} />
					<LogoText onClick={onClickLogo}>스튜디오 예약시스템</LogoText>
					<MobileCalendarImage src={MobileCalendarImg} onClick={() => navigate('/booking')} />
				</Mobile>
			</TopContainer>
			{(isDesktop || showMobileMenu) && (
				<MenuContainer onClick={() => setShowMobileMenu(false)}>
					<MenuContent>
						<Mobile>{renderMobileLoginButton()}</Mobile>
						{menus.map((menu, idx) => {
							return (
								<MenuItemContainer key={idx}>
									<MenuItem
										selected={location.pathname === menu.path}
										onClick={() => navigate(menu.link)}
									>
										{menu.title}
										<Mobile>
											<MenuItemArrow>{'>'}</MenuItemArrow>
										</Mobile>
									</MenuItem>
									{menus.length - 1 !== idx && <MenuItemSeperator />}
								</MenuItemContainer>
							);
						})}
					</MenuContent>
				</MenuContainer>
			)}
		</Container>
	);
};

const Container = styled.div`
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100vw;
	height: 160px;
	z-index: 100;
	background-color: white;

	@media only screen and (max-width: 767.98px) {
		height: 60px;
	}
`;

const TopLine = styled.div`
	width: 100%;
	height: 5px;
	background-color: #22499d;
`;

const TopContainer = styled.div`
	position: relative;
	width: 100%;
	max-width: 1240px;
	height: 95px;

	display: flex;
	justify-content: center;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		height: 60px;
	}
`;

const LogoImage = styled.img`
	width: 117px;
	height: auto;
	cursor: pointer;

	@media only screen and (max-width: 767.98px) {
		width: 70px;
		height: auto;
	}
`;

const LogoText = styled.span`
	color: #000000;
	font-family: 'Noto Sans';
	font-size: 30px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: -0.9px;
	line-height: 100%;
	margin-left: 19px;
	cursor: pointer;

	@media only screen and (max-width: 767.98px) {
		font-size: 16px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: -0.94px;
		line-height: 100%;
		margin-left: 9px;
	}
`;

const MobileMenuImage = styled.img`
	position: absolute;
	left: 20px;
	top: 22px;
	width: 21px;
	height: 16px;
`;

const MobileCalendarImage = styled.img`
	position: absolute;
	right: 20px;
	top: 19px;
	width: 20px;
	height: 24px;
`;

const HeaderButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	height: 35px;

	cursor: pointer;
	border-radius: 17px;
	border: 1px solid #275da6;
	background-color: #ffffff;

	color: #333333;
	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 500;
`;

const LoginButton = styled(HeaderButton)`
	position: absolute;
	right: 0;
	width: 88px;
`;

const LogoutButton = styled(HeaderButton)`
	position: absolute;
	right: 0;
	width: 88px;
`;

const AdminLogoutButton = styled(HeaderButton)`
	position: absolute;
	right: 130px;
	width: 88px;
`;

const AdminPageButton = styled(HeaderButton)`
	position: absolute;
	right: 0;
	width: 120px;
`;

const MenuContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 100%;
	height: 60px;

	box-sizing: border-box;
	border-top: 1px solid #dddddd;
	border-bottom: 1px solid #dddddd;

	@media only screen and (max-width: 767.98px) {
		justify-content: flex-start;
		width: 100%;
		height: calc(100% - 60px);
		position: fixed;
		z-index: 101;
		top: 60px;
		background-color: rgba(0, 0, 0, 0.5);
	}
`;

const MenuContent = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	max-width: 1240px;
	display: flex;
	justify-content: center;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		justify-content: flex-start;
		width: 180px;
		height: 100%;
		background-color: #ffffff;
	}
`;

const MenuItemContainer = styled.div`
	display: flex;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
	}
`;

const MenuItem = styled.div`
	width: 240px;
	list-style: none;
	align-self: stretch;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	${(props) =>
		props.selected &&
		css`
			font-weight: bold;
		`}

	font-family: 'Noto Sans';
	color: #111111;
	font-size: 20px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: normal;
	line-height: 18px;
	text-align: left;
	letter-spacing: -0.6px;

	@media only screen and (max-width: 767.98px) {
		justify-content: space-between;
		padding-left: 19px;
		width: 100%;
		height: 55px;
		border-bottom: 1px solid #dddddd;
		color: #333333;
		font-family: 'Noto Sans';
		font-size: 14px;
		font-weight: 500;
		font-style: normal;
		letter-spacing: -0.42px;
		line-height: 100%;
	}
`;

const MenuItemArrow = styled.div`
	padding-right: 19px;
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.42px;
	line-height: 100%;
`;

const MenuItemLogin = styled.div`
	width: 100%;
	height: 55px;
	padding-left: 19px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;

	background-color: #22499d;
	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 12px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.36px;
	line-height: 100%;
`;

const MobileLoginImage = styled.img`
	width: 11px;
	height: 12px;
	margin-right: 9px;
`;

const MenuItemQuick = styled.div`
	position: absolute;
	right: 0;
	width: 190px;
	height: 50px;
	box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.2);
	background-color: #22499d;
	background-image: linear-gradient(to right, #10abb1 0%, #275ba6 100%);
	display: flex;
	justify-content: center;
	align-self: stretch;
	align-items: center;
	margin-left: 124px;
	cursor: pointer;

	color: #ffffff;
	font-size: 18px;
	font-weight: 500;
	line-height: 18px;
	letter-spacing: -0.54px;

	transform: perspective(50) rotateX(-5deg);

	border-bottom-right-radius: 20px 15px;
	border-bottom-left-radius: 20px 15px;
`;

const CalendarImage = styled.img`
	margin-right: 10px;
`;

const MenuItemSeperator = styled.div`
	width: 1px;
	height: 14px;
	margin-top: 3px;
	background-color: #dddddd;
`;

export default Header;
