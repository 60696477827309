import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

const AdminTab = ({ tabs, tabIndex, children }) => {
	const navigate = useNavigate();

	const onClickTab = (link) => {
		if (link) {
			navigate(link);
		}
	};
	return (
		<Container>
			<Content>
				{tabs?.map((tab, idx) => {
					const selected = idx === tabIndex;
					return (
						<Tab key={idx} onClick={() => onClickTab(tab.link)} selected={selected}>
							{tab.name ?? '-'}
						</Tab>
					);
				})}
			</Content>
			{children}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const Content = styled.div`
	display: flex;
	width: 100%;
	max-width: 1240px;
	height: 50px;
`;

const Tab = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 300px;
	height: 50px;
	cursor: pointer;

	color: white;
	background-color: #d5d5d5;
	border-top: 2px solid #d5d5d5;
	font-family: 'Noto Sans';
	font-size: 16px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: -0.48px;
	line-height: 100%;

	${(props) =>
		props.selected &&
		css`
			color: #275ba6;
			background-color: white;
			border-top: 2px solid #22499d;
		`}
`;

export default AdminTab;
