import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import AdminSectionTitle from '../../components/admin/AdminSectionTitle';

const CategoryItemModal = ({ defaultName, onConfirm, onClose }) => {
	const [name, setName] = useState('');

	useEffect(() => {
		if (defaultName) {
			setName(defaultName);
		}
	}, [defaultName]);

	return (
		<ModalContainer>
			<Content>
				<section>
					<ModalTopContainer>
						<AdminSectionTitle title="분류 추가" />
						<CloseButton onClick={onClose}>x</CloseButton>
					</ModalTopContainer>
				</section>
				<section className="mt-24px">
					<TextInput type="text" value={name ?? ''} onChange={(e) => setName(e.currentTarget.value)} />
				</section>
				<Footer>
					<CancelButton onClick={onClose}>취소</CancelButton>
					<OkButton onClick={() => onConfirm(name)}>확인</OkButton>
				</Footer>
			</Content>
		</ModalContainer>
	);
};

const ModalContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 99999;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Content = styled.div`
	position: relative;
	width: 720px;
	max-height: 80%;
	overflow-y: scroll;

	background-color: white;
	padding: 40px;

	background: #ffffff;
	box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
`;

const ModalTopContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const CloseButton = styled.button`
	display: flex;
	align-items: flex-end;
	justify-content: center;

	width: 30px;
	height: 30px;

	border-radius: 8px;
	border: none;
	background-color: #333333;

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 24px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -1.2px;
	line-height: 30px;
	padding-top: 0px;
`;

const TextInput = styled.input`
	width: 100%;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const Footer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
`;

const CancelButton = styled.button`
	width: 100px;
	height: 40px;
	border: 1px solid #0e498f;
	border-radius: 20px;
	background-color: #ffffff;

	color: #0e498f;
	font-family: 'Noto Sans';
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.48px;
	line-height: 100%;
`;

const OkButton = styled.button`
	width: 100px;
	height: 40px;

	margin-left: 20px;
	border: none;
	border-radius: 20px;
	background-color: #0e498f;
	background-image: linear-gradient(to right, #10abb1 0%, #275ba6 100%);

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.48px;
	line-height: 100%;
`;

export default CategoryItemModal;
