import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';

import AdminSectionTitle from '../../components/admin/AdminSectionTitle';
import DashboardSummaryCard from '../../components/admin/DashboardSummaryCard';
import DashboardCalendar from '../../components/admin/DashboardCalendar';

import ApprovalStatus from '../../constants/ApprovalStatus';

import * as api from '../../apis';
import * as utils from '../../utils';

const Dashboard = () => {
	const navigate = useNavigate();
	const [data, setData] = useState(null);
	const [bookingTotalCount, setBookingTotalCount] = useState(0);
	const graphColorList = ['#acc4e5', '#aaacaf', '#afdde0', '#c4c5e8', '#e18a9f', '#899cba'];

	useEffect(() => {
		api.getDashboard().then((res) => {
			if (res && res.data) {
				const statistics = res.data?.room_type?.statistics;
				let totalCount = 0;
				if (statistics) {
					Object.keys(statistics).forEach((key) => {
						totalCount += statistics[key];
					});
					setBookingTotalCount(totalCount);
				}
				setData(res.data);
			}
		});
	}, []);

	const getTodayTotal = () => {
		return (
			(data?.today?.pending ?? 0) +
			(data?.today?.approved ?? 0) +
			(data?.today?.rejected ?? 0) +
			(data?.today?.canceled ?? 0)
		);
	};

	const onClickCard = (status, today) => {
		navigate(`/admin/booking/rooms`, {
			state: {
				filter: {
					approval_status: status,
					usage_date: today ? utils.convertDateToStr(new Date()) : null,
				},
			},
		});
	};

	return (
		<Content>
			<section>
				<AdminSectionTitle title="오늘의 현황" />
				<Row className="mt-24px">
					<DashboardSummaryCard
						title="승인대기(누적)"
						background="#19B4B5"
						color="#ffffff"
						count={data?.total?.pending ?? 0}
						large
						onClick={(e) => onClickCard(ApprovalStatus.PENDING, false)}
					/>
					<DashboardSummaryCard
						title="신청"
						background="#BCD1EE"
						color="#333333"
						count={getTodayTotal()}
						onClick={(e) => onClickCard(null, true)}
					/>
					<DashboardSummaryCard
						title="승인대기"
						background="#BFC3E8"
						color="#333333"
						count={data?.today?.pending ?? 0}
						onClick={(e) => onClickCard(ApprovalStatus.PENDING, true)}
					/>
					<DashboardSummaryCard
						title="승인완료"
						background="#ACD7EB"
						color="#333333"
						count={data?.today?.approved ?? 0}
						onClick={(e) => onClickCard(ApprovalStatus.APPROVED, true)}
					/>
					<DashboardSummaryCard
						title="승인거부"
						background="#F6CBC6"
						color="#333333"
						count={data?.today?.rejected ?? 0}
						onClick={(e) => onClickCard(ApprovalStatus.REJECTED, true)}
					/>
					<DashboardSummaryCard
						title="예약취소"
						background="#EFC3CD"
						color="#333333"
						count={data?.today?.canceled ?? 0}
						onClick={(e) => onClickCard(ApprovalStatus.CANCELED, true)}
					/>
				</Row>
			</section>
			<section className="mt-40px">
				<AdminSectionTitle title="예약 캘린더" />
				<DashboardCalendar />
			</section>
			<section className="mt-40px">
				<AdminSectionTitle title="전체 현황" />
				<Row className="mt-24px">
					<Table>
						<thead>
							<tr>
								<th>상태</th>
								<th>신청</th>
								<th>승인대기</th>
								<th>승인완료</th>
								<th>승인거부</th>
								<th>예약취소</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th>이번주</th>
								<td>{data?.overall?.weekly_status_total ?? '0'}</td>
								<td>{data?.overall?.weekly_status?.pending ?? '0'}</td>
								<td>{data?.overall?.weekly_status?.approved ?? '0'}</td>
								<td>{data?.overall?.weekly_status?.rejected ?? '0'}</td>
								<td>{data?.overall?.weekly_status?.canceled ?? '0'}</td>
							</tr>
							<tr>
								<th>이번달</th>
								<td>{data?.overall?.monthly_status_total ?? '0'}</td>
								<td>{data?.overall?.monthly_status?.pending ?? '0'}</td>
								<td>{data?.overall?.monthly_status?.approved ?? '0'}</td>
								<td>{data?.overall?.monthly_status?.rejected ?? '0'}</td>
								<td>{data?.overall?.monthly_status?.canceled ?? '0'}</td>
							</tr>
						</tbody>
					</Table>
				</Row>
			</section>
			<section className="mt-40px">
				<AdminSectionTitle title="시설 유형별 이달의 신청 현황" subTitle={data?.room_type?.date ?? '-'} />
				{data?.room_type?.statistics && (
					<Row className="mt-24px">
						<StatisticsGraphContainer>
							{Object.keys(data?.room_type?.statistics).map((key, idx) => {
								const graphColor = graphColorList[idx % graphColorList.length];
								const barWidth = (parseInt(data?.room_type?.statistics[key]) / bookingTotalCount) * 100;
								return (
									<StatisticsGraphRow key={idx}>
										<StatisticsTitle>{key}</StatisticsTitle>
										<StatisticsBarContainer>
											<StatisticsBar width={barWidth ?? 0} color={graphColor} />
											<StatisticsCount color={graphColor}>
												{data?.room_type?.statistics[key] ?? 0}
											</StatisticsCount>
										</StatisticsBarContainer>
									</StatisticsGraphRow>
								);
							})}
						</StatisticsGraphContainer>
					</Row>
				)}
			</section>
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	padding: 40px 50px;
	border-radius: 8px;
	background-color: white;
`;

const Row = styled.div`
	display: flex;
	width: 100%;
`;

const Table = styled.table`
	width: 100%;

	border-collapse: collapse;
	border: 1px solid #dddddd;
	border-top: 2px solid #0e498f;

	color: #0e498f;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
	text-align: center;

	thead th {
		width: calc(100% / 6);
		height: 45px;
		border: 1px solid #dddddd;
	}
	tbody tr th {
		height: 45px;
		border: 1px solid #dddddd;
	}
	tbody tr td {
		color: #666666;
		height: 45px;
		border: 1px solid #dddddd;
	}
`;

const StatisticsGraphContainer = styled.div`
	width: 100%;
	height: 100%;
	padding: 20px 38px 20px 38px;

	border-radius: 8px;
	border: 1px solid #dddddd;
`;

const StatisticsGraphRow = styled.div`
	display: flex;
	align-items: center;
	height: 45px;
`;

const StatisticsTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 130px;
	height: 100%;

	box-sizing: content-box;
	padding-right: 34px;
	border-right: 1px solid #dddddd;

	color: #333333;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const StatisticsBarContainer = styled.div`
	flex-grow: 1;
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

const StatisticsBar = styled.div`
	height: 30px;
	background-color: ${(props) => props.color};
	border-radius: 0 15px 15px 0;
	${(props) =>
		props.width &&
		css`
			width: ${props.width}%;
		`}
`;

const StatisticsCount = styled.div`
	width: 20px;
	margin-left: 9px;

	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
	color: ${(props) => props.color};
`;

export default Dashboard;
