import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';

import * as api from '../apis';
import * as utils from '../utils';

import CategorySlug from '../constants/CategorySlug';
import AdminSectionTitle from '../components/admin/AdminSectionTitle';
import AgreementPrivacyModal from '../components/modal/AgreementPrivacyModal';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const BookingRequest = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const [userData, setUserdata] = useState(null);
	const [unitId, setUnitId] = useState(0);
	const [unitData, setUnitData] = useState(null);
	const [usageDate, setUsageDate] = useState('');
	const [bookingStartTime, setBookingStartTime] = useState('');
	const [bookingEndTime, setBookingEndTime] = useState('');
	const [usageCount, setUsageCount] = useState(1);
	const [purposeDescription, setPurposeDescription] = useState('');
	const [extraDescription, setExtraDescription] = useState('');
	const [attachments, setAttachments] = useState([]);
	const [agreementPrivacy, setAgreementPrivacy] = useState(false);
	const [agreementPrivacyShow, setAgreementPrivacyShow] = useState(false);

	useEffect(() => {
		setUnitId(location.state?.unit_id ?? 0);
		setUsageDate(location.state?.filter?.usage_date ?? '');
		setBookingStartTime(location.state?.filter?.booking_start_time ?? '');
		setBookingEndTime(location.state?.filter?.booking_end_time ?? '');

		api.getUser('self')
			.then((res) => {
				if (res && res.data) {
					setUserdata(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});

		api.getUnit(location.state?.unit_id)
			.then((res) => {
				if (res && res.data) {
					setUnitData(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
				navigate(-1);
			});
	}, []);

	const onClickPrev = () => {
		navigate(-1);
	};

	const onDeleteAttachmentFile = (deleteIndex) => {
		setAttachments(attachments.filter((attachment, idx) => idx !== deleteIndex));
	};

	const onClickRequest = () => {
		if (!usageCount) {
			alert('사용 인원을 정확하게 입력하세요.');
			return;
		}
		if (!agreementPrivacy) {
			alert('개인정보처리방침에 동의하셔야 합니다');
			return;
		}

		const params = new FormData();
		params.append('unit_id', unitId);
		params.append('booking_start_date', convertParameterDate(usageDate, bookingStartTime));
		params.append('booking_end_date', convertParameterDate(usageDate, bookingEndTime));
		params.append('usage_count', usageCount);
		params.append('purpose_description', purposeDescription);
		params.append('extra_description', extraDescription ?? '');
		params.append('agreement_privacy', agreementPrivacy ?? '');
		attachments.forEach((file) => {
			params.append('attachment_files[]', file);
		});

		api.createBooking(params)
			.then((res) => {
				if (res && res.data) {
					navigate('/booking/complete', {
						state: {
							booking_id: res.data?.id,
							contact_number: unitData?.contact_number,
						},
					});
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	/**
	 * FIXME: 현재 로직으로는 usageDate를 기준으로 시간 계산을 하고 있어서
	 * 현재 날짜~ 다음 날짜까지 예약 하는 경우 데이터가 오류가 생길 수 있다
	 * 새벽까지 예약하는 경우는 없을 것이라 생각되므로 일단은 넘어가고 추후 고치자
	 * @param {*} d
	 * @param {*} time
	 * @returns
	 */
	const convertParameterDate = (d, time) => {
		let date = new Date(d);
		let splitedTime = time.split(':');

		date.setHours(splitedTime[0]);
		date.setMinutes(splitedTime[1]);

		return date.toISOString();
	};

	return (
		<Content>
			<Title>신청서 작성</Title>
			<section>
				<Row>
					<HeaderColumn>
						<AdminSectionTitle title="신청자 정보" />
					</HeaderColumn>
					<ContentColumn>
						<InfoRow>
							<InfoColumn col2>
								<InfoTitle>예약자</InfoTitle>
								<InfoDescription>{userData?.name ?? '-'}</InfoDescription>
							</InfoColumn>
							<InfoColumn col2>
								<InfoTitle large>학번/교직원 번호</InfoTitle>
								<InfoDescription>{userData?.user_login ?? '-'}</InfoDescription>
							</InfoColumn>
						</InfoRow>
						<InfoRow>
							<InfoColumn col2>
								<InfoTitle>이메일</InfoTitle>
								<InfoDescription>{userData?.email ?? '-'}</InfoDescription>
							</InfoColumn>
							<InfoColumn col2>
								<InfoTitle large>연락처</InfoTitle>
								<InfoDescription>{userData?.phone ?? '-'}</InfoDescription>
							</InfoColumn>
						</InfoRow>
					</ContentColumn>
				</Row>
			</section>
			<section>
				<Row>
					<HeaderColumn>
						<AdminSectionTitle title="예약정보" />
					</HeaderColumn>
					<ContentColumn>
						<InfoRow>
							<InfoColumn col3>
								<InfoTitle mobileSmall>대학</InfoTitle>
								<InfoDescription>
									{utils.getUnitCategoryName(unitData, CategorySlug.PLACE, 1)}
								</InfoDescription>
							</InfoColumn>
							<InfoColumn col3>
								<InfoTitle mobileSmall>캠퍼스</InfoTitle>
								<InfoDescription>
									{utils.getUnitCategoryName(unitData, CategorySlug.PLACE, 2)}
								</InfoDescription>
							</InfoColumn>
							<InfoColumn col3>
								<InfoTitle mobileSmall>건물</InfoTitle>
								<InfoDescription>
									{utils.getUnitCategoryName(unitData, CategorySlug.PLACE, 3)}
								</InfoDescription>
							</InfoColumn>
						</InfoRow>
						<InfoRow>
							<InfoColumn col3>
								<InfoTitle mobileSmall>시설유형</InfoTitle>
								<InfoDescription>
									{utils.getUnitCategoryName(unitData, CategorySlug.TYPE, 2)}
								</InfoDescription>
							</InfoColumn>
							<InfoColumn col3>
								<InfoTitle mobileSmall>시설명</InfoTitle>
								<InfoDescription>{unitData?.name}</InfoDescription>
							</InfoColumn>
						</InfoRow>
						<InfoRow>
							<InfoColumn col3>
								<InfoTitle mobileSmall>이용기간</InfoTitle>
								<InfoDescription>
									{`${usageDate} ${bookingStartTime} ~ ${bookingEndTime}`}
								</InfoDescription>
							</InfoColumn>
						</InfoRow>
					</ContentColumn>
				</Row>
			</section>
			<section>
				<Row>
					<HeaderColumn>
						<AdminSectionTitle title="신청정보" />
					</HeaderColumn>
					<ContentColumn>
						<InfoRow input>
							<InfoColumn col1>
								<InfoTitle mobileSmall>사용인원</InfoTitle>
								<UsageCountInput
									type="number"
									value={usageCount}
									onChange={(e) => setUsageCount(e.currentTarget.value)}
								/>
								<InfoDescription>{`최대 사용 인원 : ${
									unitData?.room_detail?.max_usage_count ?? 0
								}명`}</InfoDescription>
							</InfoColumn>
						</InfoRow>
						<InfoRow input>
							<InfoColumn col1>
								<InfoTitle top mobileSmall>
									촬영내용
								</InfoTitle>
								<UserTextArea
									value={purposeDescription}
									rows={4}
									onChange={(e) => setPurposeDescription(e.currentTarget.value)}
								/>
							</InfoColumn>
						</InfoRow>
						<InfoRow input>
							<InfoColumn col1>
								<InfoTitle top mobileSmall>
									특이사항
								</InfoTitle>
								<UserTextArea
									value={extraDescription}
									rows={4}
									onChange={(e) => setExtraDescription(e.currentTarget.value)}
								/>
							</InfoColumn>
						</InfoRow>
						<InfoRow input>
							<InfoColumn col1>
								<InfoTitle top mobileSmall>
									교안파일
								</InfoTitle>
								<AttachmentContainer>
									<AttachmentInput
										type="file"
										onChange={(e) => {
											setAttachments([...attachments, ...e.currentTarget.files]);
											e.currentTarget.value = null;
										}}
									/>
									{attachments.map((val, idx) => {
										return (
											<AttachmentFile key={idx}>
												{val.name}
												<StyledHighlightOffIcon onClick={() => onDeleteAttachmentFile(idx)} />
											</AttachmentFile>
										);
									})}
								</AttachmentContainer>
							</InfoColumn>
						</InfoRow>
					</ContentColumn>
				</Row>
			</section>
			<section>
				<Row>
					<HeaderColumn>
						<AdminSectionTitle title="개인정보수집·제공" />
					</HeaderColumn>
					<ContentColumn>
						<Agreement onClick={() => setAgreementPrivacyShow(true)}>개인정보 수집·제공 동의</Agreement>
						<AgreementCheckboxContainer>
							<AgreementCheckbox
								id="agreement_privacy"
								type="checkbox"
								value={agreementPrivacy}
								onChange={(e) => setAgreementPrivacy(e.currentTarget.checked)}
							/>
							<label htmlFor={'agreement_privacy'}>모두 동의합니다.</label>
						</AgreementCheckboxContainer>
					</ContentColumn>
				</Row>
			</section>
			<section>
				<ButtonContainer>
					<PrevButton onClick={onClickPrev}>{`< 이전단계`}</PrevButton>
					<RequestButton onClick={onClickRequest}>예약신청</RequestButton>
				</ButtonContainer>
			</section>
			{agreementPrivacyShow && <AgreementPrivacyModal onClose={() => setAgreementPrivacyShow(false)} />}
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	padding: 40px 50px;
	border-radius: 8px;
	background-color: white;
	margin-top: 50px;

	@media only screen and (max-width: 767.98px) {
		width: calc(100% - 30px);
		padding: 20px 15px 30px 15px;
		margin: 20px 15px;
	}
`;

const Title = styled.span`
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 22px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: -0.66px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		font-size: 20px;
	}
`;

const Row = styled.div`
	display: flex;
	margin-top: 40px;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		margin-top: 29px;
	}
`;

const HeaderColumn = styled.div`
	width: 200px;
`;

const ContentColumn = styled.div`
	width: 920px;
	margin-left: 20px;
	border-top: 2px solid #333333;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		margin-left: 0;
		margin-top: 14px;
	}
`;

const InfoRow = styled.div`
	display: flex;
	width: 100%;
	padding: 14px 0;
	border-bottom: 1px solid #dddddd;

	${(props) =>
		props.input &&
		css`
			padding: 9px 0;
		`}

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		padding: 0;
		border-bottom: none;
	}
`;

const InfoColumn = styled.div`
	display: flex;
	height: 100%;

	${(props) =>
		props.col1 &&
		css`
			width: 100%;
		`}
	${(props) =>
		props.col2 &&
		css`
			width: 50%;
		`}
	${(props) =>
		props.col3 &&
		css`
			width: 33.33%;
		`}

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		min-height: 40px;
		border-bottom: 1px solid #dddddd;
	}
`;

const InfoTitle = styled.div`
	display: flex;
	align-items: center;
	padding: 0 10px;
	width: 80px;

	${(props) =>
		props.large &&
		css`
			width: 130px;
		`}

	${(props) =>
		props.top &&
		css`
			align-items: flex-start;
			padding-top: 5px;
		`}

	color: #333333;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		width: 115px;
		font-size: 14px;

		${(props) =>
			props.mobileSmall &&
			css`
				width: 80px;
				min-width: 80px;
			`}

		${(props) =>
			props.top &&
			css`
				align-items: center;
				padding-top: 0;
			`}
	}
`;

const InfoDescription = styled.div`
	display: flex;
	align-items: center;
	padding: 0 8px;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		font-size: 14px;
	}
`;

const UserInput = styled.input`
	height: 40px;
	padding: 8px;
	margin-left: 8px;

	border: 1px solid #dddddd;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const UsageCountInput = styled(UserInput)`
	width: 100px;

	@media only screen and (max-width: 767.98px) {
		width: 50px;
		height: 32px;
		align-self: center;
	}
`;

const UserTextArea = styled.textarea`
	width: calc(100% - 90px);
	padding: 8px;
	margin-left: 8px;

	border: 1px solid #dddddd;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		margin-top: 8px;
		margin-bottom: 8px;
	}
`;

const AttachmentContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 8px;
	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;

	input:not(:first-child) {
		margin-top: 8px;
	}
`;

const AttachmentFile = styled.div`
	margin-top: 8px;
`;

const StyledHighlightOffIcon = styled(HighlightOffIcon)`
	margin-left: 8px;
	cursor: pointer;
`;

const AttachmentInput = styled.input`
	@media only screen and (max-width: 767.98px) {
		width: 100%;
		margin-top: 8px;
		& + & {
			margin-bottom: 8px;
		}
	}
`;

const Agreement = styled.div`
	display: flex;
	align-items: center;

	width: 100%;
	height: 40px;
	margin-top: 20px;
	padding: 0 15px;
	border: 1px solid #dddddd;
	border-radius: 4px;
	cursor: pointer;

	color: #333333;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;

	& + & {
		margin-top: 5px;
	}

	@media only screen and (max-width: 767.98px) {
		margin-top: 13px;
	}
`;

const AgreementCheckboxContainer = styled.div`
	width: 100%;
	@media only screen and (max-width: 767.98px) {
		text-align: center;
	}
`;
const AgreementCheckbox = styled.input`
	margin-top: 24px;
	& + label {
		margin-left: 9px;
		color: #333333;
		font-family: 'Noto Sans';
		font-size: 15px;
		font-weight: 500;
		font-style: normal;
		letter-spacing: -0.45px;
		line-height: 100%;
	}

	@media only screen and (max-width: 767.98px) {
		margin-top: 21px;
	}
`;

const ButtonContainer = styled.div`
	width: 100%;
	margin-top: 36px;
	display: flex;
	justify-content: center;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		margin-top: 29px;
	}
`;

const PrevButton = styled.button`
	width: 130px;
	height: 45px;
	border-radius: 23px;
	border: 2px solid #2561a7;
	background-color: #ffffff;

	color: #2561a7;
	font-family: 'Noto Sans';
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.48px;
	line-height: 100%;
`;

const RequestButton = styled.button`
	width: 130px;
	height: 45px;
	border-radius: 23px;
	border: none;
	background-image: linear-gradient(to right, #10abb1 0%, #275ba6 100%);

	margin-left: 10px;
	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.48px;
	line-height: 100%;
`;

export default BookingRequest;
