import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import useFilterHandler from '../../hooks/useFilterHandler';

import * as api from '../../apis';
import * as utils from '../../utils';

import FilterType from '../../constants/FilterType';
import CategorySlug from '../../constants/CategorySlug';
import AdminSectionTitle from '../../components/admin/AdminSectionTitle';
import TablePagination from '../../components/TablePagination';

const UnitList = () => {
	const navigate = useNavigate();
	const { categoryFilter, publishStatusFilter, onChangeFilterCheckbox, getUnitFilterParams } = useFilterHandler({});

	const [searchTitle, setSearchTitle] = useState('');
	const [usageStartDate, setUsageStartDate] = useState('');
	const [usageEndDate, setUsageEndDate] = useState('');

	const [pageIndex, setPageIndex] = useState(1);

	const [unitList, setUnitList] = useState(null);
	const [allChecked, setAllChecked] = useState(false);
	const [initialized, setInitialized] = useState(false);

	const tableTopRef = useRef(null);

	useEffect(() => {
		if (!unitList) return;

		setUnitList({
			...unitList,
			items: unitList.items.map((unit) => {
				return {
					...unit,
					selected: allChecked,
				};
			}),
		});
	}, [allChecked]);

	useEffect(() => {
		getUnitsWithFilter();
	}, [pageIndex]);

	const getUnitsFromApi = (filterParams) => {
		api.getUnits(filterParams)
			.then((res) => {
				if (res && res.data) {
					if (!initialized) setInitialized(true);
					setUnitList(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const getUnitsWithFilter = () => {
		let filterParams = getUnitFilterParams();
		if (pageIndex) filterParams.push(`page=${pageIndex}`);
		if (searchTitle) filterParams.push(`keyword=${searchTitle}`);
		if (usageStartDate) filterParams.push(`usage_start_date=${usageStartDate}`);
		if (usageEndDate) filterParams.push(`usage_end_date=${usageEndDate}`);
		getUnitsFromApi(filterParams);
	};

	const onClickSearch = () => {
		if (usageStartDate && usageEndDate && usageStartDate > usageEndDate) {
			alert('이용가능기간 시작일은 종료일 이후일 수 없습니다.');
			return;
		}
		getUnitsWithFilter();
	};

	const onClickDetail = (id) => {
		navigate(`/admin/unit/rooms/${id}/edit`);
	};

	const onClickDeleteSelected = () => {
		const selectedUnitList = unitList.items.filter((val) => val.selected);
		if (selectedUnitList.length === 0) {
			alert('선택한 시설이 없습니다.');
			return;
		}

		if (!window.confirm(`선택한 ${selectedUnitList.length}개의 시설을 정말 삭제하시겠습니까?`)) return;

		let promiseArr = [];
		selectedUnitList.map((unit) => {
			promiseArr.push(api.deleteUnit(unit.id));
		});
		Promise.all(promiseArr).then(() => {
			alert('선택한 시설을 정상적으로 삭제하였습니다.');
			getUnitsWithFilter();
		});
	};

	const onPageChange = (page) => {
		setAllChecked(false);
		setPageIndex(page);
		tableTopRef.current.scrollIntoView();
	};

	const renderFilterList = () => {
		return (
			<>
				<FilterRow>
					<FilterTitle>시설명</FilterTitle>
					<FilterContents>
						<SearchTitleInput
							type="text"
							value={searchTitle}
							onChange={(e) => setSearchTitle(e.currentTarget.value)}
							placeholder="시설명을 입력해주세요."
						/>
					</FilterContents>
				</FilterRow>
				{renderCategoryFilter(CategorySlug.TYPE, 1)}
				{renderCategoryFilter(CategorySlug.TYPE, 2)}
				{renderCategoryFilter(CategorySlug.PLACE, 1)}
				{renderCategoryFilter(CategorySlug.PLACE, 2)}
				{renderCategoryFilter(CategorySlug.PLACE, 3)}
				<FilterRow>
					<FilterTitle>공개 상태</FilterTitle>
					<FilterContents>
						{renderFilterCheckbox(publishStatusFilter, FilterType.PUBLISH_STATUS)}
					</FilterContents>
				</FilterRow>
				<FilterRow>
					<FilterTitle>이용가능기간</FilterTitle>
					<FilterContents>
						<SearchDateInput
							type="date"
							value={usageStartDate}
							onChange={(e) => setUsageStartDate(e.currentTarget.value)}
						/>
						<SearchDateInputSeparator>~</SearchDateInputSeparator>
						<SearchDateInput
							type="date"
							value={usageEndDate}
							onChange={(e) => setUsageEndDate(e.currentTarget.value)}
						/>
					</FilterContents>
				</FilterRow>
			</>
		);
	};

	const renderCategoryFilter = (slug, depth) => {
		if (categoryFilter.length === 0) return null;
		let filter = categoryFilter
			.find((category) => category.slug === slug)
			.filters.find((categoryFilter) => categoryFilter.depth === depth);

		return (
			<FilterRow>
				<FilterTitle>{filter.title}</FilterTitle>
				<FilterContents>{renderFilterCheckbox(filter.category_items, FilterType.CATEGORY)}</FilterContents>
			</FilterRow>
		);
	};

	const renderFilterCheckbox = (filterList, type) => {
		return filterList?.map((filter, idx) => {
			const id = `${type}_${filter.id}_${filter.name}`;
			return (
				<FilterCheckboxGroup key={idx}>
					<FilterCheckbox
						key={idx}
						type="checkBox"
						name={id}
						id={id}
						checked={filter?.selected}
						onChange={(e) => {
							onChangeFilterCheckbox(type, filter.id, e.currentTarget.checked);
						}}
					/>
					<label htmlFor={id}>{filter.name}</label>
				</FilterCheckboxGroup>
			);
		});
	};

	if (!initialized) return null;

	return (
		<Content>
			<section>
				<SearchUnitTopContainer>
					<AdminSectionTitle title="시설 검색" />
					<UnitAddButton onClick={() => navigate('/admin/unit/rooms/create')}>신규 시설 등록</UnitAddButton>
				</SearchUnitTopContainer>
				<SearchFilterContainer className="mt-24px">{renderFilterList()}</SearchFilterContainer>
				<SearchButtonContainer>
					<SearchButton onClick={onClickSearch}>검색</SearchButton>
				</SearchButtonContainer>
			</section>
			<ScrollSection className="mt-50px" ref={tableTopRef}>
				<AdminSectionTitle title="시설 목록" subTitle={`전체 ${unitList?.pagination.total ?? '0'}개`} />
				<TableTopContainer>
					<DeleteButton onClick={onClickDeleteSelected}>선택삭제</DeleteButton>
				</TableTopContainer>
				<SearchResultTable>
					<thead>
						<tr>
							<th style={{ minWidth: '50px' }}>
								<FilterCheckbox
									type="checkBox"
									checked={allChecked}
									onChange={(e) => setAllChecked(e.currentTarget.checked)}
								/>
							</th>
							<th style={{ minWidth: '50px' }}>번호</th>
							<th style={{ minWidth: '100px' }}>
								{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.TYPE, 1)}
							</th>
							<th style={{ minWidth: '120px' }}>
								{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.TYPE, 2)}
							</th>
							<th style={{ minWidth: '90px' }}>
								{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.PLACE, 1)}
							</th>
							<th style={{ minWidth: '90px' }}>
								{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.PLACE, 2)}
							</th>
							<th style={{ minWidth: '90px' }}>
								{utils.getCategoryFilterTitle(categoryFilter, CategorySlug.PLACE, 3)}
							</th>
							<th style={{ minWidth: '120px' }}>시설명</th>
							<th style={{ minWidth: '70px' }}>수량(실)</th>
							<th style={{ minWidth: '70px' }}>공개상태</th>
							<th style={{ minWidth: '170px' }}>이용가능기간</th>
							<th style={{ minWidth: '100px' }}>항목보기</th>
						</tr>
					</thead>
					<tbody>
						{unitList?.items.map((unit, idx) => {
							return (
								<tr key={idx}>
									<td>
										<FilterCheckbox
											type="checkBox"
											checked={unit.selected}
											onChange={(e) =>
												setUnitList({
													...unitList,
													items: unitList.items.map((unit, dataIdx) => {
														if (idx === dataIdx) {
															return {
																...unit,
																selected: e.currentTarget.checked,
															};
														}
														return unit;
													}),
												})
											}
										/>
									</td>
									<td>{unit.id}</td>
									<td>{utils.getUnitCategoryName(unit, CategorySlug.TYPE, 1)}</td>
									<td>{utils.getUnitCategoryName(unit, CategorySlug.TYPE, 2)}</td>
									<td>{utils.getUnitCategoryName(unit, CategorySlug.PLACE, 1)}</td>
									<td>{utils.getUnitCategoryName(unit, CategorySlug.PLACE, 2)}</td>
									<td>{utils.getUnitCategoryName(unit, CategorySlug.PLACE, 3)}</td>
									<td>{unit.name ?? '-'}</td>
									<td>{unit.room_items?.length ?? '-'}</td>
									<td>{unit.published ? '공개' : '미공개'}</td>
									<td>
										{unit?.enable_schedule?.usage_start_date ?? '-'}~
										{unit?.enable_schedule?.usage_end_date ?? '-'}
									</td>
									<td>
										<DetailButton onClick={() => onClickDetail(unit.id)}>보기</DetailButton>
									</td>
								</tr>
							);
						})}
					</tbody>
					<tfoot>
						<TableFoot>
							<td colSpan="100%">
								<DeleteButton onClick={onClickDeleteSelected}>선택삭제</DeleteButton>
							</td>
						</TableFoot>
					</tfoot>
				</SearchResultTable>
				<div className="mt-24px">
					<TablePagination
						currentPage={unitList?.pagination.current_page}
						lastPage={unitList?.pagination.last_page}
						onPageChange={onPageChange}
					/>
				</div>
			</ScrollSection>
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	padding: 40px 50px;
	background-color: white;
`;

const SearchUnitTopContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const UnitAddButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 90px;
	height: 30px;

	border-radius: 8px;
	border: none;
	background-color: #11a9b1;
	cursor: pointer;

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.39px;
	line-height: 100%;
`;

const SearchFilterContainer = styled.div`
	width: 100%;
	border-top: 2px solid #333333;
`;

const FilterRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;

	min-height: 45px;
	border-bottom: 1px solid #dddddd;
`;

const FilterTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 140px;

	background-color: #f7f7f7;
	font-weight: bold;

	color: #333333;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const FilterContents = styled.ul`
	display: flex;
	flex: 1;
	justify-content: flex-start;
	align-items: center;
	flex-grow: 1;
	flex-flow: wrap;

	height: 100%;
	padding: 5px 15px;

	margin-block-start: 0;
	margin-block-end: 0;
	padding-inline-start: 15px;
`;

const FilterCheckboxGroup = styled.li`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin: 7.5px 30px 7.5px 0;

	label {
		margin-left: 9px;
		cursor: pointer;

		color: #666666;
		font-family: 'Noto Sans';
		font-size: 15px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: -0.45px;
		line-height: 100%;
	}
`;

const FilterCheckbox = styled.input`
	width: 15px;
	height: 15px;
	border: 1px solid #c5c5c5;
`;

const SearchTitleInput = styled.input`
	width: 300px;
	height: 35px;
	margin: 7.5px 0;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const SearchDateInput = styled.input`
	width: 150px;
	height: 35px;
	margin: 7.5px 0;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const SearchDateInputSeparator = styled.span`
	margin: 0 10px;
	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const SearchButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	margin-top: 20px;
`;

const SearchButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100px;
	height: 40px;
	border: none;
	border-radius: 20px;
	background-color: #0e498f;
	background-image: linear-gradient(to right, #10abb1 0%, #275ba6 100%);

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.48px;
	line-height: 100%;
`;

const ScrollSection = styled.section`
	scroll-margin-top: 110px;
`;

const TableTopContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	height: 45px;
	margin-top: 20px;
	background: #f7f7f7;
	border-top: 1px solid #dddddd;
`;

const SearchResultTable = styled.table`
	width: 100%;

	border-collapse: collapse;
	border-top: 2px solid #333333;

	text-align: center;
	thead th {
		height: 45px;
		color: #333333;
		font-family: 'Noto Sans';
		font-size: 15px;
		font-weight: 500;
		font-style: normal;
		letter-spacing: -0.45px;
		line-height: 100%;
		:first-child {
			border-right: 1px solid #dcdcdc;
		}
	}
	thead tr {
		border-bottom: 1px solid #dcdcdc;
	}

	tbody td {
		height: 45px;

		color: #666666;
		font-family: 'Noto Sans';
		font-size: 15px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: -0.45px;
		line-height: 100%;
		word-break: break-all;
		:first-child {
			border-right: 1px solid #dddddd;
		}
	}
	tbody tr {
		border-bottom: 1px solid #dddddd;
	}

	tfoot {
		height: 45px;
		background: #f7f7f7;
		border-bottom: 1px solid #dddddd;
	}
`;

const TableFoot = styled.tr`
	text-align: left;
`;

const DetailButton = styled.button`
	width: 55px;
	height: 26px;

	border: none;
	border-radius: 13px;
	background-color: #275ba6;
	color: white;

	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.42px;
	line-height: 100%;
`;

const DeleteButton = styled.button`
	width: 60px;
	height: 28px;

	margin-left: 11px;
	border: none;
	border-radius: 4px;
	background-color: #275ba6;
	color: white;

	font-family: 'Noto Sans';
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.39px;
	line-height: 100%;
`;

export default UnitList;
