import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import useSizeDetector from '../hooks/useSizeDetector';

import * as api from '../apis';
import * as utils from '../utils';

import Day from '../constants/Day';
import CategorySlug from '../constants/CategorySlug';
import AdditionalMediaType from '../constants/AdditionalMediaType';
import TimeSelect from '../components/TimeSelect';

import BookingDateIconImage from '../assets/images/screens/Booking/booking_date_icon.png';
import BookingEnableTimeIconImage from '../assets/images/screens/Booking/booking_enable_time_icon.png';
import BookingStartTimeIconImage from '../assets/images/screens/Booking/booking_start_time_icon.png';
import BookingUsageTimeIconImage from '../assets/images/screens/Booking/booking_usage_time_icon.png';

const BookingSchedule = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const { isDesktop } = useSizeDetector();

	const [tabIndex, setTabIndex] = useState(0);
	const [unitData, setUnitData] = useState(null);
	const [usageDate, setUsageDate] = useState(null);
	const [startTime, setStartTime] = useState(null);
	const [blockTime, setBlockTime] = useState(0);
	const [scheduleList, setScheduleList] = useState([]);

	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		setUsageDate(location.state?.filter?.usage_date ?? null);

		api.getUnit(id)
			.then((res) => {
				if (res && res.data) {
					setUnitData(res.data);
					setBlockTime(res.data?.enable_schedule?.block_time ?? 0);
					setInitialized(true);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	useEffect(() => {
		if (!initialized) return;

		// 데스크톱 메뉴 스크롤 감지
		const option = {
			root: null, //viewport
			rootMargin: '0px',
			threshold: 0.45, // 45%가 viewport에 들어와 있어야 callback 실행
		};
		const callback = (entries, observer) => {
			entries.forEach((entry) => {
				let targetElemId = null;
				if (entry.isIntersecting) {
					targetElemId = entry.target.id;
				}
				if (targetElemId) {
					setTabIndex(parseInt(targetElemId.replace('section', '')));
				}
			});
		};
		const observer = new IntersectionObserver(callback, option);
		const sections = document.getElementsByClassName('tab-section');
		for (let section of sections) {
			observer.observe(section);
		}
	}, [initialized]);

	useEffect(() => {
		if (id && usageDate) {
			api.getUnitSchedule(id, usageDate)
				.then((res) => {
					if (res && res.data) {
						setScheduleList(res.data);
						checkBookingSchedule(res.data);
					}
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}, [usageDate]);

	useEffect(() => {
		checkBookingSchedule(scheduleList);
	}, [startTime, blockTime]);

	const checkBookingSchedule = (currentScheduleList) => {
		if (!startTime || currentScheduleList.length === 0) return;

		const bookingEndTime = calcBookingEndTime(Date.now(), startTime, blockTime);
		setScheduleList(
			currentScheduleList.map((schedule) => {
				return {
					...schedule,
					booked: schedule.from >= startTime && schedule.to <= bookingEndTime,
				};
			})
		);
	};

	const calcBookingEndTime = (usageDate, startTime, block) => {
		const splited = startTime.split(':');

		let date = new Date(usageDate);
		date.setHours(splited[0]);
		date.setMinutes(splited[1]);

		let endTime = new Date(date.getTime() + block * 60000);
		endTime.toLocaleTimeString('en-US', { hour12: false });

		// 다음 날짜까지 예약을 하려는 경우 block한다
		if (date.getDay() !== endTime.getDay()) {
			alert('오늘 날짜를 초과해서 예약하실 수 없습니다.');
			return;
		}

		let endHour = endTime.getHours();
		let endMinutes = endTime.getMinutes();

		return `${String(endHour).padStart(2, '0')}:${String(endMinutes).padStart(2, '0')}`;
	};

	const convertMinuteToHourMinuteStr = (minute) => {
		let hour = parseInt(minute / 60);
		minute -= hour * 60;

		if (hour === 0) {
			return `${minute}분`;
		}
		return `${hour}시간 ${minute}분`;
	};

	const getUnitCategoryFullName = () => {
		return `${utils.getUnitCategoryName(unitData, CategorySlug.PLACE, 1)} | ${utils.getUnitCategoryName(
			unitData,
			CategorySlug.PLACE,
			2
		)} | ${utils.getUnitCategoryName(unitData, CategorySlug.PLACE, 3)} | ${utils.getUnitCategoryName(
			unitData,
			CategorySlug.TYPE,
			2
		)}`;
	};

	const getEnabledWeekdayStr = () => {
		return (
			unitData?.enable_schedule?.booking_weekday
				?.map((weekday) => Day.toShortString(parseInt(weekday)))
				?.join(', ') ?? '-'
		);
	};

	const getBookingScheduleTime = () => {
		if (!startTime) {
			return '(시작시간을 선택해 주세요)';
		}

		const bookingEndTime = calcBookingEndTime(Date.now(), startTime, blockTime);
		return `(${startTime}~${bookingEndTime})`;
	};

	const onTabChange = (idx) => {
		setTabIndex(idx);

		const element = document.getElementById(`section${idx}`);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	const onClickPrev = () => {
		navigate('/booking');
	};

	const onClickNext = () => {
		if (!usageDate) {
			alert('선택한 이용일자가 없습니다.');
			return;
		}
		if (!startTime) {
			alert('선택한 시작 시간이 없습니다.');
			return;
		}
		if (!blockTime) {
			alert('최소 이용시간을 설정하지 않았습니다.');
			return;
		}

		const bookingEndTime = calcBookingEndTime(usageDate, startTime, blockTime);
		if (!bookingEndTime) {
			return;
		}

		// 시작 시간이 scheduleList의 첫번째 시간보다 이후여야 한다.
		if (scheduleList.length !== 0 && startTime < scheduleList[0].from) {
			alert('예약 가능 시간이 아닙니다.');
			return;
		}
		// 종료 시간이 scheduleList의 마지막 시간보다 이전이어야 한다.
		if (scheduleList.length !== 0 && bookingEndTime > scheduleList[scheduleList.length - 1].to) {
			alert('예약 가능 시간이 아닙니다.');
			return;
		}
		let scheduleEnable = true;
		for (let idx in scheduleList) {
			let schedule = scheduleList[idx];
			if (startTime < schedule.to && bookingEndTime > schedule.from && schedule.enabled === false) {
				scheduleEnable = false;
				break;
			}
		}
		if (!scheduleEnable) {
			alert('예약 가능 시간이 아닙니다.');
			return;
		}

		navigate('/booking/request', {
			state: {
				unit_id: id,
				filter: {
					usage_date: usageDate,
					booking_start_time: startTime,
					booking_end_time: bookingEndTime,
				},
			},
		});
	};

	const renderAdditionalImage = (media) => {
		return <AdditionalImage src={`${process.env.REACT_APP_API_URL}/storage/files/${media.image.filename}`} />;
	};

	const iframeStyle = {
		position: 'absolute',
		border: 0,
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
	};

	const renderAdditionalLink = (media) => {
		return (
			<IframeWrapper>
				<AdditionalLink src={media.link} style={iframeStyle} />
			</IframeWrapper>
		);
	};

	if (!initialized) return null;

	return (
		<Content>
			<section>
				<Row>
					<UnitDataColumn>
						<section>
							{unitData?.thumbnail ? (
								<UnitThumbnail
									src={`${process.env.REACT_APP_API_URL}/storage/files/${unitData.thumbnail.filename}`}
								/>
							) : (
								<EmptyUnitThumbnail />
							)}
						</section>
						<TabContainer>
							<TabItemRow>
								<TabItem selected={tabIndex === 0} onClick={() => onTabChange(0)} topBorder>
									기본정보
								</TabItem>
								<TabItem
									selected={tabIndex === 1}
									onClick={() => onTabChange(1)}
									topBorder
									rightBorder={!isDesktop}
								>
									상세 설명
								</TabItem>
							</TabItemRow>
							<TabItemRow>
								<TabItem selected={tabIndex === 2} onClick={() => onTabChange(2)} topBorder={isDesktop}>
									이미지 및 영상
								</TabItem>
								<TabItem
									selected={tabIndex === 3}
									onClick={() => onTabChange(3)}
									topBorder={isDesktop}
									rightBorder
								>
									위치
								</TabItem>
							</TabItemRow>
						</TabContainer>
						<TabSection id="section0" className="tab-section">
							<UnitDetailInfoContainer>
								<UnitDetailInfoTitle>시설소개</UnitDetailInfoTitle>
								<UnitDetailInfo>{unitData?.short_description ?? '-'}</UnitDetailInfo>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitDetailInfoTitle>사용 용도</UnitDetailInfoTitle>
								<UnitDetailInfo>{unitData?.purpose ?? '-'}</UnitDetailInfo>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitDetailInfoTitle>제작 장비</UnitDetailInfoTitle>
								<UnitDetailInfo>{unitData?.equipment ?? '-'}</UnitDetailInfo>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitDetailInfoTitle>예약 정보</UnitDetailInfoTitle>
								<UnitDetailInfoRow>
									<UnitDetailInfo>{getEnabledWeekdayStr()}</UnitDetailInfo>
									<UnitDetailInfoSeparator>|</UnitDetailInfoSeparator>
									<UnitDetailInfo>
										{unitData?.enable_schedule?.booking_start_time ?? '-'} ~{' '}
										{unitData?.enable_schedule?.booking_end_time ?? '-'}
									</UnitDetailInfo>
								</UnitDetailInfoRow>
							</UnitDetailInfoContainer>
							<UnitDetailInfoContainer>
								<UnitDetailInfoTitle>호실 정보</UnitDetailInfoTitle>
								<UnitDetailInfo>
									{unitData?.room_items?.map((roomItem) => roomItem.name).join(', ')}
								</UnitDetailInfo>
							</UnitDetailInfoContainer>
						</TabSection>
						<SectionSeparator />
						<TabSection id="section1" className="tab-section">
							<UnitDetailInfoContainer>
								<UnitDetailInfoSectionTitle>상세 설명</UnitDetailInfoSectionTitle>
								<UnitDescriptionViewer
									dangerouslySetInnerHTML={{ __html: unitData?.description ?? '-' }}
								/>
							</UnitDetailInfoContainer>
						</TabSection>
						<SectionSeparator />
						<TabSection id="section2" className="tab-section">
							<UnitDetailInfoContainer>
								<UnitDetailInfoSectionTitle>이미지 및 영상</UnitDetailInfoSectionTitle>
								<UnitAdditionalMediaContainer>
									{unitData?.additional_medias.map((media, _) => {
										return (
											<div key={_}>
												{media.type === AdditionalMediaType.IMAGE
													? renderAdditionalImage(media)
													: renderAdditionalLink(media)}
											</div>
										);
									})}
								</UnitAdditionalMediaContainer>
							</UnitDetailInfoContainer>
						</TabSection>
						<SectionSeparator />
						<TabSection id="section3" className="tab-section">
							<UnitDetailInfoContainer>
								<UnitDetailInfoSectionTitle>위치</UnitDetailInfoSectionTitle>
								<UnitDescriptionViewer
									dangerouslySetInnerHTML={{ __html: unitData?.location ?? '-' }}
								/>
							</UnitDetailInfoContainer>
						</TabSection>
					</UnitDataColumn>
					<UnitScheduleColumn>
						<UnitInfo>
							<UnitName>{unitData?.name}</UnitName>
							<UnitCategoryName>{getUnitCategoryFullName()}</UnitCategoryName>
							<UnitContactNumber>{`문의 : ${unitData?.contact_number ?? '-'}`}</UnitContactNumber>
						</UnitInfo>
						<BookingInfo>
							<BookingTitle>예약 신청</BookingTitle>
							<section>
								<BookingSubTitleContainer>
									<BookingDateIcon src={BookingDateIconImage} />
									<BookingSubTitle>이용일자</BookingSubTitle>
								</BookingSubTitleContainer>
								<SearchDateInput
									type="date"
									value={usageDate ?? ''}
									min={utils.convertDateToStr(new Date())}
									onChange={(e) => {
										// 예약 가능한 요일인지 판단한다.
										let selectedDay = new Date(e.currentTarget.value).getDay();
										if (
											unitData?.enable_schedule?.booking_weekday &&
											!unitData?.enable_schedule?.booking_weekday.includes(selectedDay.toString())
										) {
											alert(
												`예약가능한 요일이 아닙니다.\n예약가능 요일: ${getEnabledWeekdayStr()}`
											);
											return;
										}
										setUsageDate(e.currentTarget.value);
									}}
								/>
							</section>
							<section>
								<BookingAvailableContainer>
									<BookingSubTitleContainer inline className="mt-0">
										<BookingEnableTimeIcon src={BookingEnableTimeIconImage} />
										<BookingSubTitle>예약가능 시간</BookingSubTitle>
									</BookingSubTitleContainer>
									<BookingBlockContainer>
										<BookingBlockSqure />
										<BookingBlockTitle>예약불가</BookingBlockTitle>
									</BookingBlockContainer>
								</BookingAvailableContainer>
								<BookingAvailableTable>
									{scheduleList.map((schedule, idx) => {
										let text = schedule.from_time_str ?? '';
										let smallText = text.length === 1;
										let lastBox = idx === scheduleList.length - 1;
										if (lastBox) text = schedule.to_time_str ?? '';
										return (
											<ScheduleBox
												key={idx}
												enabled={schedule?.enabled}
												booked={schedule?.booked}
												start={schedule.from_time_str ? 'true' : 'false'}
											>
												<ScheduleBoxText small={smallText} last={lastBox}>
													{text}
												</ScheduleBoxText>
											</ScheduleBox>
										);
									})}
								</BookingAvailableTable>
							</section>
							<section className="mt-40px">
								<BookingSubTitleContainer>
									<BookingStartTimeIcon src={BookingStartTimeIconImage} />
									<BookingSubTitle>시작시간</BookingSubTitle>
								</BookingSubTitleContainer>
								<TimeSelect
									time={startTime}
									minHour={
										parseInt(unitData?.enable_schedule?.booking_start_time.substring(0, 2)) ?? 0
									}
									maxHour={parseInt(unitData?.enable_schedule?.booking_end_time.substring(0, 2)) ?? 0}
									onChange={(value) => setStartTime(value)}
								></TimeSelect>
							</section>
							<section>
								<BookingBlockTimeContainer>
									<BookingSubTitleContainer inline className="mt-0">
										<BookingUsageTimeIcon src={BookingUsageTimeIconImage} />
										<BookingSubTitle>이용시간</BookingSubTitle>
									</BookingSubTitleContainer>
									<BookingSubTitleDescription>{`최소 이용시간: ${
										unitData?.enable_schedule?.block_time ?? 0
									}분`}</BookingSubTitleDescription>
								</BookingBlockTimeContainer>
								<BookingBlockTime>
									{convertMinuteToHourMinuteStr(blockTime ?? 0)} {getBookingScheduleTime()}
								</BookingBlockTime>
								<BlockTimeUnitContainer>
									<BlockTimeUnit
										onClick={() => {
											if (
												blockTime + unitData?.enable_schedule?.block_time >
												unitData?.enable_schedule?.max_usage_time
											) {
												return;
											}
											setBlockTime(blockTime + unitData?.enable_schedule?.block_time ?? 0);
										}}
									>{`+${unitData?.enable_schedule?.block_time ?? '0'}분`}</BlockTimeUnit>
									<BlockTimeUnit
										onClick={() => {
											if (blockTime > unitData?.enable_schedule?.block_time) {
												setBlockTime(blockTime - unitData?.enable_schedule?.block_time ?? 0);
											}
										}}
									>{`-${unitData?.enable_schedule?.block_time ?? '0'}분`}</BlockTimeUnit>
									<BlockTimeUnit
										reset
										onClick={() => {
											setBlockTime(unitData?.enable_schedule?.block_time);
										}}
									>
										재설정
									</BlockTimeUnit>
								</BlockTimeUnitContainer>
							</section>
							<section>
								<ButtonContainer>
									<StepButton onClick={onClickPrev}>{`< 이전단계`}</StepButton>
									<StepButton onClick={onClickNext}>{`다음단계 >`}</StepButton>
								</ButtonContainer>
							</section>
						</BookingInfo>
					</UnitScheduleColumn>
				</Row>
			</section>
		</Content>
	);
};

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	margin-top: 50px;

	@media only screen and (max-width: 767.98px) {
		padding: 20px 15px 30px 15px;
		margin-top: 0;
	}
`;

const Row = styled.div`
	display: flex;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column-reverse;
	}
`;

const UnitDataColumn = styled.div`
	width: 800px;
	background-color: #ffffff;
	border-radius: 4px;
	padding: 50px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding: 20px 15px;
		margin-top: 15px;
	}
`;

const UnitThumbnail = styled.img`
	width: 100%;
	height: 440px;
	object-fit: cover;

	@media only screen and (max-width: 767.98px) {
		height: auto;
	}
`;

const EmptyUnitThumbnail = styled.div`
	width: 100%;
	height: 440px;
	background-color: #dddddd;

	@media only screen and (max-width: 767.98px) {
		height: auto;
	}
`;

const TabContainer = styled.div`
	display: flex;
	margin-top: 30px;
	position: sticky;
	top: 160px;
	z-index: 50;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		width: 100%;
		margin-top: 24px;
		top: 60px;
	}
`;

const TabItemRow = styled.div`
	display: flex;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
	}
`;

const TabItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 175px;
	height: 50px;
	cursor: pointer;

	color: #888888;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
	border-left: 1px solid #dddddd;
	border-bottom: 1px solid #dddddd;

	background-color: #f5f5f5;

	${(props) =>
		props.topBorder &&
		css`
			border-top: 1px solid #dddddd;
		`}

	${(props) =>
		props.rightBorder &&
		css`
			border-right: 1px solid #dddddd;
		`}
		
	${(props) =>
		props.selected &&
		css`
			color: #275ba6;
			background-color: white;
			border-top: 2px solid #275ba6;
		`}

	
	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: 40px;
	}
`;

const TabSection = styled.section`
	scroll-margin-top: 230px;

	@media only screen and (max-width: 767.98px) {
		scroll-margin-top: 160px;
	}
`;

const UnitDetailInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 29px;
`;

const UnitDetailInfoTitle = styled.div`
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const UnitDetailInfoSectionTitle = styled.div`
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 18px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const UnitDetailInfoRow = styled.div`
	display: flex;
`;

const UnitDetailInfo = styled.div`
	margin-top: 12px;
	color: #666666;
	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.42px;
	line-height: normal;
	white-space: pre-line;
`;

const UnitDetailInfoSeparator = styled(UnitDetailInfo)`
	margin-left: 10px;
	margin-right: 10px;
`;

const UnitAdditionalMediaContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const UnitDescriptionViewer = styled.div`
	width: 100%;
	font-size: 16px;
	margin-top: 10px;
	overflow-x: scroll;
`;

const AdditionalImage = styled.img`
	width: 100%;
	margin-top: 12px;
`;

const AdditionalLink = styled.iframe``;

const IframeWrapper = styled.div`
	margin-top: 12px;
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
`;

const SectionSeparator = styled.div`
	width: 100%;
	height: 1px;
	background-color: #dddddd;
	margin: 28px 0 16px 0;
`;

const UnitScheduleColumn = styled.div`
	width: 420px;
	margin-left: 20px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		margin-left: 0;
	}
`;

const UnitInfo = styled.div`
	width: 100%;
	height: 200px;
	background-color: #ffffff;
	border-radius: 4px;
	padding: 30px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		height: 152px;
		padding: 25px;
	}
`;

const UnitName = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	height: 70px;
	padding-bottom: 16px;
	border-bottom: 1px solid #dddddd;

	color: #333333;
	font-family: 'Noto Sans';
	font-size: 22px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: -0.66px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		height: initial;
		font-size: 18px;
	}
`;

const UnitCategoryName = styled.div`
	margin-top: 20px;
	height: 20px;
	color: #22499d;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 20px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	break-word: break-all;
`;

const UnitContactNumber = styled.div`
	margin-top: 14px;
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		margin-top: 11px;
	}
`;

const BookingInfo = styled.div`
	position: sticky;
	top: 180px;
	width: 100%;
	height: 663px;
	margin-top: 20px;
	background-color: #ffffff;
	border-radius: 4px;
	padding: 41px 30px;

	@media only screen and (max-width: 767.98px) {
		position: relative;
		top: 0;
		margin-top: 15px;
		height: 592px;
		padding: 25px 15px;
	}
`;

const BookingTitle = styled.div`
	height: 41px;
	padding-bottom: 19px;
	border-bottom: 1px solid #dddddd;
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 22px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: -0.66px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		height: initial;
		font-size: 18px;
	}
`;

const BookingSubTitleContainer = styled.div`
	width: 100%;
	margin-top: 26px;
	display: flex;
	align-items: center;
	margin-bottom: 14px;

	${(props) =>
		props.inline &&
		css`
			width: auto;
			margin-bottom: 0;
		`}

	@media only screen and (max-width: 767.98px) {
		margin-top: 18px;
	}
`;

const BookingSubTitle = styled.span`
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		font-size: 16px;
	}
`;

const BookingDateIcon = styled.img`
	width: 15px;
	height: 18px;
	margin-right: 8px;
`;

const BookingEnableTimeIcon = styled.img`
	width: 14px;
	height: 14px;
	margin-right: 8px;
`;

const BookingStartTimeIcon = styled.img`
	width: 16px;
	height: 18px;
	margin-right: 8px;
`;

const BookingUsageTimeIcon = styled.img`
	width: 18px;
	height: 17px;
	margin-right: 8px;
`;

const SearchDateInput = styled.input`
	width: 200px;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;
	border-radius: 4px;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const BookingAvailableContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 30px;
	margin-bottom: 14px;
`;

const BookingBlockContainer = styled.div`
	display: flex;
	align-items: center;
`;

const BookingBlockSqure = styled.div`
	width: 15px;
	height: 15px;
	background-color: #cccccc;
`;

const BookingBlockTitle = styled.span`
	margin-left: 7px;
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.42px;
	line-height: 100%;
`;

const BookingAvailableTable = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ScheduleBox = styled.div`
	width: 20px;
	height: 15px;
	border: 1px solid #6d9ed8;
	border-right: none;

	&:nth-last-child(1) {
		border-right: 1px solid #6d9ed8;
	}
	&:nth-child(2n + 1) {
		border-style: solid dashed solid solid;
		border-right: 1px dashed #dddddd;
	}
	&:nth-child(2n) {
		border-left: none;
	}
	${(props) =>
		props.booked &&
		css`
			background-color: #e4f0fa;
			border: 1px solid #6d9ed8;
			border-right: none;
		`}
	${(props) =>
		!props.enabled &&
		css`
			background-color: #cccccc;
			border: 1px solid #6d9ed8;
			border-right: none;
		`}
`;

const ScheduleBoxText = styled.div`
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 13px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.28px;
	line-height: 100%;

	position: relative;
	top: 17px;
	left: -7px;

	${(props) =>
		props.last &&
		css`
			left: 10px;
		`}
	${(props) =>
		props.small &&
		css`
			left: -2px;
		`}
`;

const BookingBlockTimeContainer = styled.div`
	display: flex;
	width: 100%;
	margin-top: 30px;
	align-items: center;
	justify-content: space-between;
`;

const BookingSubTitleDescription = styled.span`
	color: #666666;
	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.42px;
	line-height: 100%;
`;

const BookingBlockTime = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 40px;
	margin-top: 18px;
	background-color: #e4f0fa;

	color: #333333;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 700;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const BlockTimeUnitContainer = styled.div`
	display: flex;
	width: 100%;
	height: 40px;
	margin-top: 10px;
`;

const BlockTimeUnit = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	width: 115px;
	height: 40px;
	border: 1px solid #22499d;
	cursor: pointer;

	& + & {
		margin-left: 8px;
	}

	color: #22499d;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;

	${(props) =>
		props.reset &&
		css`
			border: 1px solid #275ba6;
			background-color: #275ba6;
			color: #ffffff;
		`}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	margin-top: 30px;
	padding-top: 20px;
	border-top: 1px solid #dddddd;

	@media only screen and (max-width: 767.98px) {
		margin-top: 20px;
	}
`;

const StepButton = styled.button`
	width: 110px;
	height: 40px;
	background-image: linear-gradient(to right, #10abb1 0%, #275ba6 100%);
	border: none;
	border-radius: 20px;

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;

	& + & {
		margin-left: 16px;
	}
`;

export default BookingSchedule;
