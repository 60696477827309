import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import useSizeDetector from '../hooks/useSizeDetector';

import LogoImg from '../assets/images/common/logo.png';

const Footer = () => {
	const navigate = useNavigate();
	const { Desktop, Mobile } = useSizeDetector();

	const onClickLogo = () => {
		navigate('/');
	};

	const onScrollTop = () => {
		window.scrollTo(0, 0);
	};

	return (
		<Container>
			<Content>
				<Desktop>
					<LogoContainer>
						<LogoImage onClick={onClickLogo} src={LogoImg} />
						<LogoText>스튜디오 예약시스템</LogoText>
						<Description>
							<DescriptionText>
								52828 경상남도 진주시 진주대로 501 경상국립대학교 24-가동 2층 이러닝 스튜디오
							</DescriptionText>
							<DescriptionText>대표전화 : 055) 772-4866</DescriptionText>
							<CopyrightText>© GYEONGSANG NATIONAL University. All Right Resrerved.</CopyrightText>
						</Description>
					</LogoContainer>
					<ScrollTopButton onClick={onScrollTop}>↑</ScrollTopButton>
				</Desktop>
				<Mobile>
					<Description>
						<DescriptionText>52828 경상남도 진주시 진주대로 501</DescriptionText>
						<DescriptionText>경상국립대학교 24-가동 2층 이러닝 스튜디오</DescriptionText>
						<DescriptionText>대표전화 : 055) 772-4866</DescriptionText>
						<CopyrightText>© GYEONGSANG NATIONAL University. All Right Resrerved.</CopyrightText>
					</Description>
				</Mobile>
			</Content>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	height: 180px;
	border-top: 1px solid #dddddd;
	display: flex;
	justify-content: center;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		height: 140px;
	}
`;

const Content = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 1240px;

	@media only screen and (max-width: 767.98px) {
		justify-content: center;
	}
`;

const LogoContainer = styled.div`
	display: flex;
	align-items: center;
	height: 90px;
`;

const LogoImage = styled.img`
	width: 117px;
	height: auto;
	cursor: pointer;
`;

const LogoText = styled.span`
	margin-left: 19px;
	color: #000000;
	font-family: 'Noto Sans';
	font-size: 25px;
	font-weight: 700;
	letter-spacing: -0.75px;
`;

const Description = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 78px;

	@media only screen and (max-width: 767.98px) {
		margin-left: 0;
	}
`;

const DescriptionText = styled.span`
	display: block;
	color: #999999;
	font-family: 'Noto Sans';
	font-size: 14px;
	font-weight: 400;
	line-height: 28px;
	letter-spacing: -0.42px;

	@media only screen and (max-width: 767.98px) {
		text-align: center;
		font-size: 12px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: -0.75px;
		line-height: 18px;
	}
`;

const CopyrightText = styled.span`
	margin-top: 12px;
	color: #333333;
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: normal;
	text-transform: uppercase;
	letter-spacing: -0.42px;

	@media only screen and (max-width: 767.98px) {
		text-align: center;
		font-size: 11px;
		font-weight: 500;
		font-style: normal;
		letter-spacing: -0.67px;
		line-height: 100%;
	}
`;

const ScrollTopButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 60px;
	height: 60px;

	cursor: pointer;
	border: 1px solid #dddddd;

	font-size: 14px;
	font-weight: 700;
`;

export default Footer;
