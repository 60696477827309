import React, { useEffect, useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import CategorySlug from '../constants/CategorySlug';

import * as api from '../apis';

import BannerImg from '../assets/images/screens/Introduce/banner.png';
import Banner2Img from '../assets/images/screens/Introduce/banner2.png';
import CalendarImg from '../assets/images/screens/Introduce/calendar.png';

const Introduce = () => {
	const navigate = useNavigate();
	const { user } = useContext(UserContext);
	const [typeCategoryItemList, setTypeCategoryItemList] = useState([]);
	const [categoryItemId, setCategoryItemId] = useState(1);
	const [unitList, setUnitList] = useState(null);
	const [initialized, setInitialized] = useState(false);

	useEffect(() => {
		if (!user) {
			navigate('/login');
			return;
		}

		api.getCategories()
			.then((res) => {
				if (res && res.data) {
					let typeCategory = res.data.find((category) => category.slug === CategorySlug.TYPE);
					setTypeCategoryItemList(typeCategory.items);
					setInitialized(true);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	useEffect(() => {
		let filterParams = [
			`category_item_ids[][]=${categoryItemId}`,
			`published[]=true`,
			`page_size=${Number.MAX_SAFE_INTEGER}`,
		];
		api.getUnits(filterParams)
			.then((res) => {
				if (res && res.data) {
					setUnitList(res.data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, [categoryItemId]);

	const getTypeCategoryName = (typeCategoryId) => {
		return typeCategoryItemList.find((typeCategory) => typeCategory.id == typeCategoryId)?.name || '';
	};

	const onClickBooking = (unitId) => {
		if (!user) {
			navigate('/login');
			return;
		}
		navigate(`/booking/schedule/${unitId}`);
	};

	if (!initialized) return null;

	return (
		<>
			<BannerContainer>
				<Banner src={categoryItemId === 1 ? BannerImg : Banner2Img}>
					<BannerTitleText>시설소개</BannerTitleText>
					<BannerMenuContainer>
						{typeCategoryItemList.map((typeCategory, _) => {
							return (
								<BannerMenu
									key={_}
									selected={categoryItemId === typeCategory.id}
									onClick={() => setCategoryItemId(typeCategory.id)}
								>
									<span>{typeCategory.name}</span>
								</BannerMenu>
							);
						})}
					</BannerMenuContainer>
				</Banner>
			</BannerContainer>
			<Content>
				<CategoryTitle>{getTypeCategoryName(categoryItemId)}</CategoryTitle>
				{unitList?.items.map((unit, _) => {
					return (
						<UnitRow key={_}>
							{unit.thumbnail ? (
								<Thumbnail
									src={`${process.env.REACT_APP_API_URL}/storage/files/${unit.thumbnail?.filename}`}
								/>
							) : (
								<EmptyThumbnail />
							)}
							<UnitDetailContainer>
								<UnitDetailWrapperContainer>
									<UnitDetailTitleContainer>
										<TitleBar>
											<TitleBarTop />
											<TitleBarBottom />
										</TitleBar>
										<UnitDetailTitleText>{unit.name}</UnitDetailTitleText>
									</UnitDetailTitleContainer>
									<ScheduleButton onClick={() => onClickBooking(unit.id)}>
										<ScheduleIcon src={CalendarImg} />
										<span>예약하기</span>
									</ScheduleButton>
								</UnitDetailWrapperContainer>
								<UnitDetailBodyContainer>
									<UnitDetailRow>
										<UnitDetailRowTitle>시설소개</UnitDetailRowTitle>
										<UnitDetailRowDescription>{unit.short_description}</UnitDetailRowDescription>
									</UnitDetailRow>
									<UnitDetailRow>
										<UnitDetailRowTitle>사용용도</UnitDetailRowTitle>
										<UnitDetailRowDescription>{unit.purpose}</UnitDetailRowDescription>
									</UnitDetailRow>
									<UnitDetailRow>
										<UnitDetailRowTitle>제작장비</UnitDetailRowTitle>
										<UnitDetailRowDescription>{unit.equipment}</UnitDetailRowDescription>
									</UnitDetailRow>
								</UnitDetailBodyContainer>
							</UnitDetailContainer>
						</UnitRow>
					);
				})}
			</Content>
		</>
	);
};

const BannerContainer = styled.div`
	width: 100%;
	height: 100%;
`;

const Banner = styled.div`
	${(props) =>
		props.src &&
		css`
			background-image: url(${props.src});
			background-position: center;
			background-size: cover;
		`}
	position: relative;
	width: 100%;
	height: calc(310 * 100vw / 1920);
	min-height: 310px;
	top: 0px;

	@media only screen and (max-width: 767.98px) {
		height: 150px;
		min-height: 150px;
	}
`;

const BannerTitleText = styled.div`
	position: relative;
	top: 100px;
	text-align: center;

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 50px;
	font-weight: 700;
	letter-spacing: -1.5px;

	@media only screen and (max-width: 767.98px) {
		top: 42px;
		font-size: 25px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: -1.5px;
		line-height: 100%;
	}
`;

const BannerMenuContainer = styled.div`
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: 1px solid rgba(245, 245, 245, 0.2);

	@media only screen and (max-width: 767.98px) {
		height: 40px;
	}
`;

const BannerMenu = styled.div`
	width: 250px;

	display: flex;
	align-self: stretch;
	justify-content: center;
	align-items: center;

	span {
		color: #ffffff;
		font-family: 'Noto Sans';
		font-size: 18px;
		font-weight: 700;
		letter-spacing: -0.54px;
	}

	${(props) =>
		props.selected &&
		css`
			border-top: 2px solid rgba(245, 245, 245);
			box-sizing: border-box;
		`}

	cursor: pointer;

	@media only screen and (max-width: 767.98px) {
		width: 180px;

		span {
			font-size: 14px;
			font-weight: 700;
			font-style: normal;
			letter-spacing: -0.84px;
		}
	}
`;

const Content = styled.div`
	width: 100%;
	max-width: 1240px;
	background-color: white;
	padding-top: 99px;

	@media only screen and (max-width: 767.98px) {
		padding: 42px 20px 0 20px;
	}
`;

const CategoryTitle = styled.div`
	margin-bottom: 80px;

	color: #333333;
	font-family: 'Noto Sans';
	font-size: 45px;
	font-weight: 700;
	letter-spacing: -1.35px;

	@media only screen and (max-width: 767.98px) {
		margin-bottom: 40px;
		font-size: 22px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: -1.32px;
	}
`;

const UnitRow = styled.div`
	display: flex;
	height: 330px;
	& + & {
		margin-top: 150px;
	}

	@media only screen and (max-width: 767.98px) {
		flex-direction: column-reverse;
		height: auto;
		& + & {
			margin-top: 66px;
		}
	}
`;

const Thumbnail = styled.img`
	width: 530px;
	min-width: 530px;
	height: 100%;
	object-fit: cover;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		min-width: initial;
		height: auto;
	}
`;

const EmptyThumbnail = styled.div`
	width: 530px;
	min-width: 530px;
	height: 100%;
	background-color: #dddddd;
`;

const UnitDetailContainer = styled.div`
	margin-left: 60px;
	width: 100%;
	display: flex;
	flex-direction: column;

	@media only screen and (max-width: 767.98px) {
		margin-left: 0;
	}
`;

const UnitDetailWrapperContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;
	width: 100%;

	@media only screen and (max-width: 767.98px) {
		margin-top: 0;
	}
`;

const TitleBar = styled.div`
	width: 4px;
	height: 25px;
	display: flex;
	flex-direction: column;
	background-color: black;

	@media only screen and (max-width: 767.98px) {
		width: 3px;
		height: 18px;
	}
`;

const TitleBarTop = styled.div`
	height: 50%;
	background-color: #55bdbe;
	width: 100%;
`;

const TitleBarBottom = styled.div`
	height: 50%;
	background-color: #275ba6;
	width: 100%;
`;

const UnitDetailTitleContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const UnitDetailTitleText = styled.span`
	margin-left: 20px;
	color: #333333;
	font-family: 'Noto Sans';
	font-size: 35px;
	font-weight: 700;
	letter-spacing: -1.05px;

	@media only screen and (max-width: 767.98px) {
		margin-left: 10px;
		font-size: 20px;
		font-weight: 700;
		font-style: normal;
		letter-spacing: -1.2px;
		line-height: 100%;
	}
`;

const ScheduleButton = styled.div`
	width: 120px;
	height: 45px;
	background-color: #f5f5f5;
	background-image: linear-gradient(to right, #10abb1 0%, #275ba6 100%);
	border-radius: 25px;
	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;

	span {
		color: #ffffff;
		font-family: 'Noto Sans';
		font-size: 16px;
		font-weight: 500;
		letter-spacing: -0.48px;
	}

	@media only screen and (max-width: 767.98px) {
		width: 85px;
		height: 33px;

		span {
			font-size: 13px;
			font-weight: 500;
			font-style: normal;
			letter-spacing: -0.56px;
			line-height: 100%;
		}
	}
`;

const ScheduleIcon = styled.img`
	margin-right: 8px;

	@media only screen and (max-width: 767.98px) {
		width: 12px;
		height: 13px;
		margin-right: 4px;
	}
`;

const UnitDetailBodyContainer = styled.div`
	margin-top: 25px;
	display: flex;
	flex-direction: column;
	border-top: 1px solid #22499d;
	flex-grow: 1;

	@media only screen and (max-width: 767.98px) {
		margin-top: 15px;
	}
`;

const UnitDetailRow = styled.div`
	border-bottom: 1px solid #dddddd;
	box-sizing: border-box;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-grow: 1;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const UnitDetailRowTitle = styled.div`
	margin-left: 8px;
	color: #22499d;
	font-family: 'Noto Sans';
	font-size: 18px;
	font-weight: 500;
	letter-spacing: -0.54px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 20px;
		margin-left: 0;
		font-size: 16px;
		font-weight: 500;
		font-style: normal;
		letter-spacing: -0.96px;
		line-height: normal;
	}
`;

const UnitDetailRowDescription = styled.div`
	max-width: 500px;
	margin-left: 37px;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 18px;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: -0.54px;
	word-break: keep-all;

	@media only screen and (max-width: 767.98px) {
		max-width: 100%;
		margin: 10px 0 18px 0;
		font-size: 14px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: -0.84px;
		line-height: normal;
	}
`;

export default Introduce;
