export default [
	{
		name: '시설 분류 관리',
		link: '/admin/settings/unit/room/categories',
	},
	{
		name: '게시판 분류 관리',
		link: '/admin/settings/board/categories',
	},
	{
		name: '학교 일정 관리',
		link: '/admin/settings/schedules',
	},
	// {
	// 	name: 'SMS 관리',
	// 	link: '/admin/settings/sms_messages',
	// },
];
