import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import useSizeDetector from '../../hooks/useSizeDetector';
import AdminSectionTitle from '../../components/admin/AdminSectionTitle';

import * as api from '../../apis';

const OperatorRequestModal = ({ data, onClose }) => {
	const [answerDescription, setAnswerDescription] = useState('');
	const { Desktop, Mobile } = useSizeDetector();

	const onClickConfirm = async () => {
		try {
			if (!data?.operator_request?.answer) {
				await api.createBookingOperatorRequestAnswer(data?.id, {
					answer: answerDescription,
				});
				alert('답변이 등록되었습니다.');
				onClose(true);
			} else {
				onClose(false);
			}
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<ModalContainer>
			<Content>
				<section>
					<ModalTopContainer>
						<AdminSectionTitle title="운영자 요청사항" subTitle={`예약번호 #${data.id ?? '0'}`} />
						<CloseButton onClick={onClose}>x</CloseButton>
					</ModalTopContainer>
				</section>
				<section>
					<InfoContainer>
						<InfoRow>
							<InfoColumn>
								<InfoTitle>시설명</InfoTitle>
								<InfoDescription>{data?.unit?.name ?? '-'}</InfoDescription>
							</InfoColumn>
							<InfoColumn>
								<InfoTitle>호실</InfoTitle>
								<InfoDescription>{data?.room_item?.name ?? '-'}</InfoDescription>
							</InfoColumn>
						</InfoRow>
						<InfoRow>
							<InfoColumn>
								<InfoTitle>이용기간</InfoTitle>
								<InfoDescription>{data?.booking_date ?? '-'}</InfoDescription>
							</InfoColumn>
						</InfoRow>
					</InfoContainer>
				</section>
				<section>
					<MessageContainer>
						<Desktop>다음과 같은 요청사항을 전달드립니다. 확인 후 답변 부탁드립니다.</Desktop>
						<Mobile>
							다음과 같은 요청사항을 전달드립니다.
							<br />
							확인 후 답변 부탁드립니다.
						</Mobile>
					</MessageContainer>
				</section>
				<section>
					<RequestContainer>
						<RequestRow>
							<RequestColumn>
								<RequestTitle>요청사항</RequestTitle>
								<RequestDescriptionContainer>
									<RequestText strong>{`[${data?.operator_request?.title ?? '-'}]`}</RequestText>
									<RequestText>{data?.operator_request?.description ?? '-'}</RequestText>
								</RequestDescriptionContainer>
							</RequestColumn>
						</RequestRow>
						<RequestRow>
							<RequestColumn>
								<RequestTitle>답변</RequestTitle>
								{data?.operator_request?.answer ? (
									<RequestDescriptionContainer>
										<RequestText>{data?.operator_request?.answer}</RequestText>
									</RequestDescriptionContainer>
								) : (
									<AnswerDescriptionInput
										value={answerDescription}
										placeholder="답변을 입력해주세요"
										onChange={(e) => setAnswerDescription(e.currentTarget.value)}
									/>
								)}
							</RequestColumn>
						</RequestRow>
					</RequestContainer>
				</section>
				<Footer>
					<CancelButton onClick={onClose}>취소</CancelButton>
					<OkButton onClick={onClickConfirm}>{data?.operator_request?.answer ? '확인' : '답변등록'}</OkButton>
				</Footer>
			</Content>
		</ModalContainer>
	);
};

const ModalContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 99999;
	display: flex;
	justify-content: center;
	align-items: center;

	@media only screen and (max-width: 767.98px) {
		padding: 15px;
	}
`;

const Content = styled.div`
	position: relative;
	width: 720px;
	max-height: 80%;
	overflow-y: scroll;

	background-color: white;
	padding: 40px;

	background: #ffffff;
	box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.25);
	border-radius: 10px;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		padding: 20px 15px 40px 15px;
	}
`;

const ModalTopContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const CloseButton = styled.button`
	display: flex;
	align-items: flex-end;
	justify-content: center;

	width: 30px;
	height: 30px;

	border-radius: 8px;
	border: none;
	background-color: #333333;

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 24px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -1.2px;
	line-height: 30px;
	padding-top: 0px;
`;

const InfoContainer = styled.div`
	border-top: 2px solid #333333;
	margin-top: 20px;

	@media only screen and (max-width: 767.98px) {
		margin-top: 14px;
	}
`;

const InfoRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;

	min-height: 45px;
	border-bottom: 1px solid #dddddd;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
		border-bottom: none;
	}
`;

const InfoColumn = styled.div`
	display: flex;
	width: 50%;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		min-height: 45px;
		border-bottom: 1px solid #dddddd;
	}
`;

const InfoTitle = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 120px;
	min-width: 120px;

	background-color: #f7f7f7;

	color: #333333;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		width: 100px;
		min-width: 100px;
		font-size: 14px;
	}
`;

const InfoDescription = styled.span`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	padding: 5px 15px;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		font-size: 14px;
		align-items: center;
	}
`;

const MessageContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 71px;

	color: #0e498f;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		height: 82px;
		font-size: 14px;
		text-align: center;
	}
`;

const RequestContainer = styled.div`
	border-top: 1px solid #dddddd;
`;

const RequestRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;

	min-height: 45px;
	border-bottom: 1px solid #dddddd;
`;

const RequestColumn = styled.div`
	display: flex;
	width: 100%;

	@media only screen and (max-width: 767.98px) {
		flex-direction: column;
	}
`;

const RequestTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 120px;
	min-width: 120px;

	color: #333333;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		width: 100%;
		justify-content: flex-start;
		margin-top: 15px;
	}
`;

const RequestDescriptionContainer = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
	margin: 10px 10px 10px 0;
	padding: 8px;

	border-radius: 4px;
	background-color: #f7f7f7;

	@media only screen and (max-width: 767.98px) {
		margin: 14px 10px 13px 0;
	}
`;

const RequestText = styled.span`
	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: normal;
	white-space: pre-line;

	${(props) =>
		props.strong &&
		css`
			font-weight: 500;
			color: #333333;
		`}

	@media only screen and (max-width: 767.98px) {
		font-size: 14px;
	}
`;

const AnswerDescriptionInput = styled.textarea`
	width: 100%;
	height: 135px;

	margin: 10px 10px 10px 0;
	padding: 8px;

	border: 1px solid #666666;
	border-radius: 4px;
	background-color: white;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		font-size: 14px;
		height: 64px;
		margin: 14px 10px 13px 0;
	}
`;

const Footer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
`;

const CancelButton = styled.button`
	width: 100px;
	height: 40px;
	border: 1px solid #0e498f;
	border-radius: 20px;
	background-color: #ffffff;

	color: #0e498f;
	font-family: 'Noto Sans';
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.48px;
	line-height: 100%;
`;

const OkButton = styled.button`
	width: 100px;
	height: 40px;

	margin-left: 20px;
	border: none;
	border-radius: 20px;
	background-color: #0e498f;
	background-image: linear-gradient(to right, #10abb1 0%, #275ba6 100%);

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.48px;
	line-height: 100%;

	@media only screen and (max-width: 767.98px) {
		margin-left: 10px;
	}
`;

export default OperatorRequestModal;
