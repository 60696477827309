import axios from 'axios';
import Cookies from 'js-cookie';

var headers = null;

const request = axios.create({
	timeout: 10000,
	baseURL: `${process.env.REACT_APP_API_URL}/api/v1/`,
	headers: headers,
});

request.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		//Post field error
		if (error.response.status === 422) {
			if (error.response.data && error.response.data.errors) {
				let errors = error.response.data.errors;
				errors.forEach((value) => {
					alert(value.msg);
				});
			}
		}
		return Promise.reject(error);
	}
);

const serialize = (formData, data, parentKey) => {
	if (!(formData instanceof FormData)) return;
	if (!(data instanceof Object)) return;

	Object.keys(data).forEach((key) => {
		const val = data[key];
		if (val === null) return;
		if (parentKey) key = `${parentKey}[${key}]`;
		if (val instanceof Object && !(val instanceof File) && !Array.isArray(val)) {
			return serialize(formData, val, key);
		}
		if (Array.isArray(val)) {
			val.forEach((v, idx) => {
				if (v instanceof Object) {
					serialize(formData, v, `${key}[${idx}]`);
				} else {
					formData.append(`${key}[${idx}]`, v);
				}
			});
		} else {
			formData.append(key, val);
		}
	});
};

export const login = (user_login, password) => {
	return fetchWithError(`auth/login`, 'POST', {
		user_login: user_login,
		password: password,
	});
};

export const loginSso = (user_login, password) => {
	return fetchWithError(`auth/login/sso`, 'POST', {
		user_login: user_login,
		password: password,
	});
};

export const loginSsoCallback = (result) => {
	return fetchWithError(`auth/login/sso/callback`, 'POST', {
		result: result,
	});
};

export const logout = () => {
	return fetchWithError(`auth/logout`, 'POST');
};

export const checkToken = () => {
	return fetchWithError(`auth/check`, 'GET');
};

export const getDashboard = () => {
	return fetchWithError(`dashboard`, 'GET');
};

export const getUnits = (filterParams) => {
	let url = `units`;
	if (filterParams && filterParams.length > 0) url += `?${filterParams.join('&')}`;
	return fetchWithError(url, 'GET');
};

export const getUnit = (id) => {
	return fetchWithError(`units/${id}`, 'GET');
};

export const createUnit = (data) => {
	let formData = new FormData();
	serialize(formData, data);
	return fetchWithError('units', 'POST', formData);
};

export const updateUnit = (id, data) => {
	let formData = new FormData();
	serialize(formData, data);
	return fetchWithError(`units/${id}`, 'PUT', formData);
};

export const checkUnitExists = (data) => {
	return fetchWithError('units/check', 'POST', data);
};

export const deleteUnit = (id) => {
	return fetchWithError(`units/${id}`, 'DELETE');
};

export const getCategories = () => {
	return fetchWithError(`categories`, 'GET');
};

export const getCategoryFilters = () => {
	return fetchWithError(`categories/filters`, 'GET');
};

export const updateCategory = (id, category) => {
	return fetchWithError(`categories/${id}`, 'PUT', category);
};

export const getUsedCategoryIds = () => {
	return fetchWithError(`categories/used`, 'GET');
};

export const getSystemSchedules = () => {
	return fetchWithError(`settings/schedules`, 'GET');
};

export const createSystemSchedule = (data) => {
	return fetchWithError(`settings/schedules`, 'POST', data);
};

export const updateSystemSchedule = (id, data) => {
	return fetchWithError(`settings/schedules/${id}`, 'PUT', data);
};

export const deleteSystemSchedule = (id) => {
	return fetchWithError(`settings/schedules/${id}`, 'DELETE');
};

export const createBooking = (data) => {
	return fetchWithError(`bookings`, 'POST', data);
};

export const createBookingOperatorRequest = (id, data) => {
	return fetchWithError(`bookings/${id}/operator_request`, 'POST', data);
};

export const createBookingOperatorRequestAnswer = (id, data) => {
	return fetchWithError(`bookings/${id}/operator_request/answer`, 'POST', data);
};

export const getBookings = (filterParams) => {
	let url = `bookings`;
	if (filterParams && filterParams.length > 0) url += `?${filterParams.join('&')}`;
	return fetchWithError(url, 'GET');
};

export const getBooking = (id) => {
	return fetchWithError(`bookings/${id}`, 'GET');
};

export const getUsers = (filterParams) => {
	let url = `users`;
	if (filterParams && filterParams.length > 0) url += `?${filterParams.join('&')}`;
	return fetchWithError(url, 'GET');
};

export const getUser = (id) => {
	return fetchWithError(`users/${id}`, 'GET');
};

export const createUser = (data) => {
	return fetchWithError(`users`, 'POST', data);
};

export const updateUser = (id, data) => {
	let formData = new FormData();
	serialize(formData, data);
	return fetchWithError(`users/${id}`, 'PUT', formData);
};

export const checkUserLogin = (data) => {
	return fetchWithError('users/check/user_login', 'POST', data);
};

export const deleteUser = (id) => {
	return fetchWithError(`users/${id}`, 'DELETE');
};

export const getUnitSchedule = (id, date) => {
	return fetchWithError(`units/${id}/schedule?date=${date}`, 'GET');
};

export const getMyBooking = (filterParams) => {
	let url = `users/self/bookings`;
	if (filterParams && filterParams.length > 0) url += `?${filterParams.join('&')}`;
	return fetchWithError(url, 'GET');
};

export const updateApprovalStatus = (data) => {
	return fetchWithError('bookings/status', 'PUT', data);
};

export const createFile = (data) => {
	return fetchWithError(`files`, 'POST', data);
};

export const getBoard = (boardId) => {
	return fetchWithError(`boards/${boardId}`, 'GET');
};

export const getBoards = () => {
	return fetchWithError('boards', 'GET');
};

export const getPostCategories = (boardId) => {
	return fetchWithError(`boards/${boardId}/categories`, 'GET');
};

export const updatePostCategories = (boardId, categories) => {
	return fetchWithError(`boards/${boardId}/categories`, 'PUT', categories);
};

export const getPosts = (boardId, filterParams) => {
	let url = `boards/${boardId}/posts`;
	if (filterParams && filterParams.length > 0) url += `?${filterParams.join('&')}`;
	return fetchWithError(url, 'GET');
};

export const getPost = (boardId, postId) => {
	return fetchWithError(`boards/${boardId}/posts/${postId}`, 'GET');
};

export const createPost = (boardId, data) => {
	return fetchWithError(`boards/${boardId}/posts`, 'POST', data);
};

export const updatePost = (boardId, postId, data) => {
	return fetchWithError(`boards/${boardId}/posts/${postId}`, 'PUT', data);
};

export const deletePost = (boardId, postId) => {
	return fetchWithError(`boards/${boardId}/posts/${postId}`, 'DELETE');
};

export const createComment = (boardId, postId, data) => {
	return fetchWithError(`boards/${boardId}/posts/${postId}/comments`, 'POST', data);
};

export const deleteComment = (boardId, postId, commentId) => {
	return fetchWithError(`boards/${boardId}/posts/${postId}/comments/${commentId}`, 'DELETE');
};

export const getSmsMessages = () => {
	return fetchWithError(`settings/sms_messages`, 'GET');
};

export const updateSmsMessages = (data) => {
	let formData = new FormData();
	serialize(formData, data);
	return fetchWithError(`settings/sms_messages`, 'PUT', formData);
};

function fetchWithError(url, method, body) {
	return new Promise((resolve, reject) => {
		if (method.toLowerCase() === 'put' || method.toLowerCase() === 'delete') {
			//PUT으로 들어올 경우 method를 POST로 하고 데이터에 _method = "PUT"을 넣어준다.
			//라라벨에서 데이터를 제대로 받아오지 못하기 때문임.
			if (typeof body === 'object') {
				if (body instanceof FormData) {
					body.append('_method', 'PUT');
				} else {
					body._method = method.toUpperCase();
				}
				method = 'POST';
			} else {
				body = {
					_method: method.toUpperCase(),
				};
				method = 'POST';
			}
		}
		axios({
			method: method,
			url: `${process.env.REACT_APP_API_URL}/api/v1/${url}`,
			headers: {
				Authorization: 'Bearer ' + Cookies.get('api_token'),
			},
			data: body,
		})
			.then(async (res) => {
				resolve(res);
			})
			.catch((error) => {
				if (error.response) {
					let data = error.response.data;
					if (data) {
						if (data.errors) {
							//laravel의 errors 객체로 들어올 경우
							for (let key in data.errors) {
								if (!data.errors.hasOwnProperty(key)) continue;

								let error = data.errors[key];
								for (let prop in error) {
									if (!error.hasOwnProperty(prop)) continue;

									alert(error[prop]);
								}
							}
						} else if (data.message) {
							//API 의 Custom Message
							alert(data.message);
						}
					}
					reject(error.response);
				}
			});
	});
}
