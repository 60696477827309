import React, { useState } from 'react';
import styled from 'styled-components';

import AdminSectionTitle from '../../components/admin/AdminSectionTitle';

const SystemScheduleModal = ({ defaultData, onConfirm, onClose }) => {
	const [name, setName] = useState(defaultData.name);
	const [startDate, setStartDate] = useState(defaultData.startDate);
	const [endDate, setEndDate] = useState(defaultData.endDate);
	const [allowStatus, setAllowStatus] = useState(defaultData.allowStatus);

	const validateData = () => {
		if (!name) {
			alert('제목을 입력해 주십시오.');
			return false;
		}
		if (!startDate) {
			alert('시작일을 입력하여 주십시오.');
			return false;
		}
		if (!endDate) {
			alert('종료일을 입력하여 주십시오.');
			return false;
		}
		if (startDate > endDate) {
			alert('시작일은 종료일 이후일 수 없습니다.');
			return false;
		}

		return true;
	};

	return (
		<ModalContainer>
			<Content>
				<section>
					<ModalTopContainer>
						<AdminSectionTitle title="학교 일정 관리" />
						<CloseButton onClick={onClose}>x</CloseButton>
					</ModalTopContainer>
				</section>
				<section className="mt-24px">
					<ScheduleContainer>
						<Row>
							<Title>제목</Title>
							<Contents>
								<TextInput
									type="text"
									value={name}
									placeholder="제목을 입력해주세요"
									onChange={(e) => setName(e.currentTarget.value)}
								/>
							</Contents>
						</Row>
						<Row>
							<Title>기간</Title>
							<Contents>
								<DateInput
									type="date"
									value={startDate}
									onChange={(e) => setStartDate(e.currentTarget.value)}
								/>
								<DateInputSeparator>~</DateInputSeparator>
								<DateInput
									type="date"
									value={endDate}
									onChange={(e) => setEndDate(e.currentTarget.value)}
								/>
							</Contents>
						</Row>
						<Row>
							<Title>예약 허용 상태</Title>
							<Contents>
								<RadioBoxGroup>
									<input
										type="radio"
										id={'allowStatus-true'}
										name={'allowStatus-true'}
										checked={allowStatus === true}
										onChange={(e) => {
											setAllowStatus(true);
										}}
									/>
									<label htmlFor={'allowStatus-true'}>허용</label>
								</RadioBoxGroup>
								<RadioBoxGroup>
									<input
										type="radio"
										id={'allowStatus-false'}
										name={'allowStatus-false'}
										checked={allowStatus === false}
										onChange={(e) => {
											setAllowStatus(false);
										}}
									/>
									<label htmlFor={'allowStatus-false'}>비허용</label>
								</RadioBoxGroup>
							</Contents>
						</Row>
					</ScheduleContainer>
				</section>
				<Footer>
					<CancelButton onClick={onClose}>취소</CancelButton>
					<OkButton
						onClick={() => {
							if (!validateData()) {
								return;
							}
							onConfirm(name, startDate, endDate, allowStatus);
						}}
					>
						확인
					</OkButton>
				</Footer>
			</Content>
		</ModalContainer>
	);
};

const ModalContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 99999;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Content = styled.div`
	position: relative;
	width: 720px;
	max-height: 80%;
	overflow-y: scroll;

	background-color: white;
	padding: 40px;

	background: #ffffff;
	box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
`;

const ModalTopContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const CloseButton = styled.button`
	display: flex;
	align-items: flex-end;
	justify-content: center;

	width: 30px;
	height: 30px;

	border-radius: 8px;
	border: none;
	background-color: #333333;

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 24px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -1.2px;
	line-height: 30px;
	padding-top: 0px;
`;

const ScheduleContainer = styled.div`
	width: 100%;
	border-top: 2px solid #333333;
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;

	min-height: 45px;
	border-bottom: 1px solid #dddddd;
`;

const Title = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 140px;

	background-color: #ffffff;
	font-weight: bold;

	color: #333333;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const Contents = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-grow: 1;
	flex-flow: wrap;

	height: 100%;
	padding: 10px;
`;

const TextInput = styled.input`
	width: 100%;
	height: 35px;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const DateInput = styled.input`
	width: 150px;
	height: 35px;
	margin: 7.5px 0;
	padding: 8px;

	border: 1px solid #dddddd;
	background-color: #ffffff;

	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const DateInputSeparator = styled.span`
	margin: 0 10px;
	color: #666666;
	font-family: 'Noto Sans';
	font-size: 15px;
	font-weight: 400;
	font-style: normal;
	letter-spacing: -0.45px;
	line-height: 100%;
`;

const RadioBoxGroup = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	& + & {
		margin-left: 20px;
	}

	input {
		cursor: pointer;
		width: 15px;
		height: 15px;
	}

	label {
		cursor: pointer;
		margin-left: 10px;
		color: #666666;
		font-family: 'Noto Sans';
		font-size: 15px;
		font-weight: 400;
		font-style: normal;
		letter-spacing: -0.45px;
		line-height: 100%;
	}
`;

const Footer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
`;

const CancelButton = styled.button`
	width: 100px;
	height: 40px;
	border: 1px solid #0e498f;
	border-radius: 20px;
	background-color: #ffffff;

	color: #0e498f;
	font-family: 'Noto Sans';
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.48px;
	line-height: 100%;
`;

const OkButton = styled.button`
	width: 100px;
	height: 40px;

	margin-left: 20px;
	border: none;
	border-radius: 20px;
	background-color: #0e498f;
	background-image: linear-gradient(to right, #10abb1 0%, #275ba6 100%);

	color: #ffffff;
	font-family: 'Noto Sans';
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	letter-spacing: -0.48px;
	line-height: 100%;
`;

export default SystemScheduleModal;
